import "core-js/stable";
import "regenerator-runtime/runtime";
import XRegExp from 'xregexp';

export function handleEmojis(text: string): string {
    if (containsUnicodes(text) && AllowedEmojis) {
        for (const emoji of AllowedEmojis) {
            text = text.replace(XRegExp(emoji.code, 'g'), emoji.smiley);
        }

        text = text.replace(XRegExp(EmojiUnicodes, 'g'), '');
    }

    return text;
}

function containsUnicodes(text: string): boolean {
    const regExp = XRegExp(EmojiUnicodes);
    return regExp.test(text);
}

interface EmojiType {
    code: string;
    smiley: string;
}

const AllowedEmojis: EmojiType[] = [
    {code: '\u{1F600}', smiley: ':-D'},
    {code: '\u{1F601}', smiley: ':-D'},
    {code: '\u{1F603}', smiley: ':-D'},
    {code: '\u{1F604}', smiley: ':-D'},
    {code: '\u{1F642}', smiley: ':-)'},
    {code: '\u{1F60A}', smiley: ':-)'},
    {code: '\u{1F641}', smiley: ':-('},
    {code: '\u{1F609}', smiley: ';-)'},
    {code: '\u{1F60B}', smiley: ':-P'},
];

// <editor-fold desc="Unicodes from https://unicode.org/emoji/charts/emoji-list.html v14.0">
const EmojiUnicodes: string =
    '[\u{1F606}\u{1F605}\u{1F923}\u{1F602}\u{1F643}\u{1FAE0}\u{1F607}' +
        '\u{1F970}\u{1F60D}\u{1F929}\u{1F618}\u{1F617}\u{263A}\u{1F61A}' +
        '\u{1F619}\u{1F972}\u{1F61B}\u{1F61C}\u{1F92A}\u{1F61D}\u{1F911}' +
        '\u{1F917}\u{1F92D}\u{1FAE2}\u{1FAE3}\u{1F92B}\u{1F914}\u{1FAE4}' +
        '\u{1FAE1}\u{1F910}\u{1F928}\u{1F610}\u{1F611}\u{1FAE5}\u{1F636}' +
        '\u{1F60F}\u{1F612}\u{1F644}\u{1F62C}\u{1F925}\u{1F60C}\u{1F614}' +
        '\u{1F62A}\u{1F924}\u{1F634}\u{1F637}\u{1F912}\u{1F915}\u{1F922}' +
        '\u{1F92E}\u{1F927}\u{1F975}\u{1F976}\u{1F974}\u{1F635}\u{1F92F}' +
        '\u{1F920}\u{1F973}\u{1F978}\u{1F60E}\u{1F913}\u{1F9D0}\u{1F615}' +
        '\u{1F61F}\u{2639}\u{1F62E}\u{1F62F}\u{1F632}' +
        '\u{1F633}\u{1F97A}\u{1F979}\u{1F626}\u{1F627}\u{1F628}\u{1F630}' +
        '\u{1F625}\u{1F622}\u{1F62D}\u{1F631}\u{1F616}\u{1F623}\u{1F61E}' +
        '\u{1F613}\u{1F629}\u{1F62B}\u{1F971}\u{1F624}\u{1F621}\u{1F620}' +
        '\u{1F92C}\u{1F608}\u{1F47F}\u{1F480}\u{1F4A9}\u{1F921}\u{1F479}' +
        '\u{1F47A}\u{1F47B}\u{1F47D}\u{1F47E}\u{1F916}\u{1F63A}\u{1F638}' +
        '\u{1F639}\u{1F63B}\u{1F63C}\u{1F63D}\u{1F640}\u{1F63F}\u{1F63E}' +
        '\u{1F648}\u{1F649}\u{1F64A}\u{1F48C}\u{1F498}\u{1F49D}\u{1F496}' +
        '\u{1F497}\u{1F493}\u{1F49E}\u{1F495}\u{1F49F}\u{2763}\u{1F494}' +
        '\u{1F9E1}\u{1F49B}\u{1F49A}\u{1F499}\u{1F49C}\u{1F90E}\u{1F5A4}' +
        '\u{1F90D}\u{1F4AF}\u{1F4A2}\u{1F4A5}\u{1F4AB}\u{1F4A6}\u{1F4A8}' +
        '\u{1F573}\u{1F4A3}\u{1F4AC}\u{1F5E8}\u{1F5EF}\u{1F4AD}\u{1F4A4}' +
        '\u{1F44B}\u{1F91A}\u{1F590}\u{270B}\u{1F596}\u{1FAF3}\u{1FAF4}' +
        '\u{1F44C}\u{1F90C}\u{1F90F}\u{270C}\u{1F91E}\u{1FAF0}\u{1F91F}' +
        '\u{1F918}\u{1F919}\u{1F448}\u{1F449}\u{1F446}\u{1F595}\u{1F447}' +
        '\u{261D}\u{1FAF5}\u{1F44D}\u{1F44E}\u{270A}\u{1F44A}\u{1F91B}' +
        '\u{1F91C}\u{1F44F}\u{1F64C}\u{1FAF6}\u{1F450}\u{1F932}\u{1FAF1}' +
        '\u{1FAF2}\u{1F64F}\u{270D}\u{1F485}\u{1F933}\u{1F4AA}\u{1F9BE}' +
        '\u{1F9BF}\u{1F9B5}\u{1F9B6}\u{1F442}\u{1F9BB}\u{1F443}\u{1F9E0}' +
        '\u{1FAC0}\u{1FAC1}\u{1F9B7}\u{1F9B4}\u{1F440}\u{1F441}\u{1F445}' +
        '\u{1F444}\u{1FAE6}\u{1F476}\u{1F9D2}\u{1F9D4}\u{1F471}\u{1F9D3}' +
        '\u{1F474}\u{1F475}\u{1F64D}\u{1F64E}\u{1F645}\u{1F646}\u{1F481}' +
        '\u{1F64B}\u{1F9CF}\u{1F647}\u{1F926}\u{1F937}\u{1F46E}\u{1F575}' +
        '\u{1F482}\u{1F977}\u{1F477}\u{1FAC5}\u{1F934}\u{1F478}\u{1F473}' +
        '\u{1F472}\u{1F9D5}\u{1F935}\u{1F470}\u{1F930}\u{1FAC3}\u{1FAC4}' +
        '\u{1F931}\u{1F47C}\u{1F385}\u{1F936}\u{1F9B8}\u{1F9B9}\u{1F9D9}' +
        '\u{1F9DA}\u{1F9DB}\u{1F9DC}\u{1F9DD}\u{1F9DE}\u{1F9DF}\u{1F9CC}' +
        '\u{1F486}\u{1F487}\u{1F6B6}\u{1F9CD}\u{1F9CE}\u{1F3C3}\u{1F483}' +
        '\u{1F57A}\u{1F574}\u{1F46F}\u{1F9D6}\u{1F9D7}\u{1F93A}\u{1F3C7}' +
        '\u{26F7}\u{1F3C2}\u{1F3CC}\u{1F3C4}\u{1F6A3}\u{1F3CA}\u{26F9}' +
        '\u{1F3CB}\u{1F6B4}\u{1F6B5}\u{1F938}\u{1F93C}\u{1F93D}\u{1F93E}' +
        '\u{1F939}\u{1F9D8}\u{1F6C0}\u{1F6CC}\u{1F46D}\u{1F46B}\u{1F91D}' +
        '\u{1F46C}\u{1F48F}\u{1F48B}\u{1F491}\u{1F9D1}\u{2764}\u{1F46A}' +
        '\u{1F468}\u{1F466}\u{1F469}\u{1F467}\u{1F5E3}\u{1F464}\u{1F465}' +
        '\u{1FAC2}\u{1F463}\u{1F9B0}\u{1F9B1}\u{1F9B3}\u{1F9B2}\u{1F435}' +
        '\u{1F412}\u{1F98D}\u{1F9A7}\u{1F436}\u{1F9AE}\u{1F415}\u{1F429}' +
        '\u{1F43A}\u{1F98A}\u{1F99D}\u{1F431}\u{1F408}\u{1F981}\u{1F42F}' +
        '\u{1F405}\u{1F406}\u{1F434}\u{1F40E}\u{1F984}\u{1F993}\u{1F98C}' +
        '\u{1F9AC}\u{1F42E}\u{1F402}\u{1F403}\u{1F404}\u{1F437}\u{1F416}' +
        '\u{1F417}\u{1F43D}\u{1F40F}\u{1F411}\u{1F410}\u{1F42A}\u{1F42B}' +
        '\u{1F999}\u{1F992}\u{1F418}\u{1F9A3}\u{1F98F}\u{1F99B}\u{1F42D}' +
        '\u{1F401}\u{1F400}\u{1F439}\u{1F430}\u{1F407}\u{1F43F}\u{1F9AB}' +
        '\u{1F994}\u{1F987}\u{1F43B}\u{1F428}\u{1F43C}\u{1F9A5}\u{1F9A6}' +
        '\u{1F9A8}\u{1F998}\u{1F9A1}\u{1F43E}\u{1F983}\u{1F414}\u{1F413}' +
        '\u{1F423}\u{1F424}\u{1F425}\u{1F427}\u{1F54A}\u{1F985}\u{1F986}' +
        '\u{1F9A2}\u{1F989}\u{1F9A4}\u{1FAB6}\u{1F9A9}\u{1F99A}\u{1F99C}' +
        '\u{1F438}\u{1F40A}\u{1F422}\u{1F98E}\u{1F40D}\u{1F432}\u{1F409}' +
        '\u{1F995}\u{1F996}\u{1F433}\u{1F40B}\u{1F42C}\u{1F9AD}\u{1F41F}' +
        '\u{1F420}\u{1F421}\u{1F988}\u{1F419}\u{1F41A}\u{1FAB8}\u{1F40C}' +
        '\u{1F98B}\u{1F41B}\u{1F41C}\u{1F41D}\u{1FAB2}\u{1F41E}\u{1F997}' +
        '\u{1FAB3}\u{1F577}\u{1F578}\u{1F982}\u{1F99F}\u{1FAB0}\u{1FAB1}' +
        '\u{1F9A0}\u{1F490}\u{1F338}\u{1F4AE}\u{1FAB7}\u{1F3F5}\u{1F339}' +
        '\u{1F940}\u{1F33A}\u{1F33B}\u{1F33C}\u{1F337}\u{1F331}\u{1FAB4}' +
        '\u{1F332}\u{1F333}\u{1F334}\u{1F335}\u{1F33E}\u{1F33F}\u{2618}' +
        '\u{1F340}\u{1F341}\u{1F342}\u{1F343}\u{1FAB9}\u{1FABA}\u{1F347}' +
        '\u{1F348}\u{1F349}\u{1F34A}\u{1F34B}\u{1F34C}\u{1F34D}\u{1F96D}' +
        '\u{1F34E}\u{1F34F}\u{1F350}\u{1F351}\u{1F352}\u{1F353}\u{1FAD0}' +
        '\u{1F95D}\u{1F345}\u{1FAD2}\u{1F965}\u{1F951}\u{1F346}\u{1F954}' +
        '\u{1F955}\u{1F33D}\u{1F336}\u{1FAD1}\u{1F952}\u{1F96C}\u{1F966}' +
        '\u{1F9C4}\u{1F9C5}\u{1F344}\u{1F95C}\u{1FAD8}\u{1F330}\u{1F35E}' +
        '\u{1F950}\u{1F956}\u{1FAD3}\u{1F968}\u{1F96F}\u{1F95E}\u{1F9C7}' +
        '\u{1F9C0}\u{1F356}\u{1F357}\u{1F969}\u{1F953}\u{1F354}\u{1F35F}' +
        '\u{1F355}\u{1F32D}\u{1F96A}\u{1F32E}\u{1F32F}\u{1FAD4}\u{1F959}' +
        '\u{1F9C6}\u{1F95A}\u{1F373}\u{1F958}\u{1F372}\u{1FAD5}\u{1F963}' +
        '\u{1F957}\u{1F37F}\u{1F9C8}\u{1F9C2}\u{1F96B}\u{1F371}\u{1F358}' +
        '\u{1F359}\u{1F35A}\u{1F35B}\u{1F35C}\u{1F35D}\u{1F360}\u{1F362}' +
        '\u{1F363}\u{1F364}\u{1F365}\u{1F96E}\u{1F361}\u{1F95F}\u{1F960}' +
        '\u{1F961}\u{1F980}\u{1F99E}\u{1F990}\u{1F991}\u{1F9AA}\u{1F366}' +
        '\u{1F367}\u{1F368}\u{1F369}\u{1F36A}\u{1F382}\u{1F370}\u{1F9C1}' +
        '\u{1F967}\u{1F36B}\u{1F36C}\u{1F36D}\u{1F36E}\u{1F36F}\u{1F37C}' +
        '\u{1F95B}\u{2615}\u{1FAD6}\u{1F375}\u{1F376}\u{1F37E}\u{1F377}' +
        '\u{1F378}\u{1F379}\u{1F37A}\u{1F37B}\u{1F942}\u{1F943}\u{1FAD7}' +
        '\u{1F964}\u{1F9CB}\u{1F9C3}\u{1F9C9}\u{1F9CA}\u{1F962}\u{1F37D}' +
        '\u{1F374}\u{1F944}\u{1F52A}\u{1FAD9}\u{1F3FA}\u{1F30D}\u{1F30E}' +
        '\u{1F30F}\u{1F310}\u{1F5FA}\u{1F5FE}\u{1F9ED}\u{1F3D4}\u{26F0}' +
        '\u{1F30B}\u{1F5FB}\u{1F3D5}\u{1F3D6}\u{1F3DC}\u{1F3DD}\u{1F3DE}' +
        '\u{1F3DF}\u{1F3DB}\u{1F3D7}\u{1F9F1}\u{1FAA8}\u{1FAB5}\u{1F6D6}' +
        '\u{1F3D8}\u{1F3DA}\u{1F3E0}\u{1F3E1}\u{1F3E2}\u{1F3E3}\u{1F3E4}' +
        '\u{1F3E5}\u{1F3E6}\u{1F3E8}\u{1F3E9}\u{1F3EA}\u{1F3EB}\u{1F3EC}' +
        '\u{1F3ED}\u{1F3EF}\u{1F3F0}\u{1F492}\u{1F5FC}\u{1F5FD}\u{26EA}' +
        '\u{1F54C}\u{1F6D5}\u{1F54D}\u{26E9}\u{1F54B}\u{26F2}\u{26FA}' +
        '\u{1F301}\u{1F303}\u{1F3D9}\u{1F304}\u{1F305}\u{1F306}\u{1F307}' +
        '\u{1F309}\u{2668}\u{1F3A0}\u{1F6DD}\u{1F3A1}\u{1F3A2}\u{1F488}' +
        '\u{1F3AA}\u{1F682}\u{1F683}\u{1F684}\u{1F685}\u{1F686}\u{1F687}' +
        '\u{1F688}\u{1F689}\u{1F68A}\u{1F69D}\u{1F69E}\u{1F68B}\u{1F68C}' +
        '\u{1F68D}\u{1F68E}\u{1F690}\u{1F691}\u{1F692}\u{1F693}\u{1F694}' +
        '\u{1F695}\u{1F696}\u{1F697}\u{1F698}\u{1F699}\u{1F6FB}\u{1F69A}' +
        '\u{1F69B}\u{1F69C}\u{1F3CE}\u{1F3CD}\u{1F6F5}\u{1F9BD}\u{1F9BC}' +
        '\u{1F6FA}\u{1F6B2}\u{1F6F4}\u{1F6F9}\u{1F6FC}\u{1F68F}\u{1F6E3}' +
        '\u{1F6E4}\u{1F6E2}\u{26FD}\u{1F6DE}\u{1F6A8}\u{1F6A5}\u{1F6A6}' +
        '\u{1F6D1}\u{1F6A7}\u{2693}\u{1F6DF}\u{26F5}\u{1F6F6}\u{1F6A4}' +
        '\u{1F6F3}\u{26F4}\u{1F6E5}\u{1F6A2}\u{2708}\u{1F6E9}\u{1F6EB}' +
        '\u{1F6EC}\u{1FA82}\u{1F4BA}\u{1F681}\u{1F69F}\u{1F6A0}\u{1F6A1}' +
        '\u{1F6F0}\u{1F680}\u{1F6F8}\u{1F6CE}\u{1F9F3}\u{231B}\u{23F3}' +
        '\u{231A}\u{23F0}\u{23F1}\u{23F2}\u{1F570}\u{1F55B}\u{1F567}' +
        '\u{1F550}\u{1F55C}\u{1F551}\u{1F55D}\u{1F552}\u{1F55E}\u{1F553}' +
        '\u{1F55F}\u{1F554}\u{1F560}\u{1F555}\u{1F561}\u{1F556}\u{1F562}' +
        '\u{1F557}\u{1F563}\u{1F558}\u{1F564}\u{1F559}\u{1F565}\u{1F55A}' +
        '\u{1F566}\u{1F311}\u{1F312}\u{1F313}\u{1F314}\u{1F315}\u{1F316}' +
        '\u{1F317}\u{1F318}\u{1F319}\u{1F31A}\u{1F31B}\u{1F31C}\u{1F321}' +
        '\u{2600}\u{1F31D}\u{1F31E}\u{1FA90}\u{2B50}\u{1F31F}\u{1F320}' +
        '\u{1F30C}\u{2601}\u{26C5}\u{26C8}\u{1F324}\u{1F325}\u{1F326}' +
        '\u{1F327}\u{1F328}\u{1F329}\u{1F32A}\u{1F32B}\u{1F32C}\u{1F300}' +
        '\u{1F302}\u{2602}\u{2614}\u{26F1}\u{26A1}\u{2744}\u{2603}' +
        '\u{26C4}\u{2604}\u{1F525}\u{1F4A7}\u{1F30A}\u{1F383}\u{1F384}' +
        '\u{1F386}\u{1F387}\u{1F9E8}\u{2728}\u{1F388}\u{1F389}\u{1F38A}' +
        '\u{1F38B}\u{1F38D}\u{1F38E}\u{1F38F}\u{1F390}\u{1F391}\u{1F9E7}' +
        '\u{1F380}\u{1F381}\u{1F397}\u{1F39F}\u{1F3AB}\u{1F396}\u{1F3C6}' +
        '\u{1F3C5}\u{1F947}\u{1F948}\u{1F949}\u{26BD}\u{26BE}\u{1F94E}' +
        '\u{1F3C0}\u{1F3D0}\u{1F3C8}\u{1F3C9}\u{1F3BE}\u{1F94F}\u{1F3B3}' +
        '\u{1F3CF}\u{1F3D1}\u{1F3D2}\u{1F94D}\u{1F3D3}\u{1F3F8}\u{1F94A}' +
        '\u{1F94B}\u{1F945}\u{26F3}\u{26F8}\u{1F3A3}\u{1F93F}\u{1F3BD}' +
        '\u{1F3BF}\u{1F6F7}\u{1F94C}\u{1F3AF}\u{1FA80}\u{1FA81}\u{1F3B1}' +
        '\u{1F52E}\u{1FA84}\u{1F9FF}\u{1FAAC}\u{1F3AE}\u{1F579}\u{1F3B0}' +
        '\u{1F3B2}\u{1F9E9}\u{1F9F8}\u{1FA85}\u{1FAA9}\u{1FA86}\u{2660}' +
        '\u{2665}\u{2666}\u{2663}\u{265F}\u{1F0CF}\u{1F004}\u{1F3B4}' +
        '\u{1F3AD}\u{1F5BC}\u{1F3A8}\u{1F9F5}\u{1FAA1}\u{1F9F6}\u{1FAA2}' +
        '\u{1F453}\u{1F576}\u{1F97D}\u{1F97C}\u{1F9BA}\u{1F454}\u{1F455}' +
        '\u{1F456}\u{1F9E3}\u{1F9E4}\u{1F9E5}\u{1F9E6}\u{1F457}\u{1F458}' +
        '\u{1F97B}\u{1FA71}\u{1FA72}\u{1FA73}\u{1F459}\u{1F45A}\u{1F45B}' +
        '\u{1F45C}\u{1F45D}\u{1F6CD}\u{1F392}\u{1FA74}\u{1F45E}\u{1F45F}' +
        '\u{1F97E}\u{1F97F}\u{1F460}\u{1F461}\u{1FA70}\u{1F462}\u{1F451}' +
        '\u{1F452}\u{1F3A9}\u{1F393}\u{1F9E2}\u{1FA96}\u{26D1}\u{1F4FF}' +
        '\u{1F484}\u{1F48D}\u{1F48E}\u{1F507}\u{1F508}\u{1F509}\u{1F50A}' +
        '\u{1F4E2}\u{1F4E3}\u{1F4EF}\u{1F514}\u{1F515}\u{1F3BC}\u{1F3B5}' +
        '\u{1F3B6}\u{1F399}\u{1F39A}\u{1F39B}\u{1F3A4}\u{1F3A7}\u{1F4FB}' +
        '\u{1F3B7}\u{1FA97}\u{1F3B8}\u{1F3B9}\u{1F3BA}\u{1F3BB}\u{1FA95}' +
        '\u{1F941}\u{1FA98}\u{1F4F1}\u{1F4F2}\u{260E}\u{1F4DE}\u{1F4DF}' +
        '\u{1F4E0}\u{1F50B}\u{1FAAB}\u{1F50C}\u{1F4BB}\u{1F5A5}\u{1F5A8}' +
        '\u{2328}\u{1F5B1}\u{1F5B2}\u{1F4BD}\u{1F4BE}\u{1F4BF}\u{1F4C0}' +
        '\u{1F9EE}\u{1F3A5}\u{1F39E}\u{1F4FD}\u{1F3AC}\u{1F4FA}\u{1F4F7}' +
        '\u{1F4F8}\u{1F4F9}\u{1F4FC}\u{1F50D}\u{1F50E}\u{1F56F}\u{1F4A1}' +
        '\u{1F526}\u{1F3EE}\u{1FA94}\u{1F4D4}\u{1F4D5}\u{1F4D6}\u{1F4D7}' +
        '\u{1F4D8}\u{1F4D9}\u{1F4DA}\u{1F4D3}\u{1F4D2}\u{1F4C3}\u{1F4DC}' +
        '\u{1F4C4}\u{1F4F0}\u{1F5DE}\u{1F4D1}\u{1F516}\u{1F3F7}\u{1F4B0}' +
        '\u{1FA99}\u{1F4B4}\u{1F4B5}\u{1F4B6}\u{1F4B7}\u{1F4B8}\u{1F4B3}' +
        '\u{1F9FE}\u{1F4B9}\u{2709}\u{1F4E7}\u{1F4E8}\u{1F4E9}\u{1F4E4}' +
        '\u{1F4E5}\u{1F4E6}\u{1F4EB}\u{1F4EA}\u{1F4EC}\u{1F4ED}\u{1F4EE}' +
        '\u{1F5F3}\u{270F}\u{2712}\u{1F58B}\u{1F58A}\u{1F58C}\u{1F58D}' +
        '\u{1F4DD}\u{1F4BC}\u{1F4C1}\u{1F4C2}\u{1F5C2}\u{1F4C5}\u{1F4C6}' +
        '\u{1F5D2}\u{1F5D3}\u{1F4C7}\u{1F4C8}\u{1F4C9}\u{1F4CA}\u{1F4CB}' +
        '\u{1F4CC}\u{1F4CD}\u{1F4CE}\u{1F587}\u{1F4CF}\u{1F4D0}\u{2702}' +
        '\u{1F5C3}\u{1F5C4}\u{1F5D1}\u{1F512}\u{1F513}\u{1F50F}\u{1F510}' +
        '\u{1F511}\u{1F5DD}\u{1F528}\u{1FA93}\u{26CF}\u{2692}\u{1F6E0}' +
        '\u{1F5E1}\u{2694}\u{1F52B}\u{1FA83}\u{1F3F9}\u{1F6E1}\u{1FA9A}' +
        '\u{1F527}\u{1FA9B}\u{1F529}\u{2699}\u{1F5DC}\u{2696}\u{1F9AF}' +
        '\u{1F517}\u{26D3}\u{1FA9D}\u{1F9F0}\u{1F9F2}\u{1FA9C}\u{2697}' +
        '\u{1F9EA}\u{1F9EB}\u{1F9EC}\u{1F52C}\u{1F52D}\u{1F4E1}\u{1F489}' +
        '\u{1FA78}\u{1F48A}\u{1FA79}\u{1FA7C}\u{1FA7A}\u{1FA7B}\u{1F6AA}' +
        '\u{1F6D7}\u{1FA9E}\u{1FA9F}\u{1F6CF}\u{1F6CB}\u{1FA91}\u{1F6BD}' +
        '\u{1FAA0}\u{1F6BF}\u{1F6C1}\u{1FAA4}\u{1FA92}\u{1F9F4}\u{1F9F7}' +
        '\u{1F9F9}\u{1F9FA}\u{1F9FB}\u{1FAA3}\u{1F9FC}\u{1FAE7}\u{1FAA5}' +
        '\u{1F9FD}\u{1F9EF}\u{1F6D2}\u{1F6AC}\u{26B0}\u{1FAA6}\u{26B1}' +
        '\u{1F5FF}\u{1FAA7}\u{1FAAA}\u{1F3E7}\u{1F6AE}\u{1F6B0}\u{267F}' +
        '\u{1F6B9}\u{1F6BA}\u{1F6BB}\u{1F6BC}\u{1F6BE}\u{1F6C2}\u{1F6C3}' +
        '\u{1F6C4}\u{1F6C5}\u{26A0}\u{1F6B8}\u{26D4}\u{1F6AB}\u{1F6B3}' +
        '\u{1F6AD}\u{1F6AF}\u{1F6B1}\u{1F6B7}\u{1F4F5}\u{1F51E}\u{2622}' +
        '\u{2623}\u{2B06}\u{2197}\u{27A1}\u{2198}\u{2B07}\u{2199}' +
        '\u{2B05}\u{2196}\u{2195}\u{2194}\u{21A9}\u{21AA}\u{2934}' +
        '\u{2935}\u{1F503}\u{1F504}\u{1F519}\u{1F51A}\u{1F51B}\u{1F51C}' +
        '\u{1F51D}\u{1F6D0}\u{269B}\u{1F549}\u{2721}\u{2638}\u{262F}' +
        '\u{271D}\u{2626}\u{262A}\u{262E}\u{1F54E}\u{1F52F}\u{2648}' +
        '\u{2649}\u{264A}\u{264B}\u{264C}\u{264D}\u{264E}\u{264F}' +
        '\u{2650}\u{2651}\u{2652}\u{2653}\u{26CE}\u{1F500}\u{1F501}' +
        '\u{1F502}\u{25B6}\u{23E9}\u{23ED}\u{23EF}\u{25C0}\u{23EA}' +
        '\u{23EE}\u{1F53C}\u{23EB}\u{1F53D}\u{23EC}\u{23F8}\u{23F9}' +
        '\u{23FA}\u{23CF}\u{1F3A6}\u{1F505}\u{1F506}\u{1F4F6}\u{1F4F3}' +
        '\u{1F4F4}\u{2640}\u{2642}\u{2716}\u{2795}\u{2796}\u{2797}' +
        '\u{1F7F0}\u{267E}\u{203C}\u{2049}\u{2753}\u{2754}\u{2755}' +
        '\u{2757}\u{3030}\u{1F4B1}\u{1F4B2}\u{2695}\u{267B}\u{269C}' +
        '\u{1F531}\u{1F4DB}\u{1F530}\u{2B55}\u{2705}\u{2611}\u{2714}' +
        '\u{274C}\u{274E}\u{27B0}\u{27BF}\u{303D}\u{2733}\u{2734}' +
        '\u{2747}\u{A9}\u{AE}\u{2122}\u{1F51F}\u{1F520}\u{1F521}' +
        '\u{1F522}\u{1F523}\u{1F524}\u{1F170}\u{1F18E}\u{1F171}\u{1F191}' +
        '\u{1F192}\u{1F193}\u{2139}\u{1F194}\u{24C2}\u{1F195}\u{1F196}' +
        '\u{1F17E}\u{1F197}\u{1F17F}\u{1F198}\u{1F199}\u{1F19A}\u{1F201}' +
        '\u{1F202}\u{1F237}\u{1F236}\u{1F22F}\u{1F250}\u{1F239}\u{1F21A}' +
        '\u{1F232}\u{1F251}\u{1F238}\u{1F234}\u{1F233}\u{3297}\u{3299}' +
        '\u{1F23A}\u{1F235}\u{1F534}\u{1F7E0}\u{1F7E1}\u{1F7E2}\u{1F535}' +
        '\u{1F7E3}\u{1F7E4}\u{26AB}\u{26AA}\u{1F7E5}\u{1F7E7}\u{1F7E8}' +
        '\u{1F7E9}\u{1F7E6}\u{1F7EA}\u{1F7EB}\u{2B1C}\u{25FC}\u{25FB}' +
        '\u{25FE}\u{25FD}\u{25AA}\u{25AB}\u{1F536}\u{1F537}\u{1F538}' +
        '\u{1F539}\u{1F53A}\u{1F53B}\u{1F4A0}\u{1F518}\u{1F533}\u{1F532}' +
        '\u{1F3C1}\u{1F6A9}\u{1F38C}\u{1F308}\u{1F3F3}\u{26A7}\u{2620}' +
        '\u{1F426}\u{2B1B}\u{1F3F4}\u{1F1E6}\u{1F1E7}\u{1F1E8}\u{1F1E9}' +
        '\u{1F1EA}\u{1F1EB}\u{1F1EC}\u{1F1ED}\u{1F1EE}\u{1F1EF}\u{1F1F0}' +
        '\u{1F1F1}\u{1F1F2}\u{1F1F3}\u{1F1F4}\u{1F1F5}\u{1F1F6}\u{1F1F7}' +
        '\u{1F1F8}\u{1F1F9}\u{1F1FA}\u{1F1FB}\u{1F1FC}\u{1F1FD}\u{1F1FE}' +
        '\u{1F1FF}\u{E005E}\u{E002C}\u{E004A}\u{E004B}\u{E004F}\u{E0056}' +
        '\u{E0028}\u{E005C}\u{E007B}\u{E004D}\u{E0045}\u{E0065}' +
        '\u{E0053}\u{E0049}\u{E0078}\u{E0071}\u{E0052}\u{E0055}' +
        '\u{200D}\u{E0062}\u{E0054}\u{E002F}' +
        '\u{E0060}\u{E0068}\u{E003D}\u{E0043}\u{E0021}' +
        '\u{E007C}\u{E007E}\u{E0050}\u{E006A}\u{E007A}' +
        '\u{E004E}\u{E0026}\u{E003E}\u{E0041}\u{E0061}\u{E002E}\u{E0069}' +
        '\u{E006D}\u{E0074}\u{E0025}\u{E0024}\u{E0027}\u{E007F}' +
        '\u{E003F}\u{E0059}\u{E0077}\u{E0022}\u{E007D}\u{E0075}' +
        '\u{E0048}\u{E005F}\u{E0046}\u{E003C}\u{E0064}\u{E006F}' +
        '\u{E0070}\u{E0063}\u{E0072}\u{E003A}\u{E0073}' +
        '\u{E0029}\u{E0042}\u{E0051}' +
        '\u{E0067}\u{E0047}\u{E002B}\u{E0076}' +
        '\u{E005A}\u{E0057}\u{E0040}\u{E005B}\u{E003B}\u{1F3FF}\u{E0058}' +
        '\u{E0079}\u{E005D}\u{E006E}\u{E004C}\u{E006C}' +
        '\u{E0020}\u{E0044}\u{E0066}\u{E006B}\u{1F3FB}\u{E002D}' +
        '\u{1F3FD}\u{1F3FC}\u{1F3FE}\u{1FAE8}\u{1FA75}' +
        '\u{1FA76}\u{1FA77}\u{1FAF8}\u{1FAF7}\u{1FACE}\u{1FACF}\u{1FABD}' +
        '\u{1FABF}\u{1FABC}\u{1FADA}\u{1FABB}\u{1FADB}\u{1FAAD}\u{1FAAE}' +
        '\u{1FA87}\u{1FA88}\u{1FAAF}\u{1F6DC}\u{FFF9}\u{FFFA}\u{FFFB}\u{FFFC}' +
        '\u{FFFD}\u{FFFE}\u{FFFF}]+';
        // </editor-fold>
