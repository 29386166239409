import * as React from "react";
import { useSelector } from "react-redux";
import { Router } from "./Components/Router";
import { Settings } from "library/src/Components/Settings/Settings";
import { User } from "library/src/Components/User/User";
import { MarketingDesk } from "library/src/Components/Desk/MarketingDesk";
import { selectUserStateFromMainState, UserRole } from 'library/src/Store/UserState'
import { DocumentIdentifier } from "library/src/Util/DocumentIdentifier";
import { PlatformTypes } from "library/src/Util/PlatformUtil";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Login } from 'library/src/Views/Login';

export const App = () => {
    const userState = useSelector(selectUserStateFromMainState);
    const [isLightView, setIsLightView] = useState<boolean | null>(null);

    useEffect(() => {
        const result = userState.roles.filter(role => role === UserRole.Gast).length === 1;
        DocumentIdentifier.setPlatformIdentifier(result ? PlatformTypes.lite : PlatformTypes.full);

        // initialUser object has -1. Guest user has 0 and logged ind users have an id > 0.
        if (userState.id !== -1) {
            setIsLightView(result);
        }
    }, [userState.roles, userState.id])

    return (
        <Settings>
            <User>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/" component={typeof isLightView === 'boolean' ? (isLightView ? MarketingDesk : Router) : undefined} />
                    </Switch>
                </BrowserRouter>
            </User>
        </Settings>
    );
}