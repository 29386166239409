import React, { forwardRef } from 'react';

import * as S from './Knob.styled';

export enum KnobAppearance {
    Frame = 'frame',
    Primary = 'primary',
    Secondary = 'secondary',
    Contrasted = 'contrasted',
    Inactive = 'inactive',
}

export enum KnobSize {
    Default = 'default',
    Small = 'small',
}


export type KnobProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  appearance?: KnobAppearance | boolean;
  size?: KnobSize;
};

export const Knob = forwardRef<HTMLButtonElement, KnobProps>(
  ({ appearance, size, children, ...knobProps }, forwardedRef) => {
    return (
      <S.Button
        {...knobProps}
        appearance={appearance}
        ref={forwardedRef}
        size={size}
      >
        {children}
      </S.Button>
    );
  },
);
