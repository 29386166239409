import * as React from 'react';

import classNames from 'classnames';
import {useSelector} from 'react-redux';

import useOneClickStore from "../../Hooks/useOneClickStore";
import {selectUsername, selectUserRoles, UserRole} from '../../Store/UserState';
import { useUserRoles, hasRole } from '../../Util/UserUtils';
import { Menu } from '../Header';
import { UserIcon } from '../Icons';
import { UserStatusLabel } from '../User';

export interface IndicatorLink {
    href: string;
    text: string;
    onClick?: () => void;
}

function getAccountIndicatorLinks(userRoles: UserRole[]): IndicatorLink[] {
    if (hasRole(UserRole.Authenticated, userRoles)) {
        return [
            {href: process.env.REACT_APP_DUDENDE_URL + '/user/authenticate?destination=/user', text: 'Mein Profil'},
            {href: '/settings', text: 'Einstellungen'},
            {href: '/user/expire', text: 'Abmelden', onClick: () => useOneClickStore.getState().setBufferText('')},
        ];
    }

    return [
        {href: process.env.REACT_APP_PUBLIC_URL + '/user/authenticate?destination=/', text: 'Einloggen'},
        {href: process.env.REACT_APP_DUDENDE_URL + '/user/register?source=mentor', text: 'Registrieren'},
    ];
}

export const AccountIndicator: React.FC = () => {
    const loginStatus = useUserRoles(UserRole.Authenticated);
    const userRoles = useSelector(selectUserRoles);
    const username = useSelector(selectUsername);
    const menuEntry = {
        text: (<span className="account-indicator">
                <span className={classNames("account-indicator__title", {
                    "account-indicator__title--username": loginStatus && username
                })}>
                    {loginStatus && username ? username : "Mein Duden"}
                </span>
                <UserStatusLabel />
                <UserIcon />

            </span>),
            children: getAccountIndicatorLinks(userRoles),
            isRight: true
        }

    return (
        <Menu entries={[menuEntry]} />
    );
};
