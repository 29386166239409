import styled from "styled-components";

import { Colors, Fonts } from "../../Styles";

export const Wrapper = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    
    @media screen and (max-width: 400px){
        margin-right: -20px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`