import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { selectUserFeatures, selectUserHasHadPremium, selectUserHasUsedTrial, selectUserShowDiscount, selectUserStateFromMainState, UserRole } from '../../Store/UserState';
import '../../Styles/component/teaser/alert-space/alert-space.sass';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { hasFeature, isPremiumUser, useUserRoles } from '../../Util/UserUtils';

const CTA_TRIAL_COPY = 'Jetzt Premium testen';
const CTA_PURCHASE_COPY = 'Jetzt Premium kaufen';
const CTA_OFFER_COPY = 'Zum Angebot';

const USER_REGISTER_URL = 'user/register/private';
const USER_AUTH_URL = 'user/authenticate';

const trialSearchParams = {
    type: 'duden-trial',
    source: 'mentor',
};

const itemSearchParams = {
    plan: 'premium_monthly_pt_2',
    source: 'mentor',
    itm_source: 'duden_mentor',
    itm_medium: 'premium_int',
    itm_campaign: 'basis_angebot-795',
    itm_content: 'navi_cta',
};

export const AlertSpace: React.FC = () => {
    const userFeatures = useSelector(selectUserFeatures);
    const { roles } = useSelector(selectUserStateFromMainState);
    const hasUsedTrial = useSelector(selectUserHasUsedTrial);
    const showDiscount = useSelector(selectUserShowDiscount);
    const hasHadPremium = useSelector(selectUserHasHadPremium);

    const isAuthenticated = useUserRoles(UserRole.Authenticated);
    const isSchoolDictionaryRole = useUserRoles(UserRole.School_Licensemanager_Basic, UserRole.School_Licensemanager_Premium, UserRole.Student_Licensee_Basic);
    const isPremiumRole = isPremiumUser(roles);
    const isCtaVisible = !isSchoolDictionaryRole && !isPremiumRole;

    let ctaCopy = CTA_TRIAL_COPY;
    let searchParams = new URLSearchParams(trialSearchParams);

    const onClickPremium = useCallback(() => {
        if (!isAuthenticated) {
            GTMDataLayerPush({event: "premium_touchpoint", option: "premium_alert_button"});
        }
    }, [isAuthenticated])

    if (!isCtaVisible || hasFeature("hideAlertSpace", userFeatures)) {
        return null;
    }

    if (isAuthenticated) {
        ctaCopy = CTA_PURCHASE_COPY;
        searchParams = new URLSearchParams({
            destination: '/abonnement',
        });

        if (!hasUsedTrial && !hasHadPremium && showDiscount) {
            ctaCopy = CTA_OFFER_COPY;
            const itemSearchParamsString = new URLSearchParams(itemSearchParams).toString();
            searchParams = new URLSearchParams({
                destination: `/payment/${process.env.REACT_APP_DISCOUNT_BILLWERK_ID}?${itemSearchParamsString}`,
            })
        }
    }

    const href = new URL(isAuthenticated ? USER_AUTH_URL : USER_REGISTER_URL, process.env.REACT_APP_DUDENDE_URL).href;
    const finalizedHref = [href, searchParams.toString()].filter(Boolean).join('?');

    return (
        <div id="alert-space" className="alert-space">
            <a href={finalizedHref} className="alert-space__button" onClick={onClickPremium}>
                {ctaCopy} →
            </a>
        </div>
    );
};
