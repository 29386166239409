import React, {PropsWithChildren} from "react";
import '../../Styles/component/banner/banner.sass';

import styled from "styled-components";

import {Colors, MediaBreakpoints} from "../../Styles";

type Props = {
    type: "info" | "error" | "success" | "warning";
    styleSettings?: "editor" | "settings";
    onClick?: () => void;
}

type StyleProps = {
    type: "info" | "error" | "success" | "warning";
    styleSettings?: "editor" | "settings";
}

const StyledBanner = styled.div<StyleProps>`
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  background: ${(p: StyleProps) => {
    switch (p.type) {
      case 'info':
        return Colors.mystic;
      case 'error':
        return Colors.cosmos;
      case 'success':
        return Colors.grannySmithApple;
      case 'warning':
        return Colors.visVis;
      default:
        return Colors.cosmos
    }
  }};
  border-bottom: 1px solid;
  border-bottom-color: ${(p: StyleProps) => {
    switch (p.type) {
      case 'info':
        return Colors.white;
      case 'error':
        return Colors.crimson;
      case 'success':
        return Colors.pastelGreen;
      case 'warning':
        return Colors.supernova;
      default:
        return Colors.white
    }
  }};;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0;
  top: 5px;
  left: 20px;
  right: 0;
  position: absolute;
  width: 340px;
  margin: 0;
  transform: translateY(-100%);
  animation: slide-bottom 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .5s both;
  transition: background 0.2s ease-in;

  @media ${MediaBreakpoints.medium} {
    left: 118px;
    width: 600px;
  }

  &:hover {
    background: ${Colors.mercury};
    background: ${(p: StyleProps) => {
      switch (p.type) {
        case 'info':
          return Colors.mystic;
        case 'error':
          return Colors.cosmos;
        case 'success':
          return Colors.grannySmithApple;
        case 'warning':
          return Colors.visVis;
        default:
          return Colors.mercury
      }
    }};;
    transition: background 0.3s ease-in;
  }

  margin-left: ${(p: StyleProps) => {
    switch (p.styleSettings) {
      case 'editor':
        return "25px";
      case 'settings':
        return "100px";
      default:
        return 0
    }
  }};;

  span {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
  }


  @keyframes slide-bottom {
    0% {
      transform: translateY(-110%)
    }
    2% {
      transform: translateY(7px)
    }
    98% {
      transform: translateY(7px)
    }
    100% {
      transform: translateY(-110%)
    }
  }
`;

StyledBanner.displayName = 'div';

export const Banner: React.FC<PropsWithChildren<Props>> = (props) => {
    return(
        <StyledBanner type={props.type} styleSettings={props.styleSettings} onClick={props.onClick}>{props.children}</StyledBanner>
    );
}
