import React, { useCallback } from "react";

import classNames from "classnames";

import "../../../Styles/layout/menu.sass";
import { GTMDataLayerPush } from "../../../Util/GoogleTagManager";

export type MenuItemProps = {
    text: string | JSX.Element;
    href?: string;
    onClick?: () => void;
    isRight?: boolean;
    target?: string;
    children?: MenuItemProps[];
}

type MenuProps = {
    entries: MenuItemProps[];
    className?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ children, text, onClick, href, isRight, target }) => {
    const hasChildren = children !== undefined && children.length > 0;

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(() => {
        GTMDataLayerPush({
            event: "dm_navigation_click",
            option: text.toString(),
            navigation_location: "header"
        });
        onClick?.();
    }, [onClick, text]);

    return (
        <li className={classNames("menu-item", {
            "menu-item--has-children": hasChildren,
            "menu-item--right": isRight
        })}>
            {href ? <a
                href={href}
                target={target}
                onClick={handleClick}
            >{text}</a> : <span>{text}</span>}
            {hasChildren && (
                <ul className="navigation navigation--sub">{children?.map((child, index) => <MenuItem {...child} key={index} />)}</ul>
            )}
        </li>
    );
};

const Menu: React.FC<MenuProps> = (props) => (
    <nav>
        <ul className="navigation">{props.entries.map((entry, index) => <MenuItem {...entry} key={index} />)}</ul>
    </nav>
);

export default Menu;