import * as React from "react";

export const RoundedCheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Check</title>
        <g id="Group 3">
            <path
                id="Fill 1"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.77648 12.0003C2.77648 6.48633 7.1378 2 12.4987 2C12.6663 2 12.8294 2.01713 12.995 2.02621C18.1247 2.29432 22.2209 6.65869 22.2209 12.0003C22.2209 17.3418 18.1247 21.7057 12.995 21.9743C12.8294 21.9829 12.6663 22 12.4987 22C7.1378 22 2.77648 17.5137 2.77648 12.0003ZM18.0552 1.45046C16.4017 0.525995 14.509 0 12.4985 0C11.8955 0 11.3029 0.0476254 10.7241 0.138643C10.5312 0.169335 10.3398 0.20426 10.1505 0.244477C4.83866 1.36526 0.832031 6.21035 0.832031 12.0005C0.832031 17.7902 4.83866 22.6353 10.1505 23.7561C10.3398 23.7963 10.5312 23.8317 10.7241 23.8619C11.3029 23.9529 11.8955 24.0005 12.4985 24.0005C14.509 24.0005 16.4017 23.4745 18.0552 22.5501C18.2203 22.4575 18.3834 22.3612 18.5434 22.2606C21.9111 20.1535 24.1655 16.3429 24.1655 12.0005C24.1655 7.65816 21.9111 3.84707 18.5434 1.73991C18.3834 1.63937 18.2203 1.54306 18.0552 1.45046Z"
                fill="#333333"
            />
            <path
                id="Path 2"
                d="M17.1481 7.11358C17.4898 6.76211 18.0438 6.76211 18.3855 7.11358C18.6987 7.43576 18.7248 7.94144 18.4638 8.29421L18.3855 8.38637L11.0128 15.9697C10.6996 16.2919 10.2079 16.3187 9.86498 16.0503L9.77538 15.9697L6.61566 12.7197C6.27395 12.3682 6.27395 11.7984 6.61566 11.4469C6.92889 11.1247 7.42052 11.0979 7.76349 11.3664L7.85309 11.4469L10.3938 14.06L17.1481 7.11358Z"
                fill="#333333"
            />
        </g>
    </svg>
);
