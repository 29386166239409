import * as React from "react";

import styled from "styled-components";

import { ChevronUpIcon } from "../Icons";

interface Props {
    children: React.ReactNode;
    isExpandable?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDetailsElement>) => void;
}

export const StyledAdviceCardTitle = styled.summary`
    font-weight: bold;
    list-style: none;
    position: relative;

    .open-indicator {
        transform: rotate(180deg);
        position: absolute;
        right: 0;
        top: 0;
        transition-duration: 0.3s;

        details[open]:not(.always-open) & {
            transform: rotate(0deg);
        }
    }
    
    &:hover {
        cursor: pointer;
        details.always-open & {
            cursor: default;
        }
    }

    &:focus {
        outline: none;
    }

    &::marker,
    &::-webkit-details-marker {
        display: none;
    }

    details[open] & {
        margin-bottom: 7px;
        h2:before {
            display: block;
        }
    }
    
    details.always-open & {
        font-weight: normal;
        h2:before {
            display: block;
        }
    }

`;

export const AdviceCardTitle: React.FC<Props> = ({children, onClick, isExpandable = false}) => (
    <StyledAdviceCardTitle onClick={onClick}>
        {children}
        {isExpandable && <ChevronUpIcon className="open-indicator" />}
    </StyledAdviceCardTitle>
);
