import {reducerWithInitialState} from "typescript-fsa-reducers";

import {
    rejectSynonymsAction,
    resetAdviceAction,
    resetStateAction,
    setSelectedAdviceItemAction,
    HighlightSpellErrorPayload,
    ResetStatePayload,
    SetSelectedAdviceItemPayload,
} from './Actions';
import { ADVICE_TYPE, AdviceState } from "./State";
import {HistoryProps, SynonymListProps} from '../../Components/Advices/Util/Props';
import {
    correctErrorAction,
    getSynonymsFailedAction,
    getSynonymsPendingAction,
    getSynonymsSuccessfulAction,
    highlightAdviceSpellErrorAction,
    ignoreErrorAction,
    textCheckErrorAction,
    textCheckPendingAction,
    textCheckSuccessfulAction,
    highlightSynonymCorrectionAction,
    CheckErrorPayload,
    CheckPendingPayload,
    CheckSuccessfulPayload,
    CorrectErrorPayload,
    IgnoreAdviceItemPayload,
    SynonymHighlightPayload,
    getSynonymsCleanAction
} from "../PadState";


const textCheckPendingReducer = (state: AdviceState, payload: CheckPendingPayload) => ({
    ...state,
    hasErrors: false,
    errors: {},
    isLoading: true,
    textLength: payload.textLength,
});

const textCheckSuccessfulReducer = (state: AdviceState, payload: CheckSuccessfulPayload) => ({
    ...state,
    hasErrors: false,
    errors: {},
    isLoading: false,
    spellAdvices: payload.data.spellAdvices,
    styleAdvices: payload.data.styleAdvices,
});

const textCheckErrorReducer = (state: AdviceState, payload: CheckErrorPayload) => ({
    ...state,
    hasErrors: true,
    errors: payload.data,
    isLoading: false,
});

const undoHistoryReducer = (state: AdviceState, payload: ResetStatePayload) => (
    {
        ...state,
        resultHistory: payload.adviceHistory,
        spellAdvices: payload.spellAdvices,
        styleAdvices: payload.styleAdvices,
    }
);

const errorIgnoredReducer = (state: AdviceState, payload: IgnoreAdviceItemPayload) => {
    const resultHistory: HistoryProps[] = [payload.adviceHistory, ...state.resultHistory];

    const spellAdvices = state.spellAdvices.filter(
        (advice) => !(advice.originalError === payload.adviceHistory.originalError
            && advice.offset === payload.adviceHistory.offset
            && advice.length === payload.adviceHistory.length
        ),
        payload.adviceHistory,
    );

    const styleAdvices = state.styleAdvices.filter(
        (advice) => !(advice.originalError === payload.adviceHistory.originalError
            && advice.offset === payload.adviceHistory.offset
            && advice.length === payload.adviceHistory.length
        ),
        payload.adviceHistory,
    );
    return (
        {
            ...state,
            resultHistory,
            spellAdvices,
            styleAdvices,
        }
    );
};

const resetAdviceReducer = (state: AdviceState) => ({
    ...state,
    hasErrors: false,
    isLoading: false,
    spellAdvices: [],
    styleAdvices: [],
    resultHistory: [],
    textLength: 0,
    correctionCounter: 0,
    selectedAdviceItem: undefined,
    openResultEntityKey: '-1'
});

export const errorCorrectedReducer = (state: AdviceState, correctErrorPayload: CorrectErrorPayload) => {
    const resultHistory: HistoryProps[] = [correctErrorPayload.adviceHistory, ...state.resultHistory];

    const spellAdvices = state.spellAdvices.filter(
        (advice) => !(advice.originalError === correctErrorPayload.adviceHistory.originalError
            && advice.errorCode === correctErrorPayload.adviceHistory.errorCode
            && advice.length === correctErrorPayload.adviceHistory.length
        ),
        correctErrorPayload.adviceHistory,
    );

    const styleAdvices = state.styleAdvices.filter(
        (advice) => !(advice.originalError === correctErrorPayload.adviceHistory.originalError
            && advice.offset === correctErrorPayload.adviceHistory.offset
            && advice.length === correctErrorPayload.adviceHistory.length
        ),
        correctErrorPayload.adviceHistory,
    );
    return (
        {
            ...state,
            resultHistory,
            spellAdvices,
            styleAdvices,
            correctionCounter: ++state.correctionCounter,
        }
    );
};

export const highlightAdviceSpellErrorReducer = (state: AdviceState, payload: HighlightSpellErrorPayload) => ({
    ...state,
    highlightedSpellError: payload.entityKey,
});

export const getSynonymsPendingReducer = (state: AdviceState) => ({
    ...state,
    getSynonymsPending: true,
    synonymList: [],
});

export const getSynonymsSuccessfulReducer = (state: AdviceState, payload: { query?: string, list?: SynonymListProps[] }) => ({
    ...state,
    getSynonymsPending: false,
    ...(payload.query !== undefined && { synonymQuery: payload.query }),
    ...(payload.list !== undefined && { synonymList: payload.list }),
});

export const getSynonymsCleanReducer = (state: AdviceState) => ({
    ...state,
    synonymQuery: '',
    synonymList: [],
});

export const highlightSynonymCorrectionReducer = (state: AdviceState, payload: SynonymHighlightPayload) => ({
    ...state,
    highlightedSynonym: payload,
});

export const getSynonymsFailedReducer = (state: AdviceState) => ({
    ...state,
    getSynonymsPending: false,
});

export const rejectSynonymReducer = (state: AdviceState, payload: number) => ({
    ...state,
    synonymList: [...state.synonymList.filter((item: SynonymListProps, idx: number) => idx !== payload)],
});

export const setSelectedAdviceItemReducer = (state: AdviceState, payload?: SetSelectedAdviceItemPayload) => {
    if (!payload) {
        return {
            ...state,
            selectedAdviceItem: undefined,
        }
    }

    return {
        ...state,
        selectedAdviceItem: {
            adviceType: payload.adviceType,
            spellingIndex: payload.adviceType === ADVICE_TYPE.SpellError ? payload.index : (state.selectedAdviceItem?.spellingIndex || 0),
            styleIndex: payload.adviceType === ADVICE_TYPE.StyleError ? payload.index : (state.selectedAdviceItem?.styleIndex || 0),
        }
    };
}

export const initialState: AdviceState = {
    hasErrors: false,
    errors: {},
    isLoading: false,
    spellAdvices: [],
    styleAdvices: [],
    resultHistory: [],
    textLength: 0,
    highlightedSpellError: '',
    getSynonymsPending: false,
    synonymList: [],
    synonymQuery: '',
    highlightedSynonym: {},
    correctionCounter: 0,
};

export const reducer = reducerWithInitialState(initialState)
    .case(textCheckPendingAction, textCheckPendingReducer)
    .case(textCheckSuccessfulAction, textCheckSuccessfulReducer)
    .case(textCheckErrorAction, textCheckErrorReducer)
    .case(correctErrorAction, errorCorrectedReducer)
    .case(ignoreErrorAction, errorIgnoredReducer)
    .case(resetStateAction, undoHistoryReducer)
    .case(resetAdviceAction, resetAdviceReducer)
    .case(highlightAdviceSpellErrorAction, highlightAdviceSpellErrorReducer)
    .case(getSynonymsPendingAction, getSynonymsPendingReducer)
    .case(getSynonymsSuccessfulAction, getSynonymsSuccessfulReducer)
    .case(getSynonymsCleanAction, getSynonymsCleanReducer)
    .case(getSynonymsFailedAction, getSynonymsFailedReducer)
    .case(highlightSynonymCorrectionAction, highlightSynonymCorrectionReducer)
    .case(rejectSynonymsAction, rejectSynonymReducer)
    .case(setSelectedAdviceItemAction, setSelectedAdviceItemReducer)
    .build();
