import {ContentBlock, ContentState, DraftDecorator} from 'draft-js';
import {List} from 'immutable';

const DELIMITER = ':';
const DECORATOR_DELIMITER = ',';

function occupySlice(targetArr: string[], start: number, end: number, componentKey: string): void {
    for (let ii = start; ii < end; ii++) {
        targetArr[ii] = targetArr[ii]
            ? targetArr[ii] + DECORATOR_DELIMITER + componentKey
            : componentKey;
    }
}

export class NestableDecorator {
    public decorators: DraftDecorator[];
    public componentCache: { [key: string]: Function };

    public constructor(decorators: DraftDecorator[]) {
        this.decorators = decorators.slice();
        this.componentCache = {};
    }

    public getDecorations(block: ContentBlock, contentState: ContentState): List<string> { // return immutable List
        const decorations = Array(block.getText().length).fill(undefined);
        this.decorators.forEach(
            (decorator: DraftDecorator, ii: number) => {
                let counter = 0;
                const strategy = decorator.strategy;
                strategy(
                    block,
                    (start: number, end: number) => {
                        occupySlice(decorations, start, end, `${ii}${DELIMITER}${counter}`);
                        counter++;
                    },
                    contentState,
                );
            },
        );
        return List(decorations);
    }

    public getComponentForKey(key: string): Function {
        if (this.componentCache[key]) {
            return this.componentCache[key];
        }

        const components = key.split(DECORATOR_DELIMITER)
            .map((k: string) => parseInt(k.split(DELIMITER)[0], 10))
            .map((k: number) => this.decorators[k].component);

        // tslint:disable-next-line:no-any
        this.componentCache[key] = (props: any) => components.reduce(
            // tslint:disable-next-line:no-any
            (children: any, Outer: any) => (
                <Outer {...props} children={children}/>
            ),
            props.children,
        );

        return this.componentCache[key];
    }

    public getPropsForKey(key: string): Object {
        const pps = key.split(DECORATOR_DELIMITER)
            .map((k: string) => parseInt(k.split(DELIMITER)[0], 10))
            .map((k: number) => this.decorators[k].props);
        return Object.assign({}, ...pps);
    }
}
