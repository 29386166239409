import styled, { css } from "styled-components";

import {Colors, Fonts, MediaBreakpoints} from "../../Styles";

export const SettingsView = styled.div`
    font-family: ${Fonts.theinhardt};
    background: ${Colors.white};
`;

export const Wrapper = styled.div`
    position: relative;
    margin-left: 20px;
    margin-top: 32px;
    max-height: calc(100vh - 200px);
    overflow: auto;
    max-width: 761px;
    padding: 12px;


    @media screen and ${MediaBreakpoints.medium} {
        margin-left: 95px;
        margin-right: 24px;
    }
`

export const Version = styled.div`
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: ${Colors.osloGray};
`;

export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        font-family: ${Fonts.theinhardt};
        font-size: 18px;
    }

    &.clean {
        margin-bottom: 5px;
        label {
            font-weight: 700;
        }
    }

    &.error {
        color: ${Colors.crimson};
    }

    &.with_icon {
        margin-top: 16px;

        div {
            display: flex;
            align-items: center;
            gap: 16px;

            svg {
                cursor: pointer;

                &.edit {
                    ellipse {
                        fill: ${Colors.mineShaft};
                    }

                    path {
                        stroke: ${Colors.white};
                    }
                }

                &.saved {
                    ellipse {
                        fill: ${Colors.pastelGreen};
                    }

                    path {
                        stroke: ${Colors.white};
                    }
                }
            }
        }
    }
`;

export const FormSection = styled.div<{ isForbidden?: boolean }>`
    position: relative;
    
    ${({ isForbidden }) => isForbidden && css`
        & > * {
          display: none;

            @media screen and ${MediaBreakpoints.medium} {
                display: flex;
            }
        }
    `}
`;

export const FormGroup = styled.fieldset`
    padding: 24px 0;
    margin: 24px 0;
    border: none;
    background-color: ${Colors.white};

    p {
        font-family: ${Fonts.theinhardt};
        font-weight: 700;
        font-size: 26px;
        color: ${Colors.mineShaft};
        display: block;
        width: 100%;
        line-height: 31px;
        margin-top: 0;
    }
    
    span {
        font-family: ${Fonts.theinhardt};
        font-weight: 400;
        font-size: 18px;
        color: ${Colors.mineShaft};
        display: block;
        width: 100%;
        line-height: 25px;
        margin-bottom: 24px;
    }
`;

export const Divider = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: ${Colors.iron};
`;

export const NumberInput = styled.input`
    width: 40px;
    height: 24px;
    padding: 5px 8px 5px 9px;
    font-size: 13px;
    text-align: center;
    border: 1px solid ${Colors.iron};
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const ButtonStyles = css<{disabled?: boolean}>`
    cursor: ${({disabled}) => disabled ? "default" : "pointer"};
    padding: 14px 10px;
    background-color: ${({disabled}) => disabled ? "rgba(51, 51, 51, 0.4)" : Colors.mineShaft};;
    color: ${Colors.white};
    font-size: 14px;
    font-family: ${Fonts.theinhardt};
    font-weight: 700;
    text-transform: uppercase;
    line-height: 18px;
    letter-spacing: 0.75px;
    word-wrap: break-word;
    margin-top: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border: transparent;

    &:hover {
      background-color: ${({disabled}) => disabled ? "" : "rgba(51, 51, 51, 0.9)"};;
    }
`;

export const ForbiddenCta = styled.a`
    ${ButtonStyles};
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    fill: white;
    padding: 14px 10px;
    
    text-decoration: none;
`

export const SubmitButton = styled.button<{disabled?: boolean}>`
    ${ButtonStyles};

    @media ${MediaBreakpoints.wide} {
        margin-top: 64px;
    }
`;

export const ForbiddenWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: #ffffff8c;
    z-index: 1;

    @media screen and ${MediaBreakpoints.medium} {
        position: absolute;
    }
`;

export const ForbiddenContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.mystic};
    padding: 30px 30px 40px;
    width: 100%;

    @media screen and ${MediaBreakpoints.medium} {
        width: 280px;
    }
    
    & > p {
        font-family: ${Fonts.theinhardt};
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin: 0 0 10px;
    }
`;
