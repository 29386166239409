import React, { RefObject, useLayoutEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CorrectionInlineAdvice } from '.';
import { useCorrectionInlineAdviceItem } from '../../Hooks/useCorrectionInlineAdviceItem';
import useCorrectionStore from '../../Hooks/useCorrectionStore';
import { selectUserFeatures } from '../../Store/UserState';
import { hasFeature } from '../../Util/UserUtils';

interface PositionCalculationCollection {
    markElement: HTMLElement;
    markBounding: DOMRect;
    deskBounding: DOMRect;
}

interface InlineAdviceWrapperPropsI {
    deskRef: RefObject<HTMLElement>;
}

const StyledInlineAdviceWrapper = styled.div`
    position: absolute;
    z-index: 500;
    height: unset !important;
    padding-top: 5px;
`;

const CorrectionInlineAdviceWrapper: React.FC<InlineAdviceWrapperPropsI> = ({ deskRef }) => {
    const [startToCloseInlineAdvice, stopToCloseInlineAdvice] = useCorrectionStore(store => [store.handleStartToCloseInlineAdvice, store.handleStopToCloseInlineAdvice]);
    const [inlineAdviceWidth, setInlineAdviceWidth] = useState(0);
    const [inlineAdviceHeight, setInlineAdviceHeight] = useState(0);
    const userFeatures = useSelector(selectUserFeatures);
    const { inlineAdvice } = useCorrectionInlineAdviceItem(); // TODO: skip hook usage
    let layoutCount = 0;
    // We need the width of the rendered inline advice popover.

    //TODO: fix eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        layoutCount++;
        const inlineAdvice = document.getElementsByClassName('inline-advice__wrapper').item(0);
        if (inlineAdvice && layoutCount % 10 !== 0) {
            const adviceBounding = inlineAdvice.getBoundingClientRect();
            if (adviceBounding.width !== inlineAdviceWidth) {
                setInlineAdviceWidth(adviceBounding.width);
            }
            if (adviceBounding.height !== inlineAdviceHeight) {
                setInlineAdviceHeight(adviceBounding.height);
            }
        }
        //TODO: fix eslint
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    // const adviceResults = advices.filter((result: Advice) => (result.entityKey === inlineAdviceItemKey), inlineAdviceItemKey);
    // const advice = adviceResults.shift();
    const advice = inlineAdvice;

    if (advice === undefined) {
        return null;
    }

    if (!hasFeature('inlineAdvices', userFeatures)) {
        return null;
    }

    function getBoundings(): PositionCalculationCollection | undefined {
        const markSelector = `mark[data-llm-id="${inlineAdvice?.id}"]`;

        const markElement = document.querySelector(markSelector) as HTMLElement;
        const deskElement = deskRef.current;

        if (!markElement || !deskElement) {
            return;
        }

        // if marker is multiline set inline advice starting point to first line
        const markBounding = markElement.getClientRects().length > 1 ? markElement.getClientRects()[0] : markElement.getBoundingClientRect();
        const deskBounding = deskElement.getBoundingClientRect();

        return { markElement, markBounding, deskBounding };
    }

    function getInlineAdviceOffset(): number {
        const boundings = getBoundings();
        if (!boundings) {
            return 0;
        }
        // Calculate the difference between the right side of the left pad and the right side of the
        // inline advice starting from the left side of the mark.
        // The inline advice is then moved to the left based on the calculated difference.
        //
        //       Left Pad          | <- Right side of the left pad
        // _______________M________|xxxx
        // _______________|InlineAdvice|
        // M - the beginning of the marked error.
        // xxxx - the difference between the left pad's and the inline advice's right side.
        // Or in other words xxxx is the space that exceeds the left pad's right side.
        const percentagePadding = 0.1;
        const borderOffsetValue = boundings.deskBounding.right - (boundings.markBounding.left + inlineAdviceWidth);
        return borderOffsetValue < 0
            ? Math.floor(borderOffsetValue + Math.abs(borderOffsetValue * percentagePadding))
            : 0;
    }

    function getAdvicePositionInPad(): { top: number; left: number } | undefined {
        const inlineAdviceOffset = { x: -20, y: 10 };
        const boundings = getBoundings();

        if (!boundings) {
            return;
        }
        const leftPos = boundings.markBounding.left - boundings.deskBounding.left;
        const bottomPos = boundings.markBounding.top - boundings.deskBounding.top + boundings.markBounding.height + inlineAdviceOffset.y;
        const topPos = boundings.markBounding.top - boundings.deskBounding.top - inlineAdviceHeight - inlineAdviceOffset.y;
        return {
            left: Math.max(leftPos + getInlineAdviceOffset() + inlineAdviceOffset.x, 0),
            top: isInlineAdviceBottom() ? bottomPos : topPos,
        };
    }

    function getMarkerPositionForAdvice(): number {
        const minimumOffset = 20;
        const offset = Math.abs(getInlineAdviceOffset()) + minimumOffset;

        return offset > minimumOffset ? offset : minimumOffset;
    }

    function isInlineAdviceBottom(): boolean {
        const minimumOffset = 10;
        const boundings = getBoundings();

        if (!boundings) {
            return true;
        }

        return boundings.deskBounding.bottom - (boundings.markBounding.bottom + minimumOffset) > inlineAdviceHeight;
    }

    /** only set next advice if it is currently selected */
    // const setNextAdviceItem = (): void => {
    //     if (!selectedAdviceItem) {
    //         return;
    //     }
    //
    //     const selectedAdvice = selectedAdviceItem.adviceType === 'SPELL-ERROR'
    //         ? spellAdvices[selectedAdviceItem.spellingIndex]
    //         : styleAdvices[selectedAdviceItem.styleIndex];
    //
    //     if (advice === selectedAdvice) {
    //         setNextSelectedAdvice();
    //     }
    // };

    return (
        <StyledInlineAdviceWrapper
            className="inline-advice__wrapper"
            style={getAdvicePositionInPad()}
            onMouseEnter={stopToCloseInlineAdvice}
            onMouseLeave={startToCloseInlineAdvice}
        >
            <CorrectionInlineAdvice
                advice={advice}
                markerPositionLeft={getMarkerPositionForAdvice()}
                markerPositionTop={isInlineAdviceBottom()}
                // setNextAdviceItem={setNextAdviceItem}
            />
        </StyledInlineAdviceWrapper>
    );
};

export default CorrectionInlineAdviceWrapper;