// tslint:disable:no-any
import {EditorState, Modifier} from 'draft-js';
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';

// import {State} from 'FullVersion/Store'; TODO: move redux away from library
import {editorChangedAction, getSynonymsSuccessfulAction} from './Actions';
import {SynonymListProps} from '../../Components/Advices/Util/Props';
import {createOrUpdateEntity, decoratorStrategyType, deleteAllEntitiesWithMetadata} from '../../Util/Draft';

export const showSynonymsMiddleWare: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {

            if (action.type === getSynonymsSuccessfulAction.type) {
                const { list: synonymList } = action.payload as { list: SynonymListProps[], query: string };
                if (synonymList.length < 1) {
                    return next(action);
                }
                const {editorState, entityKey} = getEditorStateWithSynonymHighlighting(
                    api.getState().padState.editorState,
                );
                synonymList.forEach((synonym: SynonymListProps) => {
                    synonym.entityKey = entityKey;
                });
                api.dispatch(editorChangedAction({
                    oldEditorState: api.getState().padState.editorState,
                    newEditorState: editorState}),
                );
            }
            // Call the next dispatch method in the middleware chain.
            return next(action);

        };

export const getEditorStateWithSynonymHighlighting = (editorState: EditorState):
    {editorState: EditorState; entityKey: string}  => {
    const currentContentState = editorState.getCurrentContent();
    const originalSelection = editorState.getSelection();
    editorState = deleteAllEntitiesWithMetadata(editorState, decoratorStrategyType.wordHighlighted);

    const contentStateWithEntity = createOrUpdateEntity(
        originalSelection,
        currentContentState,
        editorState,
        {customTypes: [decoratorStrategyType.wordHighlighted]},
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentState = Modifier.applyEntity(
        contentStateWithEntity,
        originalSelection,
        entityKey,
    );
    editorState = EditorState.push(editorState, contentState, 'insert-characters');
    editorState = EditorState.set(editorState, {selection: originalSelection});

    return {editorState, entityKey};
};
