import * as React from 'react';

interface Props {
    offsetKey: string;
    entityKey: string;
}

export class SpellErrorIgnored extends React.Component<Props> {

    public render(): JSX.Element {
        return (
            <span
                data-entity-key={this.props.entityKey}
                data-offset-key={this.props.offsetKey}
                id={`decorator-ignored-entity-id-${this.props.entityKey}`}
            >
                {this.props.children}
            </span>
        );
    }
}
