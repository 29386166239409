import React from "react";

import styled, { css } from "styled-components";

import {Colors, Fonts} from "../../Styles";


export type SquareButtonProps = {
    className?: string;
    defaultColor?: string;
    defaultBackground?: string;
    hoverBackground?: string;
    hoverColor?: string;
    disabled?: boolean;
    fontSize?: number;
    style?: React.CSSProperties;
    textTransform?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    children?: React.ReactNode;
    type?: string;
}

export const SquareButtonStyle = css<SquareButtonProps>`
    background-color: ${(p) => p.defaultBackground};
    color: ${(p) => p.defaultColor};
    display: flex;
    font-family: ${Fonts.theinhardt};
    font-size: ${(p) => `${p.fontSize}px` || "inherit"};
    font-weight: 500;
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 10px 24px;
    margin: 0;
    text-transform: ${(p) => p.textTransform || "none"};

    &:hover {
        background-color: ${(p) => p.hoverBackground || p.defaultBackground};
        color: ${(p) => p.hoverColor || p.defaultColor};
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        background-color: ${(p) => p.defaultBackground};
        opacity: 0.5;
    }

    &.dark {
        background: ${Colors.mineShaft};
        color: ${Colors.white};

        &:hover {
            background: ${Colors.tundora};
            color: ${Colors.white};
        }
    }

    &.white {
        background: ${Colors.white};
        color: ${Colors.mineShaft};
        border: 1px solid ${Colors.mercury};

        &:hover {
            background: ${Colors.mercury};
            color: ${Colors.mineShaft};
        }
    }
    
    svg {
        margin-left: 5px;
    }
`;

export const SquareButton: React.FC<SquareButtonProps> = styled.button`
    ${SquareButtonStyle}
`;

SquareButton.defaultProps = {
    defaultBackground: Colors.supernova,
    defaultColor: Colors.mineShaft,
    hoverBackground: Colors.sun,
    fontSize: 16,
    textTransform: "uppercase",
};
