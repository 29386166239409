import React, { useCallback, useEffect, useMemo } from "react";

import { renderToStaticMarkup } from "react-dom/server";

import { BlockContent } from "./BlockContent/BlockContent";
import useOneClickStore, { getDataSelector, Segment, selectSegments } from "../../Hooks/useOneClickStore";

type ExpressTextSegmentProps = {
    data: Segment;
    previousKey?: string;
};

const ExpressTextSegment: React.FC<ExpressTextSegmentProps> = ({ data, previousKey }) => {
    const { id, text, blocks, traceBlocks, isSkipped, originText, issue } = data;
    const isSegmentLoading = useOneClickStore((store) => store.isSegmentLoading);
    const editorNode: HTMLDivElement | null = useOneClickStore((store) => store.editorNode);

    const segments = useOneClickStore(selectSegments);
    const requestsRemaining = useOneClickStore((store) => store.requestsRemaining);
    const handleSetSingleCorrectionActiveIndex = useOneClickStore((store) => store.handleSetSingleCorrectionActiveIndex);
    const setIsHighlightModeActive = useOneClickStore((store) => store.setIsHighlightModeActive);
    const isSingleCorrectionMode = useOneClickStore((store) => store.singleCorrectionActiveIndex !== -1);
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const isActive = useOneClickStore((store) => {
        const dataSelector = getDataSelector(store);
        const activeIndex = store.singleCorrectionActiveIndex;

        if (activeIndex !== -1) {
            const currentIndex = store[dataSelector].segments.findIndex((segment) => segment.id === id);

            return currentIndex === activeIndex;
        }

        return false;
    });
    const isCorrected = !!traceBlocks?.length || isParaphrasingModeActive;
    const isClickable = !isActive && !!(isCorrected || issue);

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement("span");

        spanNode.classList.add("text-segment");

        if (id) {
            spanNode.id = id;
        }

        return spanNode;
    }, [id]);

    const handleSegmentSelect = useCallback((event) => {
        if (isClickable) {
            event.preventDefault();
            event.stopPropagation();

            const currentIndex = segments.findIndex((segment: Segment) => segment.id === id);

            if (currentIndex >= 0) {
                setIsHighlightModeActive(true, true);
                handleSetSingleCorrectionActiveIndex({ value: currentIndex });
            }
        }
    }, [handleSetSingleCorrectionActiveIndex, setIsHighlightModeActive, id, isClickable, segments]);

    const isLoading = isSegmentLoading(id);
    const isBlured = (isSingleCorrectionMode && !isActive) || (requestsRemaining === 0 && !isCorrected);

    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode)
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode)
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {

        return () => {
            segmentNode?.remove();
        };
    }, [editorNode, segmentNode]);

    useEffect(() => {
        const blocksContent = isSkipped && originText ?
            renderToStaticMarkup(<>{originText}</>) :
            renderToStaticMarkup(blocks?.length ? <BlockContent blocks={blocks} /> : <>{text}</>);

        segmentNode && (segmentNode.innerHTML = blocksContent);
    }, [blocks, isSkipped, originText, segmentNode, text]);

    useEffect(() => {
        if (isLoading) {
            segmentNode?.classList.add("loading");
        } else {
            segmentNode?.classList.remove("loading");
        }

    }, [isLoading, segmentNode]);

    useEffect(() => {
        if (isBlured) {
            segmentNode?.classList.add("blured");
        } else {
            segmentNode?.classList.remove("blured");
        }

    }, [isBlured, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener("click", handleSegmentSelect);

        return () => {
            segmentNode?.removeEventListener("click", handleSegmentSelect);
        };
    }, [handleSegmentSelect, segmentNode]);

    return null;
};

export default ExpressTextSegment;