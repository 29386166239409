import * as React from 'react';

export const LinkedinIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <title>LinkedIn</title>
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M0 0H36V36H0z" transform="translate(-1132 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(204)"/>
            <g fillRule="nonzero">
                <path fill="#2A7AB9" d="M26.335 0C28.358 0 30 1.642 30 3.665v22.67C30 28.358 28.358 30 26.335 30H3.665C1.642 30 0 28.358 0 26.335V3.665C0 1.642 1.642 0 3.665 0h22.67z"
                      transform="translate(-1132 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(204) translate(3 3)"/>
                <path fill="#FFF"
                      d="M9.093 11.68v13.633H4.56V11.68h4.533zm11.127-.327c2.98 0 5.213 1.947 5.213 6.14v7.82H20.9v-7.3c0-1.833-.653-3.08-2.293-3.08-1.254 0-2 .84-2.327 1.66-.12.294-.147.7-.147 1.107v7.613H11.6v-.168c.004-.742.018-3.81.024-6.842l.001-.672.001-.665v-2.19c-.002-1.547-.01-2.743-.026-3.096h4.533v1.927c.6-.927 1.68-2.254 4.087-2.254zM6.86 5.107c1.553 0 2.507 1.02 2.533 2.353 0 1.307-.986 2.353-2.566 2.353H6.8c-1.52 0-2.5-1.046-2.5-2.353 0-1.34 1.013-2.353 2.56-2.353z"
                      transform="translate(-1132 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(204) translate(3 3)"/>
            </g>
        </g>
    </svg>
);
