import styled from 'styled-components';

import {Fonts, MediaBreakpoints} from "../../Styles";
import { SquareButtonStyle } from "../Buttons/SquareButton";

export const Wrapper = styled.div<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 10px;
    box-shadow: 4px 0 8px 0 rgb(0 0 0 / 15%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: ${Fonts.theinhardt};
    height: 100%;
    padding: 17px 30px 20px 20px;
    transition: box-shadow 0.3s ease-in-out;
    width: 100%;
    max-width: 345px;
    
    :hover {
        box-shadow: 12px 6px 16px 0 rgba(0, 0, 0, 0.3);
    }

    p {
        font-size: 18px;
        line-height: 1.39;
        margin: 0 0 10px;
    }
    
    a {
        ${SquareButtonStyle};
        margin: auto auto 0;
        text-decoration: none;
        text-transform: uppercase;
        > svg {
            fill: currentColor;
        }
    }
`;

export const TileHeading = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and ${MediaBreakpoints.small} {
        flex-direction: row;
    }
    
    h2 {
        font-family: ${Fonts.theinhardt};
        font-size: 26px;
        line-height: 1.19;
        margin: 0;
        padding-bottom: 35px;
        position: relative;
    }
    
    > img {
        margin: 0 20px 20px 0;
        align-self: center;
        
        @media screen and ${MediaBreakpoints.small} {
            align-self: flex-start;
        }
    }
    
    button {
        position: absolute;
        left: 0;
        bottom: -7px;
    }
`