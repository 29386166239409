// tslint:disable:no-any
// import {State as MainState} from 'FullVersion/Store'; TODO: move state away from library
import {CheckSettings, State, UserFeature, UserRole} from './State';

export const selectUserStateFromMainState = (mainState: any): State => mainState.userState;

export const selectUserID = (state: any): number => selectUserStateFromMainState(state).id;
export const selectUsername = (state: any): string => selectUserStateFromMainState(state).username;
export const selectUserRoles = (state: any): UserRole[] => selectUserStateFromMainState(state).roles;
export const selectUserHasUsedTrial = (state: any): boolean => selectUserStateFromMainState(state).hasUsedTrial;
export const selectUserShowDiscount = (state: any): boolean => selectUserStateFromMainState(state).showDiscount;
export const selectUserHasHadPremium= (state: any): boolean => selectUserStateFromMainState(state).hasHadPremium;
export const selectUserTrialDuration = (state: any): number => selectUserStateFromMainState(state).trialDuration;
export const selectUserTrialDaysRemaining = (state: any): number => (
    selectUserStateFromMainState(state).trialDaysRemaining
);
export const selectUserCharacterLimit = (state: any): number =>
    selectUserStateFromMainState(state).characterLimit;
export const selectUserFeatures = (state: any): UserFeature[] =>
    selectUserStateFromMainState(state).features;
export const selectCheckSettings = (state: any): CheckSettings => selectUserStateFromMainState(state).checkSettings;
export const selectConsentDecision = (state: any): string => selectUserStateFromMainState(state).hide_consent;
