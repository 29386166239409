import { useQuery } from '@tanstack/react-query';

import useCorrectionStore from './useCorrectionStore';
import { DocumentIdentifier } from '../Util/DocumentIdentifier';
import { apiFetch, RequestMethod } from '../Util/RequestApi';

export type Synonym = {
    description: string;
    entityKey?: string;
    originalWord: string;
    prag_info?: string
    synonyms: string[];
    word_class: string;
    id: number;
}

const useSynonymList = () => {
    const synonymQuery = useCorrectionStore(store => store.synonymQuery);

    const response = useQuery<Synonym[]>({
        queryKey: ['synonyms', synonymQuery],
        queryFn: async () => {
            try {
                const response = await apiFetch(
                    'api/synonyms',
                    {
                        method: RequestMethod.post,
                        baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                        body: JSON.stringify({
                            word: synonymQuery,
                            numberOfReturnedSynonyms: 50,
                            documentID: DocumentIdentifier.get(),
                        }),
                    },
                );

                if (response.status !== 200) {
                    return;
                }

                return await response.json();
            } catch (_err) {
                return;
            }
        },
        enabled: !!synonymQuery,
        staleTime: Number.POSITIVE_INFINITY,
    });

    return response;
};

export default useSynonymList;