import {dictionarySaga} from 'library/src/Store/DictionaryState';
import {padSaga} from 'library/src/Store/PadState';
import {settingsSaga} from 'library/src/Store/SettingsState';
import {userSaga} from 'library/src/Store/UserState';
import {all, fork, Effect} from 'redux-saga/effects';

export function* rootSaga(): IterableIterator<Effect> {
    yield all([
        fork(dictionarySaga),
        fork(userSaga),
        fork(padSaga),
        fork(settingsSaga),
    ]);
}
