// tslint:disable:no-any
// import {State as MainState} from 'FullVersion/Store'; TODO: move redux away from library
import { AdviceState } from "./State";

export const selectCheckStateFromMainState = (mainState: any): AdviceState => mainState.checkState;

export const selectSpellAdvices = (state: any) => selectCheckStateFromMainState(state).spellAdvices;
export const selectStyleAdvices = (state: any) => selectCheckStateFromMainState(state).styleAdvices;
export const selectHistory = (state: any) => selectCheckStateFromMainState(state).resultHistory;
export const selectHasErrors = (state: any) => selectCheckStateFromMainState(state).hasErrors;
export const selectErrors = (state: any) => selectCheckStateFromMainState(state).errors;
export const selectIsLoading = (state: any) => selectCheckStateFromMainState(state).isLoading;
export const selectTextLength = (state: any) => selectCheckStateFromMainState(state).textLength;
export const selectIsSynonymsLoading = (state: any) => selectCheckStateFromMainState(state).getSynonymsPending;
export const selectSynonymList = (state: any) => selectCheckStateFromMainState(state).synonymList;
export const selectSynonymQuery = (state: any) => selectCheckStateFromMainState(state).synonymQuery;
export const selectHighlightedSynonym = (state: any) => selectCheckStateFromMainState(state).highlightedSynonym;
export const selectHighlightedAdviceSpellError = (state: any) => (
    selectCheckStateFromMainState(state).highlightedSpellError
);
export const selectCorrectionCounter = (state: any) => selectCheckStateFromMainState(state).correctionCounter;
export const selectSelectedAdviceItem = (state: any) => selectCheckStateFromMainState(state).selectedAdviceItem;
