import {reducerWithInitialState} from 'typescript-fsa-reducers';

import {removeMessagesAction, setHideStyleAdviceAction, updateCheckSettingsFailedAction, UpdateCheckSettingsPayload, updateCheckSettingsSuccessfulAction, userDataLoadedAction} from './Actions';
import {State, UserDataPayload, UserRole} from './State';
import useOneClickStore from "../../Hooks/useOneClickStore";
import {
    addDictionaryEntrySuccessfulAction,
    getDictionaryEntriesSuccessfulAction,
    GetDictionaryEntriesSuccessfulPayload,
} from '../DictionaryState';

/**
 * @param {State} state
 * @param {UserDataPayload} userData
 * @returns {State}
 */
const loadUserDataReducer = (state: State, userData: UserDataPayload): State => {

    const isAuthorizedUser = !!userData.email;

    useOneClickStore.setState({
        isOneClickAgreed: userData.hide_consent === '1',
        ...(isAuthorizedUser && { isExpressOnboarded: userData.hide_onboarding === '1' }),
    })

    return {
        ...state,
        features: userData.features,
        id: userData.id,
        username: userData.username,
        roles: userData.roles,
        hasUsedTrial: userData.hasUsedTrial,
        showDiscount: userData.showDiscount,
        hasHadPremium: userData.hasHadPremium,
        trialDuration: userData.trialDuration,
        trialDaysRemaining: userData.trialDaysRemaining,
        characterLimit: userData.characterLimit || initialState.characterLimit,
        autoLogInterval: userData.autoLogInterval || initialState.autoLogInterval,
        checkSettings: userData.checkSettings,
        hide_consent: userData.hide_consent,
        hide_onboarding: userData.hide_onboarding
    }}
;

const getDictionaryEntriesSuccessfulReducer = (
    state: State, payload: GetDictionaryEntriesSuccessfulPayload,
): State => (
    {
        ...state,
        dictionaryWordCount: payload.dictionaryEntries.length,
    }
);

const addDictionaryEntrySuccessfulReducer = (state: State): State => (
    {
        ...state,
        dictionaryWordCount: state.dictionaryWordCount + 1,
    }
);

const setHideStyleAdviceReducer = (state: State, payload: boolean): State => (
    {
        ...state,
        hideStyleAdvice: payload,
    }
);

const updateCheckSettingsSuccessfulReducer = (state: State, payload: UpdateCheckSettingsPayload): State => (
    {
        ...state,
        checkSettings: {...state.checkSettings, ...payload.settings, messages: {type: "success", text: "Einstellungen wurden erfolgreich gespeichert."}},
    }
);

const updateCheckSettingsFailedReducer = (state: State): State => ({
    ...state, checkSettings: {...state.checkSettings, messages: {
        type: "error",
        text: "Einstellungen konnten nicht gespeichert werden, bitte versuchen Sie es erneut."
    }}
});

const removeMessagesReducer = (state: State): State => ({...state, checkSettings: {...state.checkSettings, messages: undefined}});

export const initialState: State = {
    features: [
        {name: 'inlineAdvices', available: true}
    ],
    roles: [UserRole.Gast],
    id: -1,
    username: '',
    hasUsedTrial: false,
    trialDuration: 7,
    trialDaysRemaining: -1,
    dictionaryWordCount: 0,
    hideStyleAdvice: false,
    characterLimit: 800,
    autoLogInterval: 0,
    showDiscount: false,
    hasHadPremium: false,
    checkSettings: {
        spelling: true,
        styleFiller: true,
        styleOverlong: true,
        styleOverlongWordCount: 25,
        styleWordFrequency: true,
        applyDpfCommaRules: true,
        language: 'de',
        privateMode: false,
        liloVersion: '0.0.1',
        llmVersion: '0.0.1',
    },
    email: '',
    inlineAdvices: {},
    hide_consent: '0',
    hide_onboarding: '0'
};

export const reducer = reducerWithInitialState(initialState)
    .case(userDataLoadedAction, loadUserDataReducer)
    .case(getDictionaryEntriesSuccessfulAction, getDictionaryEntriesSuccessfulReducer)
    .case(addDictionaryEntrySuccessfulAction, addDictionaryEntrySuccessfulReducer)
    .case(setHideStyleAdviceAction, setHideStyleAdviceReducer)
    .case(updateCheckSettingsSuccessfulAction, updateCheckSettingsSuccessfulReducer)
    .case(updateCheckSettingsFailedAction, updateCheckSettingsFailedReducer)
    .case(removeMessagesAction, removeMessagesReducer)
    .build();
