import * as React from 'react';

/**
 * Interface for Component State
 */
interface OwnState {
    hasErrors: boolean;
}

/**
 * * Interface for Component Props
 */
interface OwnProps {

}

/**
 * This class wraps react errors, so that the whole app won't break
 */
export class ErrorWrapper extends React.Component<OwnProps, OwnState> {

    public constructor(props: OwnProps) {
        super(props);

        this.state = {
            hasErrors: false,
        };
    }
    /**
     * When this method exists errors will be caught
     * @param error
     * @param info
     */
    public componentDidCatch(error: Error, info: React.ErrorInfo): void  {
        console.error('A rendering error was caught', error);
        this.setState({
            hasErrors: true,
        });
    }

    /**
     * Renders error message when a severe React Dom Error was caught. This should actually never happen.
     * @returns {JSX.Element}
     */
    public render(): JSX.Element {
        if (this.state.hasErrors) {
            return (
                <div className="phase">
                    <span className="phase__status">⚠️</span>
                    {' '}
                    <span className="phase__text">
                        Unser Service ist zur Zeit leider nicht verfügbar.<br/>
                        Bitte versuchen Sie es später erneut.
                    </span>
                </div>

            );
        }
        return (<>{this.props.children}</>);
    }
}
