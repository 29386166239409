import React, { useState, useRef, useCallback } from "react";

import { useSelector } from "react-redux";

import * as SC from './SpellcheckFull.styled';
import { AdvicePanel } from '../../Components/Advice/AdvicePanel';
import { CorrectionAdvicePanel } from '../../Components/CorrectionAdvicePanel';
import { CorrectionHandshake } from '../../Components/CorrectionHandshake';
import { CorrectionInlineAdviceWrapper } from '../../Components/CorrectionInlineAdvice';
import { CorrectionModeButtons } from '../../Components/CorrectionModeButtons/CorrectionModeButtons';
import { CorrectionPad } from '../../Components/CorrectionPad';
import { ExpressHandshake } from '../../Components/ExpressHandshake';
import { ExpressPad } from '../../Components/ExpressPad';
import { ExpressPadHeader } from "../../Components/ExpressPadHeader";
import { Handshake } from '../../Components/Handshake/Handshake';
import { LoadingDotsIcon } from '../../Components/Icons';
import { InlineAdviceWrapper } from '../../Components/InlineAdvice/InlineAdviceWrapper';
import { MetaMenu } from '../../Components/MetaMenu/MetaMenu';
import { ApplyTextChangesModal, ExpressConsentModal } from '../../Components/Modal';
import { Pad } from '../../Components/Pad/Pad';
import { Profiler } from '../../Components/Profiler/Profiler';
import { Rim } from '../../Components/Rim/Rim';
import useOneClickStore, { selectIsSegmentsLoading } from '../../Hooks/useOneClickStore';
import { selectFeatureFlags } from '../../Store/SettingsState';
import { Colors } from '../../Styles';
import { AdviceErrorWrapper } from '../../Util/AdviceErrorWrapper';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { PadErrorWrapper } from '../../Util/PadErrorWrapper';

export const SpellcheckFull: React.FC = () => {
    const featureFlags = useSelector(selectFeatureFlags);
    const isSegmentsLoading = useOneClickStore(selectIsSegmentsLoading);
    const isOneClickAgreed = useOneClickStore((store) => store.isOneClickAgreed);
    const isExpressModeActive = useOneClickStore((store) => store.isOneClickModeActive);
    const setIsExpressModeActive = useOneClickStore((store) => store.setIsOneClickModeActive);
    const [oneClickModal, setOneClickModal] = useState(false);
    const [applyTextChangesModal, setApplyTextChangesModal] = useState(false);

    const deskRef = useRef(null);

    const isExpressFeatureActive = featureFlags["click_correction"];
    const isNativeEditorModeActive = featureFlags["native_editor"];

    const handleExpressButtonClick = useCallback(() => {
        if (isExpressModeActive) {
            return;
        }

        GTMDataLayerPush({
            event: "spellcheck_select_option",
            option: "mentor_express"
        });

        if (isOneClickAgreed) {
            setIsExpressModeActive(true);
        } else {
            setOneClickModal(true);
        }
    }, [isExpressModeActive, isOneClickAgreed, setIsExpressModeActive]);

    const handleClassicButtonClick = useCallback(() => {
        if (!isExpressModeActive) {
            return;
        }

        GTMDataLayerPush({
            event: "spellcheck_select_option",
            option: "mentor_standard"
        });

        setIsExpressModeActive(false);
    }, [isExpressModeActive, setIsExpressModeActive]);

    return (
        <>
            <SC.Desk>
                <SC.Editor className="duden-editor" expressMode={isExpressModeActive} >
                    <SC.DeskContent ref={deskRef} viewMode={isExpressModeActive}>
                        {isExpressModeActive ? (
                            <>
                                <ExpressPadHeader />
                                <ExpressPad />
                            </>
                        ) : (
                            isNativeEditorModeActive ? <>
                                <CorrectionInlineAdviceWrapper deskRef={deskRef} />
                                <CorrectionPad />
                            </> : <>
                                <InlineAdviceWrapper deskRef={deskRef}/>
                                <PadErrorWrapper>
                                    <Pad/>
                                </PadErrorWrapper>
                            </>
                        )}
                    </SC.DeskContent>

                    <footer>
                        <Rim />
                    </footer>
                </SC.Editor>

                <SC.RightColumnWrapper columnColor={isExpressModeActive ? `${Colors.cottonBoll}` : `${Colors.mystic}`}>
                    <ApplyTextChangesModal
                        closeApplyTextChangesModal={() => setApplyTextChangesModal(false)}
                        applyTextChangesModal={applyTextChangesModal}
                    />
                    <SC.AdviceWrapper>
                        {isExpressModeActive && isSegmentsLoading && <SC.StyledLoadingIcon>
                            <LoadingDotsIcon fill={Colors.mineShaft}/>
                        </SC.StyledLoadingIcon>}

                        {/* Feature flag can be removed, as soon as the express-feature is implemented permanently */}
                        {isExpressFeatureActive && (
                            <CorrectionModeButtons
                                handleClassicButton={handleClassicButtonClick}
                                handleOneClickButton={handleExpressButtonClick}
                            />
                        )}
                        {!isExpressModeActive && (
                            <div>
                                {isNativeEditorModeActive ? <div>
                                    <CorrectionAdvicePanel />
                                    <CorrectionHandshake />
                                </div> : <AdviceErrorWrapper>
                                    <AdvicePanel/>
                                    <Handshake/>
                                </AdviceErrorWrapper>}
                                <Profiler/>
                            </div>
                        )}
                        {isExpressModeActive && <ExpressHandshake />}
                        <MetaMenu />
                    </SC.AdviceWrapper>
                </SC.RightColumnWrapper>
            </SC.Desk>
            <ExpressConsentModal
                closeOneClickModal={() => setOneClickModal(false)}
                oneClickModal={oneClickModal}
            />
        </>
    );
};