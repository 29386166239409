import styled from "styled-components";

import { Fonts } from "../../../Styles";

export const AdviceHeader = styled.h2<{ counterColor: string }>`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0 0 10px 12px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.2px;

    &:before {
        position: absolute;
        top: 4px;
        left: 0;
        content: "";
        display: none;
        border-radius: 50%;
        background: ${(p) => p.counterColor};
        width: 7px;
        height: 7px;
    }

    > span {
        background-color: ${(p) => p.counterColor};
        color: black;
        height: 32px;
        width: 32px;
        line-height: 32px;
        border-radius: 32px;
        font-size: 18px;
        display: inline-block;
        text-align: center;
        letter-spacing: normal;
        margin: auto 0 auto 14px;
        flex-shrink: 0;
    }
`;

export const HiddenTitle = styled.h1`
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
`

export const Navigation = styled.div`
    display: flex;
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
    justify-content: space-between;

    .button-navigation {
        > * + * {
            margin-left: 13px;
        }
    }
`