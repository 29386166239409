import React from 'react';

import { useSelector } from "react-redux";

import { selectUserStateFromMainState, UserRole } from "../../../Store/UserState";
import { DocumentIdentifier} from "../../../Util/DocumentIdentifier";
import { GTMDataLayerPush } from "../../../Util/GoogleTagManager";
import { PlatformTypes } from "../../../Util/PlatformUtil";
import { toastRouteGeneral, toastRouteLite } from "../../../Util/ToastOverlay";
import { hasFeature, hasRole } from "../../../Util/UserUtils";

type PremiumTouchPointLinkProps = {
    adviceType?: string;
}

export const PremiumTouchPointLink: React.FC<PremiumTouchPointLinkProps> = (props) => {
    const user = useSelector(selectUserStateFromMainState);

    const target = [PlatformTypes.lite]
        .includes(DocumentIdentifier.getPlatformIdentifier()) ? '_blank' : '_self';

    const getRoute = (): string => {
        if (DocumentIdentifier.getPlatformIdentifier() === PlatformTypes.lite) {
            return toastRouteLite;
        }

        if (hasRole(UserRole.Gast, user.roles)) {
            return process.env.REACT_APP_DUDENDE_URL + `/user/register/private?source=mentor&type=duden-trial`;
        }

        if (user.hasUsedTrial || hasFeature('check20kCharacters', user.features)) {
            return process.env.REACT_APP_DUDENDE_URL + `/user/authenticate?destination=/abonnement%3Fsource=mentor`;
        }

        // When user is logged in and did not use trial
        if (!user.hasUsedTrial && !hasFeature('check20kCharacters', user.features)) {
            return process.env.REACT_APP_DUDENDE_URL + `/user/authenticate?destination=/abonnement%3Fsource=mentor%26type=duden-trial`;
        }

        return toastRouteGeneral;
    };

    let linkText = "Premium kostenlos testen";

    if (user.hasUsedTrial) {
        linkText = "Jetzt Premium-Kunde werden";
    }

    const onClickPremium = () => {
        if (props.adviceType !== undefined) {
            GTMDataLayerPush({event: "premium_touchpoint", option: props.adviceType});
        }
    }

    return (
        <a href={getRoute()} target={target} onClick={onClickPremium}>{linkText}</a>
    )
}
