import styled, { css } from 'styled-components';

import { LinkAppearance } from './Link';
import { Colors, Fonts } from '../../Styles';

const primaryStyles = css`
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: ${Colors.supernova};
`;

const secondaryStyles = css`
  all: unset;
  cursor: pointer;
`;

export const Link = styled.a<{
  appearance?: LinkAppearance | boolean;
}>`
  ${({ appearance }) =>
    appearance === LinkAppearance.Secondary ? secondaryStyles : primaryStyles}
  color: ${Colors.mineShaft};
  font-family: ${Fonts.theinhardt};
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;

  &:hover {
    opacity: 0.75;
  }
`;
