import React from 'react';

import * as SC from './RunCheck.styled';
import useOneClickStore, { selectBufferText, selectIsSegmentsLoading, selectOneClickPercentage } from "../../Hooks/useOneClickStore";
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { RunCheckIcon } from '../Icons';

export const RunCheck: React.FC = () => {
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const isSegmentsLoading = useOneClickStore(selectIsSegmentsLoading);
    const segmentsLoadingPercentage = Math.trunc(useOneClickStore(selectOneClickPercentage));
    const handleCorrection = useOneClickStore((store) => store.handleCorrection);
    const requestsRemaining = useOneClickStore((store) => store.requestsRemaining);
    const bufferText = useOneClickStore(selectBufferText);

    const textLength = bufferText.length;
    const isDisabled = isSegmentsLoading || requestsRemaining === 0;

    const checkLabel = isParaphrasingModeActive ? 'Umformulierung starten' : 'Textkorrektur starten';
    const loadingLabel = isParaphrasingModeActive ? 'Umformulierung läuft' : 'Text wird geprüft.';

    const handleTextCheckClick = () => {
        GTMDataLayerPush({
            event: 'mentor_express_start'
        });
        handleCorrection();
    };

    return (
        isSegmentsLoading ? (
            <SC.StyledCheckProgress>
                <SC.StyledCheckTitle>{loadingLabel}</SC.StyledCheckTitle>
                {segmentsLoadingPercentage === 0 ? (
                    <SC.StyledCheckButtonLoading>
                        <RunCheckIcon />
                    </SC.StyledCheckButtonLoading>
                ) : (
                    <SC.StyledCheckProgressPercent>{segmentsLoadingPercentage}%</SC.StyledCheckProgressPercent>
                )}
            </SC.StyledCheckProgress>
        ) : (
            <SC.StyledCheckButtonWrapper role="button" isDisabled={isDisabled} onClick={handleTextCheckClick} textLength={textLength}>
                {!isDisabled && <SC.StyledCheckTitle>{checkLabel}</SC.StyledCheckTitle>}
                <SC.StyledCheckButton disabled={isDisabled || !textLength}>
                    <RunCheckIcon />
                 </SC.StyledCheckButton>
            </SC.StyledCheckButtonWrapper>
        )
    );
};
