import * as React from "react";

export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#333333" fillRule="nonzero">
                <path d="M8,0 L8.2488278,0.00381358488 C12.5459518,0.135695995 16,3.67141557 16,8 C16,12.4118264 12.4118264,16 8,16 C3.58817356,16 0,12.4118264 0,8 C0,3.67141557 3.45404817,0.135695995 7.7511722,0.00381358488 L8,0 Z M8,1.23076923 L7.76274776,1.23487135 C4.14004356,1.36032381 1.23076923,4.34731484 1.23076923,8 C1.23076923,11.7320914 4.26790864,14.7692308 8,14.7692308 C11.7320914,14.7692308 14.7692308,11.7320914 14.7692308,8 C14.7692308,4.34731484 11.8599564,1.36032381 8.23725224,1.23487135 L8,1.23076923 Z M8.58701787,6.33285512 C8.73452493,6.33285512 8.85380997,6.4525173 8.85380997,6.5996491 L8.85380997,11.5863259 L10.2667921,11.5863259 C10.4139229,11.5863259 10.5335842,11.7056118 10.5335842,11.8527436 L10.5335842,12.4661063 C10.5335842,12.6136144 10.4139229,12.7329003 10.2667921,12.7329003 L6.2667921,12.7329003 C6.11966134,12.7329003 6,12.6136144 6,12.4661063 L6,11.8527436 C6,11.7056118 6.11966134,11.5863259 6.2667921,11.5863259 L7.68015052,11.5863259 L7.68015052,7.47980583 L6.2667921,7.47980583 C6.11966134,7.47980583 6,7.36051995 6,7.21301185 L6,6.5996491 C6,6.4525173 6.11966134,6.33285512 6.2667921,6.33285512 L8.58701787,6.33285512 Z M8.00037629,3 C8.58927563,3 9.0667921,3.47751983 9.0667921,4.06642333 C9.0667921,4.65570312 8.58927563,5.13322295 8.00037629,5.13322295 C7.41110066,5.13322295 6.9335842,4.65570312 6.9335842,4.06642333 C6.9335842,3.47751983 7.41110066,3 8.00037629,3 Z"></path>
            </g>
        </g>
    </svg>
);
