import React, { useCallback } from 'react';

import { useDispatch } from "react-redux";

import { CheckSettingsForm, CheckSettingsFormProps } from "../../Components/CheckSettings/CheckSettingsForm";
import { MetaMenu } from '../../Components/MetaMenu/MetaMenu';
import { updateCheckSettingsAction } from "../../Store/UserState";
import { ErrorWrapper } from '../../Util/ErrorWrapper';


const Settings: React.FC = () => {
    const dispatch = useDispatch();

    const handleSubmit: CheckSettingsFormProps['onSubmit'] = useCallback((settings) => dispatch(updateCheckSettingsAction({settings})), [dispatch])

    return (
        <>
            <ErrorWrapper>
                <CheckSettingsForm onSubmit={handleSubmit}/>
            </ErrorWrapper>

            <MetaMenu />
        </>
    );
}

export default Settings;
