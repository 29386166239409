// tslint:disable:no-any
import { EditorState, Modifier } from "draft-js";
import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { Action } from "typescript-fsa";

// import {State as MainState} from 'FullVersion/Store'; TODO: move redux away from library
import {
    correctErrorAction,
    editorChangedAction,
    userInteractionAction,
    correctSynonymAction,
    CorrectErrorPayload,
    UserActionPayload
} from "./Actions";
import { decoratorStrategyType, getDetailsForEntity, getDraftSelection, EntityDetails } from "../../Util/Draft";

export const correctErrorMiddleWare: Middleware<{}, any> =
    (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
    <A extends Action<{}>>(action: A): A => {
        if (action.type === correctErrorAction.type) {
            const actionPayload = action.payload as CorrectErrorPayload;
            const { editorState, correctError } = getEditorStateWithErrorCorrection(
                api.getState().padState.editorState,
                actionPayload
            );
            action.payload = correctError;
            api.dispatch(
                editorChangedAction({
                    oldEditorState: api.getState().padState.editorState,
                    newEditorState: editorState,
                })
            );
            const acceptedText = actionPayload.adviceHistory.correctedText;
            const acceptedPos = actionPayload.adviceHistory.proposals.indexOf(acceptedText);
            const userPayload: UserActionPayload = {
                text: editorState.getCurrentContent().getPlainText(),
                userInteraction: {
                    type: "errorCorrected",
                    completeText: editorState.getCurrentContent().getPlainText(),
                    suggestedChangeOriginalText: actionPayload.adviceHistory.originalError,
                    suggestedChangeOffset: actionPayload.adviceHistory.offset,
                    suggestedChangeErrorCode: actionPayload.adviceHistory.errorCode,
                    suggestedChangeErrorType: actionPayload.adviceHistory.errorType,
                    suggestedChangeAcceptedText: acceptedText,
                    suggestedChangeAcceptedPosition: acceptedPos,
                },
            };
            api.dispatch(userInteractionAction(userPayload));
        }

        if (action.type === correctSynonymAction.type) {
            const actionPayload = action.payload as CorrectErrorPayload;
            const { editorState } = getEditorStateWithErrorCorrection(
                api.getState().padState.editorState,
                actionPayload,
                true
            );

            api.dispatch(
                editorChangedAction({
                    oldEditorState: api.getState().padState.editorState,
                    newEditorState: editorState,
                })
            );
        }

        // Call the next dispatch method in the middleware chain.
        return next(action);
    };

export const getEditorStateWithErrorCorrection = (
    editorState: EditorState,
    correctError: CorrectErrorPayload,
    isSynonym?: boolean
): { editorState: EditorState; correctError: CorrectErrorPayload} => {
    const advice = correctError.adviceHistory;
    const manipulateEditorCallback = (entity: EntityDetails) => {
        const currentContentState = editorState.getCurrentContent();
        const originalSelection = editorState.getSelection();
        const start = entity.start!;
        const startKey = entity.blockKeyStart;
        const endKey = entity.blockKeyEnd;
        const replaceSelection = getDraftSelection(editorState, start, entity.end!, startKey, endKey);

        //trim newlines from correctedText (edge case)
        const correctedText = advice.correctedText.replace(/^[\r\n]+/g, "");

        const contentStateWithCorrectSpelling = Modifier.replaceText(
            currentContentState,
            replaceSelection,
            correctedText
        );
        const contentStateWithEntity = contentStateWithCorrectSpelling.createEntity(
            decoratorStrategyType.spellErrorCorrected,
            "MUTABLE",
            {
                originalError: correctError.adviceHistory.originalError,
                customTypes: [decoratorStrategyType.spellErrorCorrected],
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newFocusOffset = contentStateWithCorrectSpelling.getSelectionAfter().getFocusOffset();
        const newFocusKey = contentStateWithCorrectSpelling.getSelectionAfter().getFocusKey();
        const replaceEntitySelection = getDraftSelection(editorState, start, newFocusOffset, startKey, newFocusKey);
        const contentState = Modifier.applyEntity(contentStateWithEntity, replaceEntitySelection, entityKey);
        correctError.adviceHistory.entityKey = entityKey;
        editorState = EditorState.push(editorState, contentState, "insert-characters");
        if (!isSynonym) {
            editorState = EditorState.set(editorState, { selection: originalSelection });
        }
    };
    const entities: EntityDetails[] = getDetailsForEntity(editorState, advice.entityKey);

    entities.forEach((entity: EntityDetails) => {
        manipulateEditorCallback(entity);
    });
    return { editorState, correctError };
};
