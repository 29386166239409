import {all, call, put, race, takeLatest, delay, Effect} from 'redux-saga/effects';
import {Action} from 'typescript-fsa';

import {initializeSettingsAction, settingsDataFailedAction, settingsDataLoadedAction} from './Actions';
import { apiFetch } from '../../Util/RequestApi';

export function* settingsSaga(): IterableIterator<Effect> {
    yield all([
        takeLatest<Action<string>>(initializeSettingsAction.type, getSettingsDataHandler),
    ]);
}

/**
 * handle response for user data
 */
function* getSettingsDataHandler(): IterableIterator<Effect> {
    try {
        const response = yield call(getSettingsData);
        if (response.status === 200) {
            const json = yield call([response, response.json]);
            yield put(settingsDataLoadedAction({
                featureFlags: json.featureFlags,
            }));
        }
    } catch (err) {
        yield put(settingsDataFailedAction(void (0)));
    }
}

/**
 * throw error when connection will take to long
 * @param timeout
 */
function* getSettingsData(timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(fetchSettingsData),
        timeout: delay(timeout),
    });

    if (response) {
        return response;
    } else {
        throw new Error('Connection timed out.');
    }
}

function fetchSettingsData(): Promise<Response> {
    return apiFetch('api/settings');
}
