import React, { useCallback } from "react";

import * as SC from "./ExpressPadHeader.styled";
import useOneClickStore, { ParaphrasingMode, PARAPHRASING_MODE } from "../../Hooks/useOneClickStore";
import { RoundedCornersButton } from "../Buttons";
import { Switch } from "../Switch/Switch";

const ExpressPadHeader: React.FC = () => {
    const [isHighlightModeActive, setIsHighlightModeActive] = useOneClickStore((store) => [store.isHighlightModeActive, store.setIsHighlightModeActive]);
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const [paraphrasingMode, setParaphrasingMode] = useOneClickStore((store) => [store.paraphrasingMode, store.setParaphrasingMode]);

    const handleHighlightModeChange = useCallback(() => {
        setIsHighlightModeActive(!isHighlightModeActive);
    }, [isHighlightModeActive, setIsHighlightModeActive])

    const getOnParaphrasingModeClick = (mode: ParaphrasingMode) => () => {
        setParaphrasingMode(mode);
    }

    return <SC.Wrapper>
        {isParaphrasingModeActive ? <SC.Content>
            <span>Tonalität:</span>
            <RoundedCornersButton
                active={paraphrasingMode === PARAPHRASING_MODE.Neutral}
                onClick={getOnParaphrasingModeClick(PARAPHRASING_MODE.Neutral)}
            >
                neutral
            </RoundedCornersButton>
            <RoundedCornersButton
                active={paraphrasingMode === PARAPHRASING_MODE.Lighter}
                onClick={getOnParaphrasingModeClick(PARAPHRASING_MODE.Lighter)}
            >
                einfacher
            </RoundedCornersButton>
            <RoundedCornersButton
                active={paraphrasingMode === PARAPHRASING_MODE.Formal}
                onClick={getOnParaphrasingModeClick(PARAPHRASING_MODE.Formal)}
            >
                formeller
            </RoundedCornersButton>

        </SC.Content> :  <SC.Content>
            <span>Änderungen anzeigen:</span>
            <Switch id="highlightMode" checked={isHighlightModeActive} onChange={handleHighlightModeChange} />
        </SC.Content>}
    </SC.Wrapper>
}

export default ExpressPadHeader;