import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as SC from './SynonymCard.styled';
import useCorrectionStore, { type NormalizedAdvice } from '../../../../Hooks/useCorrectionStore';
import useSynonymList from '../../../../Hooks/useSynonymList';
import { ChevronUpIcon, LoadingDotsIcon } from '../../../Icons';
import { Pill } from '../../../Pill';

type SynonymCardProps = {
    advice?: NormalizedAdvice;
};

const SynonymCard: React.FC<SynonymCardProps> = ({ advice }) => {
    const editorNode = useCorrectionStore((store) => store.editorNode);
    const synonymQuery = useCorrectionStore(store => store.synonymQuery);
    const synonymId = useCorrectionStore(store => store.synonymId);
    const cleanSynonymData = useCorrectionStore(store => store.handleCleanSynonymData);
    const handleSegmentUpdate = useCorrectionStore(store => store.handleSegmentUpdate);
    const activeIndex = useCorrectionStore(store => store.activeIndex);
    const handleSegmentGrammarcheck = useCorrectionStore(store => store.handleSegmentGrammarcheck);
    const cursorPosition = useCorrectionStore(store => store.cursorPosition);
    const { data: synonymList, isLoading } = useSynonymList();
    const [isExpanded, setIsExpanded] = useState(false);

    const synonymNode = useRef<{ node: HTMLSpanElement | null }>({ node: null });

    const isStaticMode = !synonymQuery && !!advice?.originalError;

    const currentQuery = isStaticMode ? advice.originalError : synonymQuery;
    const currentList = isStaticMode ? advice?.synonyms : synonymList;
    const currentId = isStaticMode ? advice?.id : synonymId;

    const isEmpty = !!synonymQuery && !synonymList?.length;

    useEffect(() => {
        setIsExpanded(!!(synonymQuery || (currentQuery && currentList?.length)));
    }, [activeIndex, currentList, currentQuery, synonymQuery]);

    useEffect(() => {
        synonymQuery && setTimeout(() => {
            window.addEventListener('click', cleanSynonymData);
            window.addEventListener('keypress', cleanSynonymData);
        }, 0);

        if (synonymQuery && synonymList?.length) {
            const selection = window.getSelection();

            if (selection?.type === 'Range') {
                const range = selection?.getRangeAt(0);

                if (range && range.toString() === synonymQuery) {
                    const spanNode = document.createElement('span');
                    spanNode.classList.add('synonym');
                    spanNode.setAttribute('data-llm-synonym', synonymQuery);
                    spanNode.setAttribute('data-llm-id', synonymId);
                    const subSpanNode = document.createElement('span');
                    subSpanNode.textContent = synonymQuery ?? '';
                    synonymId && (spanNode.id = synonymId);

                    spanNode.appendChild(subSpanNode);

                    synonymNode.current.node = spanNode;

                    range.deleteContents();
                    range.insertNode(spanNode);
                }
            }
        }

        return () => {
            window.removeEventListener('click', cleanSynonymData);
            window.removeEventListener('keypress', cleanSynonymData);
        };
    }, [cleanSynonymData, synonymId, synonymList, synonymQuery]);

    const handleSynonymSuggestion = useCallback((suggestion?: string) => {
        const synonymNode = editorNode?.querySelector(`#${currentId}`);

        if (!synonymNode) return;

        if (suggestion) {
            synonymNode.setAttribute('suggestion', suggestion);
        } else {
            synonymNode.removeAttribute('suggestion');
        }
    }, [editorNode, currentId]);

    const handleSectionExpand = useCallback(() => {
        setIsExpanded(prevState => !prevState);
    }, []);

    const handleSynonymAccept = (suggestion: string) => {
        const node = isStaticMode ? editorNode?.querySelector(`#${currentId}`) as HTMLSpanElement : synonymNode.current.node;

        if (node) {
            const segmentId = isStaticMode ? advice?.segmentId : cursorPosition.segmentId;

            if (isStaticMode) {
                node.removeAttribute('suggestion');
            }

            node.innerText = suggestion;

            if (segmentId) {
                setTimeout(() => {
                    handleSegmentUpdate({ id: segmentId });
                    void handleSegmentGrammarcheck(segmentId);
                }, 100);
            }
        }
    };

    return (
        <SC.Wrapper>
            <SC.SynonymCardTitle isExpanded={isExpanded} onClick={handleSectionExpand}>
                <p>Synonyme</p>
                <ChevronUpIcon />
            </SC.SynonymCardTitle>
            {isExpanded && (
                <SC.SynonymsBody>
                    {synonymQuery && isLoading ? <SC.LoaderWrapper>
                        <LoadingDotsIcon style={{ width: '45px' }} />
                    </SC.LoaderWrapper> : (
                        !currentList?.length ? <SC.NoSynonyms>
                            {isEmpty ? <h1><SC.Highlited>{currentQuery}</SC.Highlited></h1> : <h1>Für ausdrucksstarke Texte</h1>}
                            {isEmpty ? <p>Für dieses Wort sind keine Synonyme verfügbar.</p> : <p>Mit Doppelklick oder <SC.Highlited>Markierung</SC.Highlited> eines Wortes erhalten Sie passende Vorschläge.</p>}
                        </SC.NoSynonyms> : currentList.map((item, index) => (
                                <SC.SynonymItem key={index}>
                                    {item.prag_info && <SC.PragInfo
                                    >
                                        {item.prag_info}:
                                    </SC.PragInfo>}
                                    {item.synonyms.map((synonym, index) => (
                                        synonym && (
                                            <Pill
                                                key={index}
                                                fontSize={13}
                                                onClick={() => handleSynonymAccept(synonym)}
                                                onMouseEnter={() => handleSynonymSuggestion(synonym)}
                                                onMouseLeave={() => handleSynonymSuggestion()}
                                            >
                                                {synonym}
                                            </Pill>
                                        )
                                    ))}
                                </SC.SynonymItem>
                            ),
                        )
                    )}
                </SC.SynonymsBody>
            )}
        </SC.Wrapper>
    );

};

export default SynonymCard;
