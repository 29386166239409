import * as React from 'react';
import {useLayoutEffect, useState} from "react";

import {useSelector} from 'react-redux';

import {selectUserFeatures} from '../../Store/UserState';
import {hasFeature} from '../../Util/UserUtils';

export const YieldLoveScript: React.FC = () => {
    const userFeatures = useSelector(selectUserFeatures);
    const [isInitialized, setInitialized] = useState(false);
   
    useLayoutEffect(
        () => {
            if (!isInitialized && !hasFeature('noAdvertisements', userFeatures)) {
                // @see https://jira.cornelsen.de/browse/DUMEN-768
                const yieldloveScript = document.createElement('script');
                yieldloveScript.src = "//cdn-a.yieldlove.com/v2/yieldlove.js?duden.de";
                yieldloveScript.async = true;
                document.body.appendChild(yieldloveScript);
            }
            setInitialized(true)
        },
        [userFeatures]
    );
    
    // tslint:disable-next-line:no-null-keyword
    return null;
};
