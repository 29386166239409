import styled, { css } from 'styled-components';

import { InputAppearance } from './Input';
import { Colors, Fonts } from '../../Styles';

export const Label = styled.label`
    display: block;
    padding-bottom: 10px;
`;

export const Input = styled.input<{
    appearance?: InputAppearance;
    hideControls?: boolean;
    hasIcon?: boolean;
}>`
    display: block;
    box-sizing: border-box;
    background: ${Colors.mystic};
    height: 44px;
    line-height: 44px;
    border-radius: 2px;
    border: unset;
    border-bottom: 2px solid ${({ appearance }) =>
        appearance === InputAppearance.Error
            ? Colors.crimson
            : appearance === InputAppearance.Empty
                ? 'transparent'
                : Colors.iron};
    width: 100%;
    padding: ${({ hasIcon }) => (hasIcon ? '0 42px 0 10px' : '0 10px')};
    font-size: 15px;
    font-family: ${Fonts.publico};
    outline: 0;
    box-shadow: none;
    margin: 0;

    ${({ hideControls }) =>
        hideControls &&
        css`
            appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                appearance: none;
                margin: 0;
            }
        `}
    &:focus {
        border-color: ${Colors.supernova};
    }

    &:disabled {
        opacity: 0.6;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
`;

export const InputIcon = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    inset: 0 6px 0 auto;
    cursor: pointer;
`;

export const Sup = styled.span`
    display: inline-block;
    margin-left: 1px;
    top: -2px;
`;

export const Message = styled.p<{ appearance?: InputAppearance }>`
    font-size: 14px;
    color: ${({ appearance }) =>
        appearance === InputAppearance.Error
            ? Colors.crimson
            : Colors.pastelGreen};
    margin: 4px 0;
`;