import * as React from "react";

import classNames from "classnames";
import styled, { css } from "styled-components";

import {Colors} from "../../Styles";


interface Props {
    children: React.ReactNode;
    alwaysOpen?: boolean;
    open?: boolean;
}

export const StyledBaseAdvice = css`
    position: relative;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .15);
    background-color: ${Colors.white};
    `;

const StyledAdviceCard = styled.details`
    ${StyledBaseAdvice};
    margin-top: -7px;
    padding: 22px 20px 15px;
`;

export const AdviceCard = (props: Props) => (
    <StyledAdviceCard open={props.open || props.alwaysOpen} className={classNames("", {"always-open": props.alwaysOpen})}>
        {props.children}
    </StyledAdviceCard>
);
