import React from "react";

import { Modal } from "@material-ui/core";
import styled from "styled-components";

import useOneClickStore from "../../Hooks/useOneClickStore";
import { Colors, MediaBreakpoints } from "../../Styles";
import { SquareButton } from "../Buttons";

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 330px;
    max-width: 90%;
    transform: translate(0, -105%);
    overflow: auto;
    padding: 20px;
    border-radius: 10px;
    background: ${Colors.supernova};
    box-sizing: border-box;

    @media ${MediaBreakpoints.medium} {
        right: auto;
        left: auto;
        max-width: 100%;
        transform: translate(-105%, 0);
    }
`;

const ModalHeader = styled.div`
    h2 {
        margin: 0 0 10px;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
    }

    button {
        position: absolute;
        top: 17px;
        right: 17px;
        background: ${Colors.supernova};
        border: none;
        font-size: 18px;
        cursor: pointer;
    }
`;

const TextWrapper = styled.div`
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 25px;
`;

const ButtonsWrapper = styled.div`
    display: flex;

    button {
        margin-right: 20px;
        letter-spacing: 2px;
        border: 2px solid ${Colors.mineShaft};
    }
`;

type ApplyTextChangesModalProps = {
    closeApplyTextChangesModal: () => void;
    applyTextChangesModal: boolean;
    keepOneClickContent?: () => void;
};

export const ApplyTextChangesModal: React.FC<ApplyTextChangesModalProps> = ({
    closeApplyTextChangesModal,
    applyTextChangesModal,
    keepOneClickContent,
}) => {
    const setIsOneClickModeActive = useOneClickStore((store) => store.setIsOneClickModeActive);

    const handleKeepOneClickText = (keepText: boolean) => {
        if (keepText) {
            keepOneClickContent?.();
        }
        setIsOneClickModeActive(false);
        closeApplyTextChangesModal();
    };

    return (
        <Modal
            open={applyTextChangesModal}
            onClose={closeApplyTextChangesModal}
            aria-labelledby="classic-correction-title"
            hideBackdrop
            disablePortal
        >
            <ModalWrapper>
                <ModalHeader>
                    <h2 id="classic-correction-title">Änderungen übernehmen?</h2>
                    <button onClick={closeApplyTextChangesModal}>&#10060;</button>
                </ModalHeader>
                <TextWrapper>Möchten Sie diesen Text übernehmen in die „klassische Textprüfung“?</TextWrapper>

                <ButtonsWrapper>
                    <SquareButton
                        onClick={() => handleKeepOneClickText(false)}
                        defaultBackground={Colors.white}
                        hoverBackground={Colors.white}
                        defaultColor={Colors.mineShaft}
                    >
                        NEIN
                    </SquareButton>
                    <SquareButton
                        onClick={() => handleKeepOneClickText(true)}
                        defaultBackground={Colors.mineShaft}
                        hoverBackground={Colors.mineShaft}
                        defaultColor={Colors.white}
                    >
                        JA
                    </SquareButton>
                </ButtonsWrapper>
            </ModalWrapper>
        </Modal>
    );
};
