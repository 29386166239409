// tslint:disable:no-any
import {EditorState, Modifier} from 'draft-js';
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';

// import {State as MainState} from 'FullVersion/Store'; TODO: move redux away from library
import {
    editorChangedAction,
    openInlineAdviceItemAction,
    textCheckSuccessfulAction,
    updateDecoratorAction,
    CheckSuccessfulPayload,
} from './Actions';
import {AdviceData, RedundancyProps} from '../../Components/Advices/Util/Props';
import {
    decoratorStrategyType,
    deleteAllEntitiesWithMetadata,
    getBlockDraftSelection,
    getDetailsForEntity,
    getEntitiesInSelection,
    isEntityInSelection,
    EntityMetadata,
} from '../../Util/Draft';
import { ADVICE_TYPE, setSelectedAdviceItemAction, SetSelectedAdviceItemPayload } from "../AdviceState";
import {setHideStyleAdviceAction, userDataLoadedAction, UserDataPayload} from '../UserState';

export const padStyleMiddleWare: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        // tslint:disable-next-line:no-any
        <A extends Action<any>>(action: A): A => {
            // tslint:disable-next-line:prefer-switch
            if (action.type === textCheckSuccessfulAction.type) {
                const actionPayload = action.payload as CheckSuccessfulPayload;
                const {editorState, newSpellAdvices, newStyleAdvices} = getEditorStateWithCSSHighlighting(
                    api.getState().padState.editorState,
                    actionPayload.data.spellAdvices,
                    actionPayload.data.styleAdvices,
                    api.getState().userState.hideStyleAdvice,
                );
                api.dispatch(editorChangedAction({
                        oldEditorState: api.getState().padState.editorState,
                        newEditorState: editorState,
                    }),
                );
                action.payload.data.spellAdvices = newSpellAdvices;
                action.payload.data.styleAdvices = newStyleAdvices;

                // set selected advice, when none was selected before
                if (api.getState().checkState.selectedAdviceItem === undefined) {
                    const hideStyleAdvices = api.getState().userState.hideStyleAdvice;
                    const payload = getFirstAdvice(newSpellAdvices, hideStyleAdvices ? [] : newStyleAdvices);
                    api.dispatch(setSelectedAdviceItemAction(payload));

                    // Open first intext advice if there is a payload.
                    if (payload) {
                        openFirstIntextAdvice(api, payload, newSpellAdvices, newStyleAdvices);
                    }
                }
            } else if (action.type === setHideStyleAdviceAction.type) {
                    const {editorState} = getEditorStateWithCSSHighlighting(
                        api.getState().padState.editorState,
                        api.getState().checkState.spellAdvices,
                        api.getState().checkState.styleAdvices,
                        action.payload,
                    );
                    api.dispatch(editorChangedAction({
                        oldEditorState: api.getState().padState.editorState,
                        newEditorState: editorState}),
                    );

                    // select first spell error, when style errors are hidden
                    const selectedAdviceItem = api.getState().checkState.selectedAdviceItem;
                    if (action.payload && selectedAdviceItem && selectedAdviceItem.adviceType === 'STYLE-ERROR') {
                        const payload = getFirstAdvice(api.getState().checkState.spellAdvices, []);
                        api.dispatch(setSelectedAdviceItemAction(payload));
                    }
            } else if (action.type === userDataLoadedAction.type) {
                const actionPayload = action.payload as UserDataPayload;
                api.dispatch(updateDecoratorAction(actionPayload.characterLimit));
            }
            // Call the next dispatch method in the middleware chain.
            return next(action);

        };

/**
 * Open the first spell or style advice depending on the given payload.
 *
 * @param api
 * @param payload
 * @param spellAdvices
 * @param styleAdvices
 */
const openFirstIntextAdvice = (
        api: MiddlewareAPI<Dispatch, any>,
        payload: SetSelectedAdviceItemPayload,
        spellAdvices: AdviceData[],
        styleAdvices: AdviceData[],
    ) => {
    if (payload.adviceType === ADVICE_TYPE.SpellError) {
        const spellKey = spellAdvices[0].entityKey;
        if (spellKey) {
            api.dispatch(openInlineAdviceItemAction({entityKey: spellKey}));
        }
    } else {
        const styleKey = styleAdvices[0].entityKey;
        if (styleKey) {
            api.dispatch(openInlineAdviceItemAction({entityKey: styleKey}));
        }
    }
};

function getFirstAdvice(spAdvices: AdviceData[], stAdvices: AdviceData[]): SetSelectedAdviceItemPayload | undefined {
    const firstSpellAdvice = spAdvices.length > 0 ? spAdvices[0] : undefined;
    const firstStyleAdvice = stAdvices.length > 0 ? stAdvices[0] : undefined;

    if (!firstSpellAdvice && !firstStyleAdvice) {
        return;
    }

    if (!firstStyleAdvice || (firstSpellAdvice && firstStyleAdvice.offset > firstSpellAdvice!.offset)) {
        return {adviceType: 'SPELL-ERROR', index: 0};
    }

    return {adviceType: 'STYLE-ERROR', index: 0};
}

export function getEditorStateWithCSSHighlighting(
    editorState: EditorState,
    spellCheckResults: AdviceData[],
    styleAdvices: AdviceData[],
    hideStyleAdvices: boolean,
):
    { editorState: EditorState; newSpellAdvices: AdviceData[]; newStyleAdvices: AdviceData[] } {

    const originalSelection = editorState.getSelection();
    const maxAnchor = editorState.getCurrentContent().getPlainText().length;
    editorState = deleteAllEntitiesWithMetadata(editorState, decoratorStrategyType.spellError);
    editorState = deleteAllEntitiesWithMetadata(editorState, decoratorStrategyType.styleError);
    let newSpellAdvices: AdviceData[] = [];

    editorState = spellCheckResults.reduce(
        (editorStateInReduce: EditorState, spellCheckArray: AdviceData) => {
            const {editorStateInProgress, newResultArray} = addEntitiesInEditor(
                editorStateInReduce,
                spellCheckArray,
                newSpellAdvices,
                maxAnchor,
                decoratorStrategyType.spellError,
            );
            newSpellAdvices = newResultArray;
            return editorStateInProgress;
        },
        editorState,
    );

    let newStyleAdvices: AdviceData[] = [];
    editorState = styleAdvices.reduce(
        (editorStateInReduce: EditorState, styleAdvice: AdviceData) => {
            const {editorStateInProgress, newResultArray} = addEntitiesInEditor(
                editorStateInReduce,
                styleAdvice,
                newStyleAdvices,
                maxAnchor,
                (hideStyleAdvices ? '' : decoratorStrategyType.styleError),
            );
            newStyleAdvices = newResultArray;
            return editorStateInProgress;
        },
        editorState,
    );
    editorState = EditorState.set(editorState, {selection: originalSelection});

    return {editorState, newSpellAdvices, newStyleAdvices};
}

const addEntitiesInEditor = (
    editorStateInProgress: EditorState,
    result: AdviceData,
    newResultArray: AdviceData[],
    maxAnchor: number,
    entityType: string,
): { editorStateInProgress: EditorState; newResultArray: AdviceData[] } => {
    const focusEnd = result.offset + result.length;
    if (focusEnd > maxAnchor) {
        return {editorStateInProgress, newResultArray};
    }

    const newSelection = getBlockDraftSelection(editorStateInProgress, result.offset, focusEnd);
    if (isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.spellError)
        || isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.spellErrorIgnored)
        || isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.styleError)
    ) {
        // in case of prefilled occurrence entity we want this in our result array
        if (result.type === 'orth' && result.occurrences.length > 1) {
            const entitiesInSelection = getEntitiesInSelection(newSelection, editorStateInProgress);
            const entityDetails = getDetailsForEntity(editorStateInProgress, entitiesInSelection[0]);
            if (entityDetails[entitiesInSelection[0]] !== undefined) {
                // @ts-ignore
                const entityMetaData = entityDetails[entitiesInSelection[0]].entity.getData();

                if (entityDetails[entitiesInSelection[0]] !== undefined && entityMetaData.customTypes[0] === decoratorStrategyType.spellError &&
                    entityMetaData.originalError === result.originalError) {
                    result.entityKey = entitiesInSelection[0];
                    result.firstOccurrenceEntityKey = entityMetaData.firstOccurrenceEntityKey;
                    newResultArray.push(result);
                }
            }
        }
        return {editorStateInProgress, newResultArray};
    }

    editorStateInProgress = EditorState.set(
        editorStateInProgress,
        {selection: newSelection},
    ); // assign selection to editor
    const contentState = editorStateInProgress.getCurrentContent();
    const metadata: EntityMetadata = {originalError: result.originalError, customTypes: [entityType]};
    let contentStateWithEntity = contentState.createEntity(
        entityType,
        'MUTABLE',
        metadata,
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let firstOccurrenceEntityKey = entityKey;

    if (result.type === 'orth' && result.occurrences.length > 1 && result.offset !== result.occurrences[0].offset) {
        const offset = result.occurrences[0].offset;
        const occurrenceSelection = getBlockDraftSelection(editorStateInProgress, offset, offset + result.length);
        const occurrenceEntity = getEntitiesInSelection(occurrenceSelection, editorStateInProgress);
        firstOccurrenceEntityKey = occurrenceEntity[0] || firstOccurrenceEntityKey;
    }

    contentStateWithEntity = contentStateWithEntity.mergeEntityData(
        entityKey,
        {firstOccurrenceEntityKey},
    );
    result.entityKey = entityKey;
    result.firstOccurrenceEntityKey = firstOccurrenceEntityKey;

    newResultArray.push(result);
    const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        newSelection,
        entityKey,
    );
    editorStateInProgress = EditorState.push(editorStateInProgress, contentStateWithLink, 'insert-characters');

    const {editorState, adviceItems} = addOccurrenceEntitiesInEditor(
        newResultArray, result, editorStateInProgress, maxAnchor, entityType,
    );

    return {editorStateInProgress: editorState, newResultArray: adviceItems};
};

function addOccurrenceEntitiesInEditor(
    adviceItems: AdviceData[],
    result: AdviceData,
    editorStateInProgress: EditorState,
    maxAnchor: number,
    entityType: string,
): {editorState: EditorState; adviceItems: AdviceData[]} {
    if (entityType !== decoratorStrategyType.styleError) {
        return { editorState: editorStateInProgress, adviceItems };
    }

    const newEditorState = result.occurrences.reduce(
        (occEditorState: EditorState, occ: RedundancyProps) => {
            const focusEnd = occ.offset + occ.text.length;
            if (focusEnd > maxAnchor) {
                return editorStateInProgress;
            }

            if (occ.offset === result.offset) {
                const resultIndex = adviceItems.indexOf(result);
                if (resultIndex >= 0) {
                    adviceItems[resultIndex].synonyms = occ.synonyms;
                }
                return editorStateInProgress;
            }

            const newSelection = getBlockDraftSelection(editorStateInProgress, occ.offset, focusEnd);
            if (isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.spellError)
                || isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.spellErrorIgnored)
                || isEntityInSelection(newSelection, editorStateInProgress, decoratorStrategyType.styleError)
            ) {
                return editorStateInProgress;
            }

            editorStateInProgress = EditorState.set(
                editorStateInProgress,
                {selection: newSelection},
            ); // assign selection to editor
            const contentState = editorStateInProgress.getCurrentContent();
            const metadata: EntityMetadata = {
                originalError: occ.text,
                customTypes: [entityType],
                firstOccurrenceEntityKey: result.entityKey,
            };
            const contentStateWithEntity = contentState.createEntity(
                entityType,
                'MUTABLE',
                metadata,
            );

            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const contentStateWithLink = Modifier.applyEntity(
                contentStateWithEntity,
                newSelection,
                entityKey,
            );

            adviceItems.push({
                ...result, entityKey, offset: occ.offset, synonyms: occ.synonyms, originalError: occ.text,
            });
            editorStateInProgress = EditorState.push(occEditorState, contentStateWithLink, 'insert-characters');
            return editorStateInProgress;
        },
        editorStateInProgress,
    );
    return {editorState: newEditorState, adviceItems};
}
