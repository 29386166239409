import React, { useEffect, useState } from 'react';

import classNames from "classnames";
import { useSelector } from "react-redux";

import * as SC from "./Advice.styled";
import { AdviceType } from "../../../Store/AdviceState";
import { selectUserStateFromMainState } from "../../../Store/UserState";
import { Colors, Fonts } from "../../../Styles";
import { GTMDataLayerPush } from "../../../Util/GoogleTagManager";
import { hasFeature } from "../../../Util/UserUtils";
import { AdviceData } from "../../Advices/Util/Props";
import { RoundButton } from "../../Buttons";
import { AcceptAllIcon, AcceptIcon, DictionaryIcon } from "../../Icons";
import { Pill } from "../../Pill";
import { Tooltip } from "../../Tooltip/Tooltip";
import { AdviceCard } from "../AdviceCard";
import { AdviceCardTitle } from "../AdviceCardTitle";
import { AdviceInformation } from "../AdviceInformation";
import { useAdvice } from "../useAdvice";

import "../../../Styles/component/authoring/advice/advice.sass";

type AdviceProps = {
    data: AdviceData;
    adviceType: AdviceType;
    adviceHeader: React.ReactNode;
}

export type CardType = "examples" | "additionalInfo";

export const truncateText = (text: string): string => {
    const maxStringLength: number = 90;
    if (text.length <= maxStringLength) {
        return text;
    }
    const subString = text.substr(0, maxStringLength - 1);
    return `${subString.substr(0, subString.lastIndexOf(" "))} …`;
};

export const Advice: React.FC<AdviceProps> = (props) => {
    const [openedCard, setOpenedCard] = useState<undefined | CardType>(undefined);
    const {
        suggestion,
        canAddToDictionary,
        isMultiple,
        isAcceptable,
        accept,
        mouseEnter,
        mouseLeave,
        addToDictionary,
        reject
    } = useAdvice(props.data, props.adviceType);

    // @see https://jira.cornelsen.de/browse/DUMEN-492
    const user = useSelector(selectUserStateFromMainState);
    const debugErrorCodes = hasFeature("debugErrorCodes", user.features);

    useEffect(() => {
        const keyPressHandler: (this:Window, ev: KeyboardEvent) => any = (event) => {
            if (!window._is_advise_highlighted && event.ctrlKey && event.keyCode === 73) {
                event.preventDefault();
                onClickReject();
            }
        };

        window.addEventListener('keydown', keyPressHandler);

        return () => {
            window.removeEventListener('keydown', keyPressHandler);
        };
    }, [props.data.entityKey]);

    const toggleCard = (event: React.MouseEvent<HTMLDetailsElement>, value: CardType) => {
        event.preventDefault();
        if (value) {
            GTMDataLayerPush({ event: "open-card", "advice-type": props.adviceType === "SPELL-ERROR" ? "spelling" : "style", location: "advice-panel", card: value });
        }
        setOpenedCard(value === openedCard ? undefined : value);
    };

    const onClickAccept = (alternative: string) => {
        GTMDataLayerPush({ event: "text-advice", option: "accepted", location: "advice-panel", "advice-type": props.adviceType === "SPELL-ERROR" ? "spelling" : "style" });
        accept(alternative);
    };

    const onClickAlternatives = (alternative: string) => {
        GTMDataLayerPush({ event: "select-alternative", "advice-type": props.adviceType === "SPELL-ERROR" ? "spelling" : "style", location: "advice-panel" });
        accept(alternative);
    };

    const onClickReject = () => {
        GTMDataLayerPush({ event: "text-advice", option: "rejected", location: "advice-panel", "advice-type": props.adviceType === "SPELL-ERROR" ? "spelling" : "style" });
        reject();
    };

    const onClickDictionary = () => {
        GTMDataLayerPush({ event: "save-in-dictionary", location: "advice-panel", "advice-type": props.adviceType === "SPELL-ERROR" ? "spelling" : "style" });
        addToDictionary();
    };

    return (
        <SC.Wrapper>
            <AdviceCard alwaysOpen={true}>
                <AdviceCardTitle onClick={(e) => e.preventDefault()}>
                    {props.adviceHeader}
                </AdviceCardTitle>

                <>
                    <SC.OriginalError>
                        <SC.TextTruncate>
                            <span className={classNames("original-error", {
                                style: props.data.type === "style"
                            })}>{truncateText(props.data.originalError)}</span>
                        </SC.TextTruncate>

                    </SC.OriginalError>
                </>
                {isAcceptable && (
                    <p style={{ fontFamily: Fonts.theinhardt, fontSize: 18, margin: "5px 0", paddingBottom: 10 }}>
                        {props.data.shortMessage}
                    </p>
                )}
                {(debugErrorCodes) && (
                    <p style={{ color: Colors.silver }}> (Fehlercode: <a href={`/admin/error_message/list?name=${props.data.errorCode}`} target="_blank" rel="noreferrer">{props.data.errorCode}</a>)</p>
                )}

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 13, paddingTop: 13, borderTop: `1px solid ${Colors.iron}` }}>
                    {suggestion}
                    <div className="button-navigation">
                        {isAcceptable && (
                            <Tooltip direction="west" text={isMultiple ? "Alle Vorschläge übernehmen" : "Vorschlag übernehmen"}>
                                <RoundButton
                                    onClick={() => onClickAccept(props.data.proposals[0] || " ")}
                                    onMouseEnter={() => mouseEnter(props.data.proposals[0] || " ")}
                                    onMouseLeave={mouseLeave}
                                    defaultBackground={Colors.supernova}
                                    hoverBackground={Colors.pastelGreen}
                                    iconHoverColor={Colors.white}
                                >
                                    {isMultiple ? <AcceptAllIcon /> : <AcceptIcon />}
                                </RoundButton>
                            </Tooltip>
                        )}
                    </div>
                </div>

                {props.data.proposals.length > 1 && (
                    <SC.AdditionalReplacements>
                        <h3>Alternativen</h3>
                        {props.data.proposals.slice(1).slice(0, 10)
                            .map((alternative, index) => (
                                <Pill
                                    key={index}
                                    onClick={() => onClickAlternatives(alternative)}
                                    onMouseEnter={() => mouseEnter(alternative)}
                                    onMouseLeave={mouseLeave}
                                >{alternative}</Pill>
                            ))
                        }
                    </SC.AdditionalReplacements>
                )}

                <SC.StyledAdvicePanelIgnoreButton onClick={onClickReject}>
                    Ignorieren&nbsp;<span>(Strg/Ctrl + i)</span>
                </SC.StyledAdvicePanelIgnoreButton>

                {canAddToDictionary && (
                    <SC.StyledAdvicePanelButton onClick={onClickDictionary}>
                        <DictionaryIcon /> In meinem Wörterbuch speichern
                    </SC.StyledAdvicePanelButton>
                )}
            </AdviceCard>

            {props.data.errorMessage && (
                <AdviceCard open={openedCard === "examples"}>
                    <AdviceCardTitle isExpandable onClick={(e) => toggleCard(e, "examples")}>Erklärung und Beispiele</AdviceCardTitle>
                    <AdviceInformation dangerouslySetInnerHTML={{ __html: props.data.errorMessage }} />
                </AdviceCard>
            )}

            {(props.data.additionalInformation) && (
                <AdviceCard open={openedCard === "additionalInfo"}>
                    <AdviceCardTitle isExpandable onClick={(e) => toggleCard(e, "additionalInfo")}>Mehr Informationen</AdviceCardTitle>
                    <AdviceInformation dangerouslySetInnerHTML={{ __html: props.data.additionalInformation }} />
                </AdviceCard>
            )}
        </SC.Wrapper>
    );
};

export default Advice;