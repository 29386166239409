import React from 'react';

import * as SC from './ExpressOnboardingPopover.styled';

type ExpressOnboardingPopoverProps = {
    handleClose: React.MouseEventHandler<HTMLButtonElement>;
    isLiteMode?: boolean;
}

export const ExpressOnboardingPopover: React.FC<ExpressOnboardingPopoverProps> = ({ handleClose, isLiteMode }) => {
    return (
        <SC.Wrapper isLiteMode={isLiteMode}>
            <SC.CloseButton onClick={handleClose}>❌</SC.CloseButton>
            <SC.Image src="/assets/icons/express-onboarding.png" alt="Exress_onboarding." />
            <SC.Content>
                Optimieren Sie Ihre Texte mit nur einem Klick durch unsere KI-Prüfung!
            </SC.Content>
        </SC.Wrapper>
    );
}

export default ExpressOnboardingPopover;