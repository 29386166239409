import * as React from 'react';

import { setInterval } from 'timers';

import classNames from 'classnames';
import {
    getDefaultKeyBinding,
    ContentState,
    DraftDragType,
    DraftEditorCommand,
    DraftHandleValue,
    Editor,
    EditorState,
    KeyBindingUtil,
    Modifier,
    SelectionState,
} from 'draft-js';
import { connect } from 'react-redux';

import { resetAdviceAction, selectSpellAdvices, selectSynonymList } from '../../Store/AdviceState';
import {
    editorChangedAction,
    keyDownAction,
    openInlineAdviceItemAction,
    rerenderDecorationsAction,
    selectEditorState,
    selectInlineAdviceTimer,
    SetInlineAdviceTimerPayload,
    textPastedAction,
    wordHighlightedAction
} from "../../Store/PadState";
import '../../Styles/component/authoring/pad/pad.sass';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { advancedDecoratorSpells, getSelection, Selection } from '../../Util/Draft';
import { gtmEventTypes, sendGTMEvent } from '../../Util/GoogleTagManager';
import { handleEmojis } from '../../Util/HandleEmojis';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { hasStorage, loadFromStorage } from '../../Util/Storage';
import { hasFeature } from '../../Util/UserUtils';
import { AdviceData, SynonymListProps } from '../Advices/Util/Props';


interface ComponentState {
    highlightedWord: string;
}

interface DispatchProps {
    results: AdviceData[];
    editorState: EditorState;
    synonyms: SynonymListProps[];
    characterLimit: number;
    autoLogInterval: number;
    shouldCheckSynonyms: boolean;
    inlineAdviceTimer: SetInlineAdviceTimerPayload;
}

interface DispatchActions {
    onKeyDown: typeof keyDownAction;
    onEditorChange: typeof editorChangedAction;
    onTextPaste: typeof textPastedAction;
    onResetAdvice?: typeof resetAdviceAction;
    onWordHighlighted: typeof wordHighlightedAction;
    onRerenderDecorations: typeof rerenderDecorationsAction;
    openInlineAdviceItem: typeof openInlineAdviceItemAction;
}

export type Props = DispatchProps & DispatchActions;

/**
 * This component provides the Pad Editor
 */
export class PadComponent extends React.Component<Props, ComponentState> {

    private lastKey?: string;
    private domEditorRef?: Editor;
    private didBlur?: boolean;
    private trackNextClick: boolean = true;

    public constructor(props: Props) {
        super(props);
        this.state = { highlightedWord: '' };
    }

    public componentWillMount(): void {
        let storedText = '';

        if (hasStorage()) {
            storedText = loadFromStorage()!.getPlainText();
        }

        if (storedText === '') {
            return;
        }

        const oldEditorState = this.props.editorState;

        if (oldEditorState.getCurrentContent().getPlainText() === '') {
            this.props.onEditorChange({
                oldEditorState,
                newEditorState: EditorState.createWithContent(
                    ContentState.createFromText(storedText),
                    advancedDecoratorSpells(this.props.characterLimit),
                ),
            });
        }
        this.props.onTextPaste({ text: storedText });
    }

    public componentDidUpdate(prevProps: Props): void {
        const text = this.props.editorState.getCurrentContent().getPlainText();
        const prevText = prevProps.editorState.getCurrentContent().getPlainText();

        if (text !== prevText) {
            if (text.length === 0) {
                DocumentIdentifier.reset();

                if (this.domEditorRef) {
                    this.domEditorRef.focus();
                }
            }
        }
    }

    /**
     * set focus and auto logging on mount
     */

    public componentDidMount(): void {
        if (DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.lite && this.domEditorRef) {
            this.domEditorRef.focus();
        }

        // auto log the whole text in a specified interval
        if (this.props.autoLogInterval > 0) {
            setInterval(
                () => {
                    const text = this.props.editorState.getCurrentContent().getPlainText();

                    if (text.length) {
                        autoLogText(text);
                    }
                },
                this.props.autoLogInterval,
            );
        }

        this.checkForForcedCrash();

        const source = document.getElementById('pad-panel');
        if (source) {
            source.addEventListener('copy', () => sendGTMEvent(gtmEventTypes.textCopied));
        }
    }

    public componentWillUnmount(): void {
        const source = document.getElementById('pad-panel');
        if (source) {
            source.removeEventListener('copy', () => sendGTMEvent(gtmEventTypes.textCopied));
        }
    }

    /**
     * Renders the Draft Editor
     * @returns {JSX.Element}
     */
    public render(): JSX.Element {
        return (
            <>
                <div
                    id="pad-panel"
                    className={classNames('pad pad--length_overwhelmingly-long', {
                        'pad--empty': this.props.editorState.getCurrentContent().getPlainText().length === 0,
                        'pad--selection-is-synonym': (this.props.synonyms && this.props.synonyms.length > 0)
                            && this.props.synonyms[0].originalWord === this.state.highlightedWord,
                    })}
                >
                    <div
                        className="pad__writable"
                        onClick={this.onClick}
                        onMouseDown={this.onMouseDown}
                        onScroll={this.closeInlineAdvice}
                        data-placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
                        style={(
                            this.props.editorState.getCurrentContent().getPlainText().length > this.props.characterLimit
                                && this.props.characterLimit > 0
                                ? { caretColor: '#ff5f56' }
                                : {}
                        )}
                    >
                        <Editor
                            editorState={this.props.editorState}
                            keyBindingFn={this.customKeyBindingFn}
                            onChange={this.handleChange}
                            onBlur={this.onBlur}
                            handlePastedText={this.handlePastedText}
                            handleDrop={this.handleDrop}
                            ref={this.setEditorRef}
                        />
                    </div>
                </div>
            </>
        );
    }

    private setEditorRef = (draftEditor: Editor) => this.domEditorRef = draftEditor;

    private customKeyBindingFn = (e: React.KeyboardEvent<{}>): DraftEditorCommand | null | string => {
        this.setState({ highlightedWord: '' });

        this.lastKey = e.key;
        const { hasCommandModifier } = KeyBindingUtil;
        /* `A` and `command / ctrl` key */
        if (e.keyCode === 65 && hasCommandModifier(e)) {
            this.lastKey = void 0;
        }
        return getDefaultKeyBinding(e);
    }
    /**
     * method to intercept and handle pasted text.
     *
     * @param {string} text
     * @param {string | undefined} html
     * @param {EditorState} editorState
     * @returns {DraftHandleValue}
     */
    private handlePastedText = (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
        if (!text) {
            return 'handled';
        }

        text = handleEmojis(text);
        text = text.trim();

        // remove zero-width spaces
        text = text.replace(/[\u200B\u200C]/g, '');

        const blockMap = ContentState.createFromText(text).getBlockMap();
        const originalSelection = editorState.getSelection();
        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            blockMap,
        );
        editorState = EditorState.push(editorState, newState, 'insert-fragment');
        editorState = EditorState.set(editorState, { selection: originalSelection });
        const plainText = editorState.getCurrentContent().getPlainText();
        this.props.onEditorChange({ oldEditorState: this.props.editorState, newEditorState: editorState });
        this.props.onRerenderDecorations(editorState);
        this.props.onTextPaste({ text: plainText });
        return 'handled';
    }

    private handleDrop = (
        selection: SelectionState, dataTransfer: Object, isInternal: DraftDragType,
    ): DraftHandleValue => 'handled'

    private onBlur = (e: React.SyntheticEvent<{}>): void => {
        this.trackNextClick = true;
        this.didBlur = true;
    }

    private onClick = (e: React.SyntheticEvent<{}>): void => {
        if (this.trackNextClick) {
            sendGTMEvent(gtmEventTypes.padFocus);
            this.trackNextClick = false;
        }
        if (document.body.classList.contains('user__can-touch')) {
            const deskElements = document.getElementsByClassName('desk');
            deskElements[0].classList.add('desk--writing-mode');
            const advicePanel: HTMLElement = document.getElementById('advice-panel')!;
            advicePanel.addEventListener('click', (): void => {
                deskElements[0].classList.remove('desk--writing-mode');
            });
        }
        this.closeInlineAdvice();
    }

    private onMouseDown = (e: React.SyntheticEvent<{}>): void => {
        this.setState({ highlightedWord: '' });
    }


    private closeInlineAdvice = (): void => {
        if (this.props.inlineAdviceTimer !== undefined) {
            clearTimeout(this.props.inlineAdviceTimer as number);
        }
        this.props.openInlineAdviceItem({ entityKey: '-1' });
    }

    private handleChange = (editorState: EditorState): void => {
        if (this.didBlur) {
            this.didBlur = false;
            return;
        }
        const text = editorState.getCurrentContent().getPlainText();

        if (this.lastKey && this.props.editorState.getCurrentContent().getPlainText() !== text && text.length > 0) {
            const key = this.lastKey;

            this.props.onKeyDown({ text, key });
            this.lastKey = void 0;
        }

        if (text.length <= 0) {
            this.props.onResetAdvice?.();
        }

        const selection: Selection = getSelection(editorState);
        const selectedText = selection.text.trim();

        let newEditorState = editorState;

        // if selected text contains empty spaces, we have to adjust the selection
        if (selection.text !== selectedText) {
            const currentSelection = editorState.getSelection();
            const isReverseSelection = currentSelection.getIsBackward();

            const deltaIndex = selection.text.indexOf(selectedText);

            const startOffset = selection.startOffset + deltaIndex;
            const focusOffset = startOffset + selectedText.length;

            const newSelection = currentSelection.merge({
                anchorOffset: isReverseSelection ? focusOffset : startOffset,
                focusOffset: isReverseSelection ? startOffset : focusOffset,
            });

            newEditorState = EditorState.forceSelection(this.props.editorState, newSelection);
        }

        this.props.onEditorChange({ oldEditorState: this.props.editorState, newEditorState });

        if (selectedText !== '') {
            const blocks = newEditorState.getCurrentContent().getBlocksAsArray();
            const blockKey = newEditorState.getSelection().getStartKey();
            let blockCounter = 0;

            for (const block of blocks) {
                if (blockKey === block.getKey()) {
                    break;
                }

                blockCounter++;
            }

            if (
                selection.startOffset + selectedText.length + blockCounter <= this.props.characterLimit
                || this.props.characterLimit <= 0
            ) {
                if (selectedText !== '' && !!selectedText.match(/^[a-zA-ZäöüßÄÖÜẞ-]+$/)) {
                    if (this.props.shouldCheckSynonyms) {
                        this.props.onWordHighlighted(selectedText);
                    }
                    this.setState({ highlightedWord: selectedText });
                }
            }
        }
    }

    private checkForForcedCrash = () => {
        const search = window.location.search;

        if (search) {
            const params = new URLSearchParams(search);
            const forceCrash = params.get('force_crash');

            if (forceCrash === 'true') {
                throw new Error('Crash forced!');
            }
        }
    }
}

const URL = process.env.REACT_APP_API_URI;

function autoLogText(text: string): Promise<Response> {
    return fetch(`${URL}/api/log`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        credentials: 'same-origin',
        body: JSON.stringify({
            text,
        }),
    });
}

// tslint:disable-next-line:no-any
const mapStateToProps = (store: any): DispatchProps => ({
    results: selectSpellAdvices(store),
    editorState: selectEditorState(store),
    synonyms: selectSynonymList(store),
    autoLogInterval: store.userState.autoLogInterval,
    characterLimit: store.userState.characterLimit,
    shouldCheckSynonyms: hasFeature('synonyms', store.userState.features),
    inlineAdviceTimer: selectInlineAdviceTimer(store),
});

const dispatchMap: DispatchActions = {
    onKeyDown: keyDownAction,
    onEditorChange: editorChangedAction,
    onTextPaste: textPastedAction,
    onResetAdvice: resetAdviceAction,
    onWordHighlighted: wordHighlightedAction,
    onRerenderDecorations: rerenderDecorationsAction,
    openInlineAdviceItem: openInlineAdviceItemAction,
};

export const Pad = connect(mapStateToProps, dispatchMap)(PadComponent);
