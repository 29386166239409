import React, {PropsWithChildren, useLayoutEffect} from 'react';

import {useDispatch} from 'react-redux';

import {initializeSettingsAction} from '../../Store/SettingsState';

/**
 * Settings component used for providing the Duden Mentor Feature Flags.
 *
 * @param props
 * @constructor
 */
export const Settings: React.FC<PropsWithChildren<{}>> = (props) => {
    const dispatch = useDispatch();
    useLayoutEffect(
        () => { dispatch(initializeSettingsAction()); },
        [dispatch],
    );
    return (<>{props.children}</>);
};
