import styled from "styled-components";

import { MediaBreakpoints } from "../../Styles";

export const StyledExtensionsListView = styled.div<{
    hasHeight?: boolean;
}>`
    padding: 10px 20px;
    overflow: auto;
    
    @media screen and ${MediaBreakpoints.medium} {
        padding: 90px 100px;
    }
`;

export const StyledExtensionsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
`;

export const StyledExtensionListItem = styled.li`
  margin: 0 40px 25px 0;
`;
