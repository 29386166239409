// tslint:disable:no-any
import {Dispatch, Middleware} from 'redux';
import {Action} from 'typescript-fsa';
// import {State} from 'FullVersion/Store'; TODO: move redux away from library
import {editorChangedAction, EditorChangePayload} from './Actions';

const regionCheckbox: HTMLFormElement = document.getElementById('hide-appendix') as HTMLFormElement;

export const triggerRegionMiddleWare: Middleware<{}, any> = () =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            // return early if no checkbox is found
            if (!regionCheckbox) {
                return next(action);
            }

            if (action.type === editorChangedAction.type) {
                const editorChange = action.payload as EditorChangePayload;
                const currentContent = editorChange.newEditorState.getCurrentContent();
                const currentContentLength = currentContent.getPlainText().length;

                regionCheckbox.checked = !!currentContentLength;
            }

            return next(action);
        };
