import * as React from "react";

export const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M14.29 5.168l.076.066 6.222 6.223-6.222 6.222c-.313.313-.82.313-1.132 0-.288-.288-.31-.742-.066-1.056l.066-.075 4.29-4.292H3.8c-.442 0-.8-.357-.8-.8 0-.407.305-.744.7-.793l.1-.006 13.724-.001-4.29-4.29c-.288-.289-.31-.742-.066-1.056l.066-.076c.289-.288.742-.31 1.056-.066z"
        />
    </svg>
);
