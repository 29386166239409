import React, {useCallback, useEffect} from "react";

import * as SC from './ExpressPad.styled';
import useOneClickStore, { selectSegments, selectText } from "../../Hooks/useOneClickStore";
import { ExpressTextSegment } from "../ExpressTextSegment";

const ExpressPad: React.FC = () => {
    const text = useOneClickStore(selectText);
    const segments = useOneClickStore(selectSegments);
    const editorNode = useOneClickStore((store) => store.editorNode);
    const setIsCorrectionFinished = useOneClickStore((store) => store.setIsCorrectionFinished);
    const setBufferText = useOneClickStore((store) => store.setBufferText);
    const syncBufferText = useOneClickStore((store) => store.syncBufferText);
    const setEditorNode = useOneClickStore((store) => store.setEditorNode);
    const isHighlightModeActive = useOneClickStore((store) => store.isHighlightModeActive);
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const paraphrasingMode = useOneClickStore((store) => store.paraphrasingMode);
    const isSegmentsLoading = useOneClickStore((store) => Object.values(store.segmentsLoading).some(segmentId => segmentId));
    const isSingleCorrectionMode = useOneClickStore((store) => store.singleCorrectionActiveIndex !== -1);

    useEffect(() => {
        syncBufferText();
    }, [syncBufferText, paraphrasingMode, isParaphrasingModeActive])

    const handleEditorChange = useCallback(() => {
        setIsCorrectionFinished(false)

        if (editorNode) {
            setBufferText(editorNode.innerText)
        }
    }, [setIsCorrectionFinished, editorNode, setBufferText]);

    const handleOnPaste = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        let paste = event.clipboardData.getData('text');
        const selection = window.getSelection();

        if (!selection?.rangeCount) return;
        selection.deleteFromDocument();

        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
        handleEditorChange()
    }, [handleEditorChange]);

    return (
        <SC.Wrapper
            ref={setEditorNode}
            key={isParaphrasingModeActive ? paraphrasingMode : 'common'}
            contentEditable={!isSegmentsLoading}
            isHighlighted={isHighlightModeActive && !isParaphrasingModeActive}
            isCorrected={!isSingleCorrectionMode && !!segments.length}
            suppressContentEditableWarning
            onPaste={handleOnPaste}
            onInput={handleEditorChange}
            placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
        >
            {!!segments.length ? segments.map((segment, index, segments) => <ExpressTextSegment key={segment.id} previousKey={segments[index - 1]?.id} data={segment} />) : text}
        </SC.Wrapper>
    );
};

export default ExpressPad;
