import React, { useCallback } from 'react';

import * as SC from './ParaphrasingAcceptPopover.styled';
import useOneClickStore, { DATA_SELECTOR } from '../../../Hooks/useOneClickStore';
import { SquareButton } from '../../Buttons';

type ParaphrasingAcceptPopoverProps = {
    handleClose: () => void;
    onAction: () => void;
    showArrow?: boolean;
};

const ParaphrasingAcceptPopover: React.FC<ParaphrasingAcceptPopoverProps> = ({ handleClose, onAction, showArrow }) => {
    const handleCopyEditorText = useOneClickStore(state => state.handleCopyEditorText);

    const handleReject = useCallback(() => {
        onAction();
        handleClose();
    }, [onAction, handleClose])

    const handleAccept = useCallback(() => {
        handleCopyEditorText(DATA_SELECTOR.Lighter, DATA_SELECTOR.Neutral, DATA_SELECTOR.Formal);
        onAction();
        handleClose();
    }, [handleCopyEditorText, onAction, handleClose])

    return <SC.Wrapper showArrow={showArrow}>
        <SC.CloseButton onClick={handleClose}>❌</SC.CloseButton>
        <SC.Content>Möchten Sie den aktuellen Text übernehmen?</SC.Content>
        <SC.CtaContainer>
            <SquareButton className="white" onClick={handleReject}>Nein</SquareButton>
            <SquareButton className="dark" onClick={handleAccept}>Ja</SquareButton>
        </SC.CtaContainer>
    </SC.Wrapper>
}

export default ParaphrasingAcceptPopover;