import React from "react";

import { useSelector } from "react-redux";

import * as SC from './NoAdvice.styled';
import { selectCheckStateFromMainState } from "../../../Store/AdviceState";
import { selectTextLength } from "../../../Store/PadState";
import { AcceptIcon } from "../../Icons";

const NoAdvice: React.FC = () => {
    const {spellAdvices, styleAdvices} = useSelector(selectCheckStateFromMainState);
    const textLength = useSelector(selectTextLength);

    if (textLength > 0 && spellAdvices.length === 0 && styleAdvices.length === 0) {
        return (
            <SC.Wrapper>
                <span>
                    <AcceptIcon height={42} width={42} />
                </span>
                <p>Es wurden keine Fehler gefunden.</p>
            </SC.Wrapper>
        )
    }

    return null;
}

export default NoAdvice;
