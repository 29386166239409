import styled from "styled-components";

import { Fonts } from '../../../Styles';

export const Wrapper = styled.span`
    border: 1px solid currentColor;
    border-radius: 3px;
    font-family: ${Fonts.theinhardt};
    font-size: 13px;
    line-height: 1.46;
    padding: 2px 6px 1px 8px;
    margin-right: 12px;
    white-space: nowrap;
`;