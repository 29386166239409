import React from 'react';

import { useSelector } from 'react-redux';

import * as SC from './UserStatusLabel.styled';
import { selectUserFeatures, selectUserStateFromMainState, UserRole } from '../../../Store/UserState';
import { hasFeature, hasRole, isPlusUser, isPremiumUser } from '../../../Util/UserUtils';

export const UserStatusLabel: React.FC = () => {
    const { roles } = useSelector(selectUserStateFromMainState);
    const userFeatures = useSelector(selectUserFeatures);

    const isAuthenticated = hasRole(UserRole.Authenticated, roles);

    if (!isAuthenticated) {
        return null;
    }

    const isDisplayedAsTrial = hasFeature('displayAsTrial', userFeatures);
    const isPremiumRole = isPremiumUser(roles);
    const isPlusRole = isPlusUser(roles);

    const labelCopy = isDisplayedAsTrial
        ? 'Premium-Test' :
        (isPremiumRole
            ? (isPlusRole
                ? 'Premium Plus'
                : 'Premium')
            : 'Basis');

    return (
        <SC.Wrapper className="user-status">
            {labelCopy}
        </SC.Wrapper>
    );
};