import React from 'react';

import {useSelector} from 'react-redux';

import {PremiumTeaserElement} from './PremiumTeaserElement';
import {PremiumTouchPointLink} from "./PremiumTouchPointLink";
import {AdviceType} from './Types';
import {selectUserStateFromMainState} from '../../../Store/UserState';
import {hasAnyFeature} from '../../../Util/UserUtils';
import '../../../Styles/component/authoring/advice__teaser/advice__teaser.sass';
import '../../../Styles/component/authoring/toast/toast.sass';

export const PremiumTeaser: React.FC = () => {
    const user = useSelector(selectUserStateFromMainState);

    if (hasAnyFeature(user.features, 'displayAsPremium', 'displayAsTrial')) {
        return null;
    }

    const synonymTeaserText: JSX.Element = (
        <>
            Premium-Mitglieder erhalten Synonym-Vorschläge<br/>
            <PremiumTouchPointLink adviceType="synonyms" />;
        </>
    );

    const writingStyleTeaserText: JSX.Element = (
        <>
            Premium-Mitglieder erhalten stilistische Hinweise<br/>
            <PremiumTouchPointLink adviceType="style_vocabulary" />;
        </>
    );

    return (
        <>
            <div className="advice__teaser-header">Premium-Funktionen</div>
            <PremiumTeaserElement
                type={AdviceType.spellCheck}
                title="Synonyme"
                teaserText={synonymTeaserText}
            />
            <PremiumTeaserElement
                type={AdviceType.writingStyle}
                title="Stil und Vokabular"
                teaserText={writingStyleTeaserText}
            />
        </>
    );
}
