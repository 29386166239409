import {useLayoutEffect} from "react";

import {useDispatch, useSelector} from 'react-redux';

import {AdviceData} from './Props';
import {
    ADVICE_TYPE,
    AdviceType,
    selectSelectedAdviceItem,
    selectSpellAdvices,
    selectStyleAdvices,
    setSelectedAdviceItemAction,
    SelectedAdviceItem
} from "../../../Store/AdviceState";
import {selectUserStateFromMainState} from '../../../Store/UserState';


interface ReturnValue {
    advices: AdviceData[];
    selectedAdviceItem: SelectedAdviceItem | undefined;
    setNextSelectedAdvice: () => void;
}

export const useSelectedAdviceItem = (type?: AdviceType): ReturnValue => {
    const spellAdvices = useSelector(selectSpellAdvices);
    let styleAdvices = useSelector(selectStyleAdvices);
    const selectedAdviceItem = useSelector(selectSelectedAdviceItem);
    const hideStyleAdvices = useSelector(selectUserStateFromMainState).hideStyleAdvice;
    const dispatch = useDispatch();

    useLayoutEffect(
        () => {
            if (selectedAdviceItem !== undefined && spellAdvices.length === 0 && styleAdvices.length === 0) {
                dispatch(setSelectedAdviceItemAction(undefined));
            }
        },
        [spellAdvices, styleAdvices],
    );

    function setNextSelectedAdvice(): void {
        if (hideStyleAdvices) {
            styleAdvices = [];
        }

        let currentAdvice: AdviceData | undefined;
        const adviceList = [...spellAdvices, ...styleAdvices].sort(
            (a: AdviceData, b: AdviceData) => a.offset - b.offset,
        );

        if (adviceList.length <= 1) {
            dispatch(setSelectedAdviceItemAction(undefined));
            return;
        }

        if (selectedAdviceItem === undefined) {
            const adviceType = spellAdvices.indexOf(adviceList[0]) === -1 ? ADVICE_TYPE.StyleError : ADVICE_TYPE.SpellError;
            dispatch(setSelectedAdviceItemAction({
                index: 0,
                adviceType,
            }));

            return;
        }

        currentAdvice = selectedAdviceItem.adviceType === ADVICE_TYPE.SpellError
            ? spellAdvices[selectedAdviceItem.spellingIndex]
            : styleAdvices[selectedAdviceItem.styleIndex];

        let nextAdviceListIndex= adviceList.indexOf(currentAdvice) + 1;
        if (nextAdviceListIndex >= adviceList.length) {
            nextAdviceListIndex = adviceList.length - 1;
        }

        const nextAdvice = adviceList[nextAdviceListIndex];
        const nextAdviceType = spellAdvices.indexOf(nextAdvice) === -1 ? ADVICE_TYPE.StyleError : ADVICE_TYPE.SpellError;
        const nextAdviceIndex = (nextAdviceType === ADVICE_TYPE.SpellError
                ? spellAdvices.indexOf(nextAdvice)
                : styleAdvices.indexOf(nextAdvice)
        ) - (selectedAdviceItem.adviceType === nextAdviceType ? 1 : 0);

        dispatch(setSelectedAdviceItemAction({index: nextAdviceIndex, adviceType: nextAdviceType}));
    }

    if (type === ADVICE_TYPE?.SpellError) {
        return {advices: spellAdvices, selectedAdviceItem, setNextSelectedAdvice};
    }

    if (type === ADVICE_TYPE?.StyleError) {
        return {advices: styleAdvices, selectedAdviceItem, setNextSelectedAdvice};
    }

    return {
        advices: [...spellAdvices, ...styleAdvices].sort((a: AdviceData, b: AdviceData) => a.offset - b.offset),
        selectedAdviceItem,
        setNextSelectedAdvice,
    };
};
