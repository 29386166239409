import * as React from 'react';

interface ComponentProps {
    icon: string;
    tooltip: string;
    disabled: boolean;
}

interface ComponentFunctions {
    onClick?: () => void;
}

export type Props = ComponentProps & ComponentFunctions;

export class RimActionButton extends React.Component<Props> {
    public render(): JSX.Element {
        return (
            <button className="rim__action" disabled={this.props.disabled} onClick={this.onClick}>
                {`${this.props.icon} `}
                {!(document.body.classList.contains('user__can-touch'))
                    && <span className="rim__action__tooltip">{this.props.tooltip}</span>
                }
            </button>
        );
    }

    private onClick = (event: React.SyntheticEvent<{}>): void => {
        event.stopPropagation();
        if (this.props.onClick) {
            event.preventDefault();
            this.props.onClick();
        }
    }
}
