import styled, { css } from 'styled-components';

import {Colors, Fonts} from '../../Styles';

export const baseCheckButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    padding: 0;
    background: ${Colors.mineShaft};
    border: none;
    border-radius: 50%;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.3);
    color: ${Colors.white};
`;

export const baseCheckWrapper = css`
    display: flex;
    border-radius: 23px;
    align-items: center;
    font-size: 15px;
    font-family: ${Fonts.theinhardt};
    color: ${Colors.mineShaft};
    white-space: nowrap;
    position: relative;
    right: 0;
    margin-bottom: 15px;
`;

export const StyledCheckProgress = styled.div`
    ${baseCheckWrapper};
    background: ${Colors.grannySmithApple};
`;

export const StyledCheckProgressPercent = styled.span`
    ${baseCheckButton};
    font-family: ${Fonts.theinhardt};
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
`;

export const StyledCheckButtonLoading = styled.button`
    ${baseCheckButton};
    padding: 4px;

    svg {
        animation: spin 2s infinite;
        fill: currentColor;
        height: 100%;
        pointer-events: none;
        width: 100%;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;

export const StyledCheckTitle = styled.span`
  margin: 0 10px 0 16px;
`;

export const StyledCheckButtonWrapper = styled.div<{ isDisabled?: boolean, textLength?: number }>`
    ${baseCheckWrapper};
    background-color: ${({ isDisabled, textLength }) => isDisabled || !textLength ? 'transparent' : Colors.supernova};
    
    ${({textLength}) => !textLength ? css`
        span{
            display: none;
        }
        
        &:hover {
            span {
                display: none;
            }
        }
    ` : css`
        &:hover {
            cursor: pointer;

            span {
                display: block;
            }
        }
    `}
`;

export const StyledCheckButton = styled.button`
    ${baseCheckButton};
    position: relative;
    display: block;
    padding: 4px;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
        pointer-events: none;
    }
    
    i {
        display: block;
        font-size: 22px;
        font-weight: 700;
        transform: translateX(-2px);
    }
`;