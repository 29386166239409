import React, { useEffect } from 'react';

import styled from 'styled-components';

import { Fonts } from '../../Styles';
import { Colors } from '../../Styles';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { useInlineAdviceItem } from '../../Util/useInlineAdviceItem';
import { SettingsInformation } from '../Advice/SettingsInformation';
import { useAdvice } from '../Advice/useAdvice';
import { AdviceData } from '../Advices/Util/Props';
import { RoundButton } from '../Buttons';
import { AcceptAllIcon, AcceptIcon, DictionaryIcon } from '../Icons';
import { Pill } from '../Pill';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        _is_advise_highlighted?: boolean;
    }
}

export interface Props {
    advice: AdviceData;
    markerPositionLeft: number;
    markerPositionTop: boolean;
    setNextAdviceItem: () => void;
}

interface StyleProps {
    markerPositionLeft: number;
    markerPositionTop: boolean;
}

const StyledInlineAdvice = styled.div<StyleProps>`
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    border-radius: 6px;
    padding: 7px 12px 5px;
    box-shadow: 0 2px 24px 0 rgba(52, 57, 60, 0.32);
    width: 255px;

    &:before {
        content: "";
        position: absolute;
        border-style: solid;
        border-color: ${Colors.white} transparent;
        display: block;
        width: 0;
        border-width: ${(p: StyleProps) => p.markerPositionTop ? '0 15px 10px' : '10px 15px 0'};
        bottom: ${(p: StyleProps) => p.markerPositionTop ? 'auto' : '-10px'};
        top: ${(p: StyleProps) => p.markerPositionTop ? '-5px' : 'auto'};
        left: ${(p: StyleProps) => p.markerPositionLeft ? `${p.markerPositionLeft}px` : '20px'};
        right: auto;
        z-index: 1;
    }

    .advice__proposal {
        font-size: 14px;
    }

    .advice__label {
        font-size: 15px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    .short-message {
        font-size: 15px;
    }
`;

const StyledProposalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 3px 4px;
`;

const StyledAdditionalShortMessage = styled.div`
    margin: 0 3px 10px;
`;

const StyledShortMessage = styled.span`
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
`;

const StyledAdvicePanelButton = styled.button`
    border: none;
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    cursor: pointer;
    padding: 4px 3px 3px;
    font-size: 13px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 7px;
    border-radius: 19px;

    &:hover {
        background: ${Colors.mystic};
    }

    svg {
        vertical-align: middle;
        margin-top: -1px;
        fill: currentColor;
        width: 25px;
        height: 25px;
        margin-right: 7px;
    }
`;

const StyledAdvicePanelIgnoreButton = styled(StyledAdvicePanelButton)`
    font-size: 13px;
    font-weight: 500;
    width: auto;
    border-radius: 4px;
    background: ${Colors.mystic};
    padding: 5px 10px 3px 10px;

    span {
        color: ${Colors.rollingStone};
    }

    &:hover {
        background: ${Colors.mystic};
    }
`;

const StyledAlternativeSuggestions = styled.div`
    font-family: "Theinhardt", monospace, sans-serif;
    padding: 0 2px;
    margin: 13px 0 10px;
    font-size: 13px;
`;

const StyledProposalHeading = styled.h3`
    text-transform: uppercase;
    font-family: ${Fonts.theinhardt};
    font-size: 10px;
    font-weight: bold;
    color: ${Colors.osloGray};
    line-height: 1.38;
    letter-spacing: 1px;
    margin: 0 0 7px;
`;

export const InlineAdvice: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { closeInlineAdviceItem } = useInlineAdviceItem();
    const adviceType = props.advice.type === 'style' ? 'STYLE-ERROR' : 'SPELL-ERROR';
    const {
        suggestion,
        isAcceptable,
        isMultiple,
        showAdditionalShortMessage,
        hasSettingsInformation,
        reject,
        accept,
        mouseEnter,
        mouseLeave,
        canAddToDictionary,
        addToDictionary,
    } = useAdvice(props.advice, adviceType);

    useEffect(() => {
        const keyPressHandler: (this:Window, ev: KeyboardEvent) => any = (event) => {
            if (event.ctrlKey && event.keyCode === 73) {
                event.preventDefault();
                onClickReject();
            }
        };

        window.addEventListener('keydown', keyPressHandler);
        window._is_advise_highlighted = true;

        return () => {
            window.removeEventListener('keydown', keyPressHandler);
            window._is_advise_highlighted = false;
        };
    }, [props.advice.entityKey]);

    /**
     * accept all occurrences of an error with specific proposal/text
     */
    const onClickAccept = (alternative: string) => {
        GTMDataLayerPush({ event: 'text-advice', option: 'accepted', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        accept(alternative);
        closeInlineAdviceItem();
        props.setNextAdviceItem();
    };

    const onClickAlternatives = (alternative: string) => {
        GTMDataLayerPush({ event: 'select-alternative', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style', location: 'inline-advice' });
        accept(alternative);
    };

    const onClickReject = () => {
        GTMDataLayerPush({ event: 'text-advice', option: 'rejected', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        reject();
        closeInlineAdviceItem();
        props.setNextAdviceItem();
    };

    const onClickDictionary = () => {
        GTMDataLayerPush({ event: 'save-in-dictionary', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        addToDictionary();
    };

    return (
        <StyledInlineAdvice markerPositionLeft={props.markerPositionLeft} markerPositionTop={props.markerPositionTop}>
            <StyledProposalWrapper>
                {suggestion}
                {isAcceptable && (
                    <RoundButton
                        defaultBackground={Colors.supernova}
                        hoverBackground={Colors.sun}
                        onMouseEnter={() => mouseEnter(props.advice.proposals[0] || ' ')}
                        onMouseLeave={mouseLeave}
                        onClick={() => onClickAccept(props.advice.proposals[0] || ' ')}
                        style={{ flexShrink: 0 }}
                    >
                        {isMultiple ? <AcceptAllIcon /> : <AcceptIcon />}
                    </RoundButton>
                )}
            </StyledProposalWrapper>
            {showAdditionalShortMessage && (
                <StyledAdditionalShortMessage>
                    <StyledShortMessage>{props.advice.shortMessage}</StyledShortMessage>
                    {hasSettingsInformation && <SettingsInformation />}
                </StyledAdditionalShortMessage>
            )}

            {props.advice.proposals.length > 1 && (
                <StyledAlternativeSuggestions>
                    <StyledProposalHeading>Alternativen</StyledProposalHeading>
                    {props.advice.proposals.slice(1)
                        .slice(0, 3)
                        .map((alternative, index) => (
                            <Pill
                                key={index}
                                fontSize={13}
                                onClick={() => onClickAlternatives(alternative)}
                                onMouseEnter={() => mouseEnter(alternative)}
                                onMouseLeave={mouseLeave}
                            >{alternative}</Pill>
                        ))}
                </StyledAlternativeSuggestions>
            )}

            <StyledAdvicePanelIgnoreButton
                onClick={() => {
                    onClickReject();
                }}
            >
                Ignorieren&nbsp;<span>(Strg/Ctrl + i)</span>
            </StyledAdvicePanelIgnoreButton>
            {canAddToDictionary && (
                <StyledAdvicePanelButton
                    onClick={() => {
                        onClickDictionary();
                    }}
                >
                    <DictionaryIcon /> In meinem Wörterbuch speichern
                </StyledAdvicePanelButton>
            )}
        </StyledInlineAdvice>
    );
};
