import React, { forwardRef } from 'react';

import * as SC from './Link.styled';

export enum LinkAppearance {
    Primary = 'primary',
    Secondary = 'secondary',
    Contrasted = 'contrasted',
    Inactive = 'inactive',
}


export type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  appearance?: LinkAppearance | boolean;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ appearance, children, ...linkProps }, forwardedRef) => {
    return (
      <SC.Link {...linkProps} appearance={appearance} ref={forwardedRef}>
        {children ?? linkProps.href}
      </SC.Link>
    );
  },
);
