import {
    CharacterMetadata,
    ContentBlock,
    ContentState,
    EditorState,
    EntityInstance,
    Modifier,
    SelectionState,
} from 'draft-js';

import {NestableDecorator} from './NestableDecorator';
import {CharacterLimitExceeded} from '../Components/Pad/Decorator/CharacterLimitExceeded';
import {ShowSynonym} from '../Components/Pad/Decorator/ShowSynonym';
import {SpellError} from '../Components/Pad/Decorator/SpellError';
import {SpellErrorCorrected} from '../Components/Pad/Decorator/SpellErrorCorrected';
import {SpellErrorIgnored} from '../Components/Pad/Decorator/SpellErrorIgnored';
import {StyleError} from '../Components/Pad/Decorator/StyleError';
import {initialState} from '../Store/UserState/Reducers';

export interface EntityDetails {
    entityKey: string;
    blockKeyStart: string;
    blockKeyEnd: string;
    entity: EntityInstance;
    moreThanOneBlock: boolean;
    start?: number;
    end?: number;

}

export interface EntityMetadata {
    customTypes: string[];
    originalError?: string;
    entityKey?: string;
    firstOccurrenceEntityKey?: string;
}

export enum decoratorStrategyType {
    spellError = 'SPELLERROR',
    spellErrorCorrected = 'SPELLERRORCORRECTED',
    spellErrorIgnored = 'SPELLERRORIGNORED',
    styleError = 'STYLEERROR',
    wordHighlighted = 'WORDHIGHLIGHTED',
}

export const findEntityRangeForEntity = (
    editorState: EditorState,
    entityKey: string,
    callback: (start: number, end: number) => void,
) => {
    const contentState = editorState.getCurrentContent();
    contentState.getBlockMap().map((contentBlock) =>
        (
            contentBlock?.findEntityRanges(
                (character: CharacterMetadata) => {
                    const foundEntityKey = character.getEntity();

                    return entityKey === foundEntityKey;
                },
                callback,
            )
        ),
    );
};

export const getDraftSelection = (
    editorState: EditorState,
    start: number,
    end: number,
    startKey?: string,
    endKey?: string,
): SelectionState => {

    startKey = startKey ? startKey : editorState.getCurrentContent().getFirstBlock().getKey();
    endKey = endKey ? endKey : startKey;

    return new SelectionState({
        anchorKey: startKey,
        anchorOffset: start,
        focusKey: endKey,
        focusOffset: end,
        isBackward: false,
        hasFocus: false,
    });
};

export const getBlockDraftSelection = (editorState: EditorState, start: number, end: number): SelectionState => {
    const contentState = editorState.getCurrentContent();
    let length: number = 0;
    let startKey: string | undefined;
    let endKey: string | undefined;
    let anchorOffset: number = start;
    let focusOffset: number = end;
    let counter = 0;
    contentState.getBlocksAsArray().forEach((block: ContentBlock) => {
        const oldLength = length;
        length += block.getLength() + counter;
        if (!startKey && start >= oldLength && start < length) {
            startKey = block.getKey();
            anchorOffset = start - oldLength - counter;

            if(anchorOffset < 0) {
                counter += Math.abs(anchorOffset);
                anchorOffset = 0;
            }
        }

        if (!(startKey === undefined) && (endKey === undefined) && (end >= oldLength && end <= length)) {
            endKey = block.getKey();
            focusOffset = end - oldLength - counter;
        }
        counter = 1;
    });

    startKey = startKey ? startKey : editorState.getCurrentContent().getFirstBlock().getKey();
    endKey = endKey ? endKey : startKey;

    return new SelectionState({
        anchorKey: startKey,
        anchorOffset,
        focusKey: endKey,
        focusOffset,
        isBackward: false,
        hasFocus: false,
    });
};

const getSelectedText = (editorState: EditorState): string => {
    const blockDelimiter = '\n';
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const blocks = editorState.getCurrentContent().getBlockMap();

    let lastWasEnd = false;
    const selectedBlock = blocks
        .skipUntil((block) => block?.getKey() === startKey)
        .takeUntil((block) => {
            const result = lastWasEnd;
            if (block?.getKey() === endKey) {
                lastWasEnd = true;
            }
            return result;
        });

    return selectedBlock
        .map((block) => {
            const key = block?.getKey();
            let text = block?.getText();

            let start = 0;
            let end = text?.length;

            if (key === startKey) {
                start = selection.getStartOffset();
            }
            if (key === endKey) {
                end = selection.getEndOffset();
            }

            text = text?.slice(start, end);
            return text;
        })
        .join(blockDelimiter);
};

const getOffsetOfSelectionInFullText = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    const blocks = editorState.getCurrentContent().getBlocksAsArray();

    const startBlockKey = selection.getStartKey();
    const endBlockKey = selection.getEndKey();

    let startOffset = selection.getStartOffset();
    let endOffset = selection.getEndOffset();

    for (const block of blocks) {
        if (block.getKey() !== startBlockKey) {
            startOffset += block.getText().length;
        } else {
            break;
        }
    }
    for (const block of blocks) {
        if (block.getKey() !== endBlockKey) {
            endOffset += block.getText().length;
        } else {
            break;
        }
    }

    return {
        startOffset: startOffset <= endOffset ? startOffset : endOffset,
        endOffset: startOffset <= endOffset ? endOffset : startOffset,
    };
};

export interface Selection {
    text: string;
    startOffset: number;
    endOffset: number;
}

export const getSelection = (editorState: EditorState): Selection => {
    const selectedText = getSelectedText(editorState);
    const selection = getOffsetOfSelectionInFullText(editorState);

    return {text: selectedText, startOffset: selection.startOffset, endOffset: selection.endOffset};
};

export function getEntityByMetadataStrategy(type: string): (
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => void {
    return (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
        contentBlock.findEntityRanges(
            (character: CharacterMetadata) => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                const foundType = contentState.getEntity(entityKey).getData().customTypes.find(
                    (entityType: string) => entityType === type,
                );
                return foundType === type;
            },
            callback,
        );
    };
}

export function deleteAllEntitiesWithMetadata(editorState: EditorState, type: decoratorStrategyType): EditorState {
    const entityRanges = getRangesWithBlocksForAllEntitiesWithCustomType(editorState, type);
    editorState = entityRanges.reduce(
        (editorStateInProgress: EditorState, entityRange: EntityDetails) => {
            const entityKey = entityRange.entityKey;
            const currentContentState = editorStateInProgress.getCurrentContent();
            const data: EntityMetadata = currentContentState.getEntity(entityKey).getData();
            let entityTypes = data.customTypes;
            let newContentState;
            if (entityTypes.length > 1) {
                entityTypes = entityTypes.filter((entityType: string) => entityType !== type);
                newContentState = currentContentState.mergeEntityData(entityKey, {
                        ...data,
                        customTypes: entityTypes,
                    },
                );
            } else {
                const replaceSelection = getDraftSelection(
                    editorStateInProgress,
                    entityRange.start!,
                    entityRange.end!,
                    entityRange.blockKeyStart,
                    entityRange.blockKeyEnd,
                );
                // tslint:disable-next-line:no-null-keyword
                newContentState = Modifier.applyEntity(currentContentState, replaceSelection, null);
            }

            editorStateInProgress = EditorState.push(editorStateInProgress, newContentState, 'apply-entity');
            return editorStateInProgress;
        },
        editorState,
    );
    return editorState;
}

export function createOrUpdateEntity(
    selection: SelectionState,
    contentState: ContentState,
    editorState: EditorState,
    metadata: EntityMetadata,
): ContentState {
    const oldEntities = getEntitiesInSelection(selection, editorState);
    if (oldEntities.length > 0) {
        const oldEntity = contentState.getEntity(oldEntities[0]);
        if (metadata.customTypes !== oldEntity.getData().customTypes) {
            metadata.firstOccurrenceEntityKey = oldEntities[0];
        }
        metadata.customTypes = [...oldEntity.getData().customTypes, ...metadata.customTypes];
    }
    return contentState.createEntity(
        'GENERIC',
        'MUTABLE',
        metadata,
    );
}

export function getEntitiesInSelection(selection: SelectionState, editorState: EditorState): string[] {
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const startBlock = editorState.getCurrentContent().getBlockForKey(startKey);
    const entities = [];

    for (let ii = startOffset; ii < endOffset; ii++) {
        const entityKey = startBlock.getEntityAt(ii);
        if (typeof entityKey === 'string') {
            entities.push(entityKey);
        }
    }
    return entities;
}

export function isEntityInSelection(selection: SelectionState, editorState: EditorState, type: string): boolean {
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const startBlock = editorState.getCurrentContent().getBlockForKey(startKey);
    const endBlock = editorState.getCurrentContent().getBlockForKey(endKey);
    if (startBlock === endBlock) {
        for (let ii = startOffset; ii < endOffset; ii++) {
            const entityKey = startBlock.getEntityAt(ii);
            if (!entityKey) {
                continue;
            }

            const entityTypes = editorState.getCurrentContent().getEntity(entityKey).getData().customTypes.filter(
                (entityType: string) => entityType === type,
            );
            if (entityTypes.length > 0) {
                return true;
            }
        }
    } else {
        for (let ii = startOffset; ii < startBlock.getLength(); ii++) {
            const entityKey = startBlock.getEntityAt(ii);
            if (!entityKey) {
                continue;
            }

            const entityTypes = editorState.getCurrentContent().getEntity(entityKey).getData().customTypes.filter(
                (entityType: string) => entityType === type,
            );
            if (entityTypes.length > 0) {
                return true;
            }
        }

        for (let ii = 0; ii <= endOffset; ii++) {
            const entityKey = endBlock.getEntityAt(ii);
            if (!entityKey) {
                continue;
            }

            const entityTypes = editorState.getCurrentContent().getEntity(entityKey).getData().customTypes.filter(
                (entityType: string) => entityType === type,
            );
            if (entityTypes.length > 0) {
                return true;
            }
        }
    }
    return false;
}

export const getRangesWithBlocksForAllEntitiesWithCustomType = (
    editorState: EditorState,
    type: string,
) => {
    const entityRanges: EntityDetails[] = [];
    let selectedEntity: EntityDetails;
    const callbackNew = (start: number, end: number) => {
        const entityIndex = Number(selectedEntity.entityKey);

        if (!entityRanges[entityIndex]) {
            entityRanges[entityIndex] = ({...selectedEntity, start, end});
        } else {
            entityRanges[entityIndex].moreThanOneBlock = true;
            entityRanges[entityIndex].end = end;
            entityRanges[entityIndex].blockKeyEnd = selectedEntity.blockKeyEnd;
        }
    };
    const contentState = editorState.getCurrentContent();
    contentState.getBlockMap().map((contentBlock) =>
        (
            contentBlock?.findEntityRanges(
                (character: CharacterMetadata) => {
                    const foundEntityKey = character.getEntity();
                    if (foundEntityKey === null) {
                        return false;
                    }
                    const foundType = contentState.getEntity(foundEntityKey).getData().customTypes.find(
                        (entityType: string) => entityType === type,
                    );
                    if (foundType === type) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKeyStart: contentBlock.getKey(),
                            blockKeyEnd: contentBlock.getKey(),
                            entity: contentState.getEntity(character.getEntity()),
                            moreThanOneBlock: false,
                        };
                        return true;
                    }
                    return false;
                },
                callbackNew,
            )
        ),
    );
    return entityRanges;
};

export function getDetailsForEntity(editorState: EditorState, entityKey: string): EntityDetails[] {
    const contentState = editorState.getCurrentContent();
    const entities: EntityDetails[] = [];
    contentState.getBlocksAsArray().forEach((block: ContentBlock) => {
        let selectedEntity: EntityDetails;
        block.findEntityRanges(
            (character: CharacterMetadata) => {
                const foundEntityKey = character.getEntity();
                if (entityKey === foundEntityKey) {
                    selectedEntity = {
                        entityKey: character.getEntity(),
                        blockKeyStart: block.getKey(),
                        blockKeyEnd: block.getKey(),
                        entity: contentState.getEntity(character.getEntity()),
                        moreThanOneBlock: false,
                    };
                    return true;
                }

                return false;
            },
            (start: number, end: number) => {
                const entityIndex = Number(selectedEntity.entityKey);

                if (!entities[entityIndex]) {
                    entities[entityIndex] = ({...selectedEntity, start, end});
                } else {
                    entities[entityIndex].moreThanOneBlock = true;
                    entities[entityIndex].end = end;
                    entities[entityIndex].blockKeyEnd = selectedEntity.blockKeyEnd;
                }
            });
    });
    return entities;
}

/**
 * This function finds characters in a given block which exceed the characterLimit and calls the callback function
 * @param {number} characterLimit
 * @returns {(block: , callback: (start: number, end: number) => void, contentState: ) => void}
 */
export function getCharacterLimitExceededStrategy(characterLimit: number):
    (
        block: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState,
    ) => void {
    return (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
        let blockEndOffset: number = 0;
        let blockCounter: number = 0;
        const blockArray: ContentBlock[] = contentState.getBlocksAsArray();
        for (const block of blockArray) {
            if (block.getKey() === contentBlock.getKey()) {
                break;
            }
            blockEndOffset += block.getLength();
            blockCounter++;
        }
        blockEndOffset += blockCounter;
        const blockStartOffset = blockEndOffset;
        blockEndOffset += contentBlock.getLength();
        if (blockEndOffset > characterLimit && characterLimit > 0) {
            if (blockStartOffset > characterLimit) {
                callback(0, contentBlock.getLength());
            } else {
                callback(characterLimit - blockStartOffset, contentBlock.getLength());
            }
        }
    };
}

export const advancedDecoratorSpells = (characterLimit: number = 800) => new NestableDecorator([
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellError),
        component: SpellError,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.styleError),
        component: StyleError,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellErrorCorrected),
        component: SpellErrorCorrected,
    },

    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellErrorIgnored),
        component: SpellErrorIgnored,
    },
    {
        strategy: getCharacterLimitExceededStrategy(characterLimit),
        component: CharacterLimitExceeded,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.wordHighlighted),
        component: ShowSynonym,
    },
]);

export const decoratorSpell = new NestableDecorator([
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellError),
        component: SpellError,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.styleError),
        component: StyleError,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellErrorCorrected),
        component: SpellErrorCorrected,
    },

    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.spellErrorIgnored),
        component: SpellErrorIgnored,
    },
    {
        strategy: getCharacterLimitExceededStrategy(initialState.characterLimit),
        component: CharacterLimitExceeded,
    },
    {
        strategy: getEntityByMetadataStrategy(decoratorStrategyType.wordHighlighted),
        component: ShowSynonym,
    },
]);
