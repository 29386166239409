import * as React from 'react';

import styled from "styled-components";

import {Colors, Fonts} from "../../Styles";
import {CrossOutlinedIcon} from "../Icons/CrossOutlinedIcon";

type ComponentProps = {
    word: string;
    id: number;
    onDelete: (id: number) => void;
}

const DictionaryItem = styled.div`
  padding: 0 24px;
  display: flex;
    
  &:hover {
    background: ${Colors.bajaWhite};
  }

  &:nth-child(n+2) {
    border-top: 1px solid #ccc
  }

  .word {
    font-family: ${Fonts.publico};
    font-size: 28px;
    font-weight: 400;
    line-height: 72px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete {
    position: relative;
    background: none;
    border: none;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;

    title {
      height: 32px;
      width: 91px;
      background-color: ${Colors.mineShaft};
      border-radius: 10px;
    }

    svg {
      border-radius: 20px;
      transition-duration: 0.3s;
    }

    &:hover {

      .tooltip {
        opacity: 1;
      }

      svg {
        background: ${Colors.mineShaft};

        #cross-main {
          fill: ${Colors.white}
        }
      }
    }
  }
`;

type TooltipProps = {
    direction: 'north' | 'south' | 'east' | 'west'
}

const Tooltip = styled.span<TooltipProps>`
  position: absolute;
  font-family: ${Fonts.theinhardt};
  font-weight: 500;
  background: black;
  color: white;
  padding: 0.6em 1em;
  font-size: 13px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity .25s, transform .25s;
  pointer-events: none;
  z-index: 17;

  ${TooltipProps => {
    switch (TooltipProps.direction) {
      case 'north':
        return `
                    bottom: 100%;
                    left: 50%;
                    transform: translate(-50%, 20%);`;
      case 'south':
        return `
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 80%);`;
      case 'east':
        return `
                    left: 100%;
                    top: 50%;
                    transform: translate(20%, -50%);`;
      case 'west':
        return `
                    right: 100%;
                    top: 50%;
                    transform: translate(-20%, -50%);`;
    }
  }}

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;

    ${TooltipProps => {
      switch (TooltipProps.direction) {
        case 'north':
          return `
            border-width: 3px 3px 0 3px;
            border-color: black transparent transparent transparent;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);`;
        case 'south':
          return `
            border-width: 0px 3px 3px;
            border-color: transparent transparent black;
            left: 50%;
            top: 0;
            transform: translate(-50%, -100%);`;
        case 'east':
          return `
            border-width: 3px 3px 3px 0;
            border-color: transparent black transparent transparent;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);`;
        case 'west':
          return `
            border-width: 3px 0 3px 3px;
            border-color: transparent transparent transparent black;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);`;
      }
    }}
  }
`;

export const DictionaryEntry = (props: ComponentProps) => {
    const onDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        props.onDelete(props.id);
    }

    return (
        <DictionaryItem>
            <span className="word">{props.word}</span>
            <button className="delete" onClick={onDelete}><CrossOutlinedIcon/><Tooltip className="tooltip" direction="west">Wort löschen</Tooltip></button>
        </DictionaryItem>
    )
};
