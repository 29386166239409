import styled from "styled-components";

import { Colors, Fonts } from "../../../Styles";

export const Wrapper = styled.div`
    margin-bottom: 30px;

    details.always-open {
        z-index: 3;
        margin-top: 0;
        padding-top: 15px;
        padding-bottom: 20px;
        border-radius: 10px;
    }

    details:nth-child(2) {
        z-index: 2;
    }

    details:nth-child(3) {
        z-index: 1;
    }

    .button-navigation {
        display: flex;

        > * + * {
            margin-left: 13px;
        }
    }
`

export const TextTruncate = styled.span`
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
`;

export const OriginalError = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 10px;
    font-family: ${Fonts.publico};
    font-size: 23px;
    line-height: 1.2;

    .original-error {
        background-color: ${Colors.visVis};
        line-height: 1;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        &.style {
            background-color: ${Colors.periwinkle};
        }
    }
`;

export const AdditionalReplacements = styled.div`
    margin-top: 25px;

    h3 {
        text-transform: uppercase;
        font-family: ${Fonts.theinhardt};
        font-size: 14px;
        font-weight: bold;
        color: ${Colors.osloGray};
        line-height: 1.38;
    }
`;

export const StyledAdvicePanelButton = styled.button`
    border: none;
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    cursor: pointer;
    padding: 7px 15px 7px 2px;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 15px;
    width: 100%;
    margin: 0 -3px 3px;
    border-radius: 22px;

    &:first-of-type {
        margin-top: 15px;
    }

    &:hover {
        background: ${Colors.mystic};
    }

    svg {
        margin-right: 7px;
        margin-left: 5px;
        vertical-align: middle;
        width: 29px;
        height: 29px;
    }
`;

export const StyledAdvicePanelIgnoreButton = styled(StyledAdvicePanelButton)`
    font-size: 13px;
    font-weight: 500;
    width: auto;
    border-radius: 4px;
    background: ${Colors.mystic};
    margin: 0 0 3px;
    padding: 5px 10px 3px 10px;
    
    span {
        color: ${Colors.rollingStone};
    }

    &:hover {
        background: ${Colors.mystic};
    }
`;