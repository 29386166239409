import React from 'react';

import * as SC from './Tile.styled';

type TileProps = {
    heading: React.ReactNode;
    children: React.ReactNode;
    backgroundColor: string;
    button?: JSX.Element;
}

const Tile: React.FC<TileProps> = (props) => (
    <SC.Wrapper backgroundColor={props.backgroundColor} >
        <SC.TileHeading>{props.heading}</SC.TileHeading>
        {props.children}
        {!!props.button && props.button}
    </SC.Wrapper>
);

export default Tile;