import * as React from "react";

import styled from 'styled-components';

import {Colors, Fonts} from "../../Styles";

export const AdviceInformation: React.FC<React.HTMLProps<HTMLDivElement>> = styled.div`
    p {
        margin: 0 0 15px;
    }

    a {
        color: currentColor;
        border-bottom: 2px solid ${Colors.supernova};
        text-decoration: none;

        &:hover {
            border-bottom-width: 3px;
        }
    }

    ul, ol {
        margin: 7px 0 15px;
        padding: 0 0 0 20px;
    }

    ul {
        list-style-type: disc;
    }

    .note {
        background: ${Colors.bajaWhite};
        border-radius: 6px;
        padding: 20px;
        margin: 1em 0;

        &__title {
            display: block;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__content,
        &__content--good,
        &__content--bad {
            padding: 3px 0;

            p {
                margin-bottom: 7px;
            }
        }

        &__content--good,
        &__content--bad {
            position: relative;
            padding-left: 29px;
            margin-bottom: 15px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 3px;
                display: block;
                font-size: 17px;
                line-height: 1;
                color: #fff;
                z-index: 2;
                width: 22px;
                height: 22px;
                background-size: contain;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__content--good:before {
            background-image: url("/assets/icons/advice/goodexample.svg");
        }

        &__content--bad:before {
            background-image: url("/assets/icons/advice/badexample.svg");
        }
    }

    .rule-ref {
        color: ${Colors.white};
        background: ${Colors.crimson};
        padding: .26em .3em;
        margin: 0 .09em;
        line-height: 1em;
        font-weight: bold;
        display: inline-block;
        text-decoration: none;
        font-style: normal;
        font-family: ${Fonts.theinhardt};
        font-size: .55em;
        vertical-align: .3em;
        border-bottom: none;
    }
`;
