import * as React from "react";

import styled from "styled-components";

import {Colors, Fonts} from "../../Styles";

type Direction = "north" | "south" | "east" | "west"

const StyledTooltip: React.FC<React.HTMLProps<HTMLSpanElement> & {direction: Direction}> = styled.span`
    position: relative;
    line-height: 1;

    .tooltip__content {
        position: absolute;
        font-family: ${Fonts.theinhardt};
        font-weight: 500;
        background: ${Colors.black};
        color: ${Colors.white};
        padding: 0.6em 1em;
        font-size: 11px;
        line-height: 14px;
        border-radius: 4px;
        opacity: 0;
        transition: opacity .25s, transform .25s;
        pointer-events: none;
        z-index: 10;

        ${(p: {direction: Direction}) => p.direction === "north" && `
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 20%);
        `}
        ${(p: {direction: Direction}) => p.direction === "south" && `
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 80%);
        `}
        ${(p: {direction: Direction}) => p.direction === "east" && `
            left: 100%;
            top: 50%;
            transform: translate(20%, -50%);
        `}
        ${(p: {direction: Direction}) => p.direction === "west" && `
            right: 100%;
            top: 50%;
            transform: translate(-20%, -50%);
        `}

        &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-style: solid;

            ${(p: {direction: Direction}) => p.direction === "north" && `
                border-width: 3px 3px 0 3px;
                border-color: black transparent transparent transparent;
                left: 50%;
                top: 100%;
                transform: translateX(-50%);
            `}
            ${(p: {direction: Direction}) => p.direction === "south" && `
                border-width: 0px 3px 3px;
                border-color: transparent transparent black;
                left: 50%;
                top: 0;
                transform: translate(-50%, -100%);
            `}
            ${(p: {direction: Direction}) => p.direction === "east" && `
                border-width: 3px 3px 3px 0;
                border-color: transparent black transparent transparent;
                right: 100%;
                top: 50%;
                transform: translateY(-50%);
            `}
            ${(p: {direction: Direction}) => p.direction === "west" && `
                border-width: 3px 0 3px 3px;
                border-color: transparent transparent transparent black;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
            `}
        }
    }

    &:focus,
    &:hover {
        .tooltip__content {
            opacity: 1;

            ${(p: {direction: Direction}) => p.direction === "north" && `
                transform: translate(-50%, -20%)
            `}
            ${(p: {direction: Direction}) => p.direction === "south" && `
                transform: translate(-50%, 80%)
            `}
            ${(p: {direction: Direction}) => p.direction === "east" && `
                transform: translate(20%, -50%)
            `}
            ${(p: {direction: Direction}) => p.direction === "west" && `
                transform: translate(-20%, -50%)
            `}
        }
    }
`;

export const Tooltip = (props: React.HTMLProps<HTMLSpanElement> & {direction: Direction, text: string, children: React.ReactNode}) => (
    <StyledTooltip {...props}>
        <span className="tooltip__content">{props.text}</span>
        {props.children}
    </StyledTooltip>
)
