// tslint:disable:no-any
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';
// import {State} from 'FullVersion/Store'; TODO: move state away from library
import {initHideStyleAdviceAction, setHideStyleAdviceAction} from './Actions';

export const hideStyleAdviceMiddleware: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            if (setHideStyleAdviceAction.match(action)) {
                saveToLocalStorage(action.payload);
            } else if (initHideStyleAdviceAction.match(action)) {
                api.dispatch(setHideStyleAdviceAction(loadFromStorage()));
            }
            // Call the next dispatch method in the middleware chain.
            return next(action);
        };

function saveToLocalStorage(value: boolean): void {
    window.sessionStorage.setItem('hideStyleAdvice', value ? 'true' : 'false');
}

function loadFromStorage(): boolean {
    return window.sessionStorage.getItem('hideStyleAdvice') === 'true';
}
