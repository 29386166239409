import React from "react";

import styled from "styled-components";

import {Colors} from "../../Styles";

interface Props {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    size?: number;
    style?: React.CSSProperties;
    iconDefaultColor?: string;
    iconHoverColor?: string;
    defaultBackground?: string;
    hoverBackground?: string;
    disabled?: boolean;
}

const Button = styled.button`
    border: none;
    border-radius: 50%;
    width: ${(p: Props) => p.size}px;
    height: ${(p: Props) => p.size}px;
    background-color: ${(p: Props) => p.defaultBackground};
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: ${(p: Props) => p.hoverBackground};

        > svg {
            fill: ${(p: Props) => p.iconHoverColor || p.iconDefaultColor};
        }
    }

    &:focus {
        outline: none;
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        background-color: ${(p: Props) => p.defaultBackground};
        opacity: 0.5;
        pointer-events: none;
    }

    > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: ${(p: Props) => p.iconDefaultColor};
    }

    &:disabled > svg,
    &[disabled] > svg {
        fill: ${(p: Props) => p.iconDefaultColor};
        opacity: 0.5;
    }

    & + & {
        margin-left: 12px;
    }
`;

const defaultProps: Partial<Props> = {
    defaultBackground: Colors.mystic,
    hoverBackground: Colors.alto,
    iconDefaultColor: Colors.mineShaft,
    size: 32,
}

export const RoundButton = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
    (props, ref) => {
        return (
            <Button {...{...defaultProps, ...props}} ref={ref}>
                {props.children}
            </Button>
        );
    }
);
