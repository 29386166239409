import styled from 'styled-components';

import { Colors, MediaBreakpoints } from '../../Styles';

export const FormWrapper = styled.div`
    margin-top: 70px;
`;

export const FieldWrapper = styled.div`
    margin: 16px 0;
`;

export const CtaWrapper = styled.div`
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media screen and ${MediaBreakpoints.medium} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const ResetPassword = styled.div`
  margin: auto 0;
  
  a {
    font-size: 18px;
  }
`;

export const RegisterLinkLabel = styled.div`
  font-size: 18px;
  margin-bottom: 18px;
`;

export const RegisterLink = styled.div`
  div {
    font-weight: bold;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    color: ${Colors.mineShaft};
  }
`;

export const Hr = styled.hr`
  margin: 35px 0 25px;
  height: 1px;
  border: none;
  border-bottom: 1px solid ${Colors.iron};
`;
