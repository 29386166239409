import * as React from 'react';

import styled from "styled-components";

import {Colors, Fonts} from "../../Styles";

interface Props {
    color?: string;
    fontSize?: number;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const PillStyle = styled.button`
    border: none;
    border-radius: .4em;
    padding: .35em .65em;
    margin-right: 12px;
    margin-bottom: 12px;
    background: ${(p: Props) => p.color ? p.color : Colors.visVis};
    font-size: ${(p: Props) => p.fontSize ? p.fontSize : "17"}px;
    font-family: ${Fonts.theinhardt};

    ${(p: Props) => p.onClick ? `
        &:hover {
        background: ${Colors.bajaWhite};
        cursor: pointer;
    }
    ` : ""
    }
`;

export const Pill = (props: React.PropsWithChildren<Props>) => (
    <PillStyle {...props}>{props.children}</PillStyle>
)