import React from 'react';

import classNames from 'classnames';

import '../Styles/component/authoring/toast/toast.sass';

export enum toastType {
    success,
    warning,
    padded,
    ink,
}

export enum toastPointerDirection {
    left,
    bottom,
}

interface OffsetDirection {
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
    top?: string | number;
}

interface ComponentProps {
    positionOffset?: OffsetDirection;
    pointerOffset?: OffsetDirection;
    pointerDirection?: toastPointerDirection;
    type?: toastType;
    onOverlayClose: () => void;
}

export type Props = ComponentProps;

export const toastRouteGeneral = 'https://angebot.mentor.duden.de/premium_dir/';
export const toastRouteLite = 'https://angebot.mentor.duden.de/premium_dir/';

export class ToastOverlay extends React.Component<Props> {
    public render(): JSX.Element {
        const {pointerOffset, positionOffset, type} = this.props;
        return (
            <aside
                className={classNames('toast', {
                    'toast--type_success': type === toastType.success,
                    'toast--type_warning': type === toastType.warning || type === toastType.padded,
                    'toast--type_ink': type === toastType.ink,
                    'toast--padded': type === toastType.padded,
                })}
                style={(positionOffset ? {...positionOffset, position: 'absolute'} : {})}
            >
                {this.props.children}
                <button className="toast__close" onClick={this.onOverlayClose}>❌</button>
                {pointerOffset && <div
                    className="toast__pointer"
                    style={{...pointerOffset, position: 'absolute'}}
                >
                    {this.props.pointerDirection === toastPointerDirection.left ? '◀'
                        : document.body.classList.contains('user__can-touch') ? '▲' : '▼'}
                </div>}
            </aside>
        );
    }

    private onOverlayClose = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.onOverlayClose();
    }
}
