import React from "react";

import * as SC from "./IssueSection.styled";
import { IssueSectionIcon } from "./IssueSectionIcon";

type IssueSectionProps = {
    code?: string,
    title?: string,
    message?: string,
}
export const IssueSection: React.FC<IssueSectionProps> = ({code, title, message}) => {
    return <SC.Wrapper>
        {code && <IssueSectionIcon code={code} alt={title ? title : ""} />}
        {title && <SC.Title>{title}</SC.Title>}
        {message && <SC.Message>{message}</SC.Message>}
    </SC.Wrapper>
}
