import * as React from 'react';

interface Props {
    offsetKey: string;
    entityKey: string;
}

export class CharacterLimitExceeded extends React.Component<Props> {

    public render(): JSX.Element {
        return (
            <span className="pad__overflow">
            {this.props.children}
            </span>
        );
    }
}
