// tslint:disable:no-any
import { compose } from "redux";

import { SetInlineAdviceTimerPayload } from "./Actions";
import { State } from "./State";

export const selectPadStateFromMainState = (mainState: any): State => mainState.padState;

export const selectEditorState = (state: any) => selectPadStateFromMainState(state).editorState;

export const selectOneClickEditorState = (state: any) => selectPadStateFromMainState(state).oneClickEditorState;

export const selectText = compose(
    (state: State) => state.editorState.getCurrentContent().getPlainText(),
    selectPadStateFromMainState
);
export const selectTextLength = compose((text: string) => text.length, selectText);
export const selectHighlightedSpellError = (state: any) => selectPadStateFromMainState(state).highlightedSpellError;

export const selectInlineAdviceEntityKey = (state: any) => selectPadStateFromMainState(state).openResultEntityKey;

export const selectInlineAdviceTimer = (state: any): SetInlineAdviceTimerPayload =>
    selectPadStateFromMainState(state).inlineAdviceTimer;

export const selectCrashes = (state: any) => selectPadStateFromMainState(state).crashes;
