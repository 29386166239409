import * as React from 'react';

import '../../Styles/component/misc/switch.sass';

interface Props {
    disabled?: boolean;
    id: string;
    checked: boolean;
    onChange: () => void;
    type?: "checkbox" | "radio";
    name?: string;
}

export const Switch: React.FC<Props> = (props: Props) => (
    <div className="switch">
        <input
            id={props.id}
            className="switch__input"
            name={props.name}
            type={props.type}
            value={`${props.checked}`}
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
        />
        <label className="switch__slider" htmlFor={props.id} />
    </div>
);

Switch.defaultProps = {type: "checkbox"};
