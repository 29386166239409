// tslint:disable:no-any
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';
// import {State} from 'FullVersion/Store'; TODO: move state away from library
import {CheckErrorPayload, textCheckErrorAction} from "../PadState";
import {initializeUserAction} from "./Actions";

export const logoutNoticeMiddleware: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            if (action.type === textCheckErrorAction.type) {
                const actionPayload = action.payload as CheckErrorPayload;
                const json = JSON.parse(actionPayload.data);

                if (json.errorCode === 'maximum_characters_exceeded') {
                    api.dispatch(initializeUserAction(void(0)));

                    window.location.href = '/user/authenticate?destination=/';
                }
            }

            // Call the next dispatch method in the middleware chain.
            return next(action);
        };
