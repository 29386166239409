import { EditorState, Modifier, SelectionState } from "draft-js";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { advancedDecoratorSpells, decoratorSpell, getBlockDraftSelection } from "../../Util/Draft";
import { hasStorage, loadFromStorage } from "../../Util/Storage";
import { OpenAdviceItemPayload } from "../AdviceState";
import {
    editorChangedAction,
    highlightEntityAction,
    openInlineAdviceItemAction,
    padCrashedAction,
    rerenderDecorationsAction,
    resetPadAction,
    setInlineAdviceTimerAction,
    updateDecoratorAction,
    EditorChangePayload,
    HighlightEntityPayload,
    PadCrashedPayload,
    SetInlineAdviceTimerPayload,
    oneClickEditorChangedAction,
} from "./Actions";
import { OneClickEditorState, State } from "./State";

/**
 * Method to actually mark characterLimit. We need to force draft to go through all blocks one more time
 * @param {State} state
 * @param {EditorState} currentEditorState
 * @returns {State}
 */
const rerenderDecorationsReducer = (state: State, currentEditorState: EditorState): State => {
    if (currentEditorState.getLastChangeType() === "change-inline-style") {
        return state;
    }
    const originalSelection = currentEditorState.getSelection();
    const selection: SelectionState = getBlockDraftSelection(
        currentEditorState,
        0,
        currentEditorState.getCurrentContent().getPlainText().length
    );
    const newState = Modifier.applyInlineStyle(currentEditorState.getCurrentContent(), selection, "BOLD");
    currentEditorState = EditorState.push(currentEditorState, newState, "change-inline-style");
    const newState2 = Modifier.removeInlineStyle(currentEditorState.getCurrentContent(), selection, "BOLD");
    currentEditorState = EditorState.push(currentEditorState, newState2, "change-inline-style");
    currentEditorState = EditorState.forceSelection(currentEditorState, originalSelection);
    return {
        ...state,
        editorState: currentEditorState,
    };
};

const oneClickEditorChangedReducer = (state: State, checkResults: OneClickEditorState): State => {
    return {
        ...state,
        oneClickEditorState: {
            ...state.oneClickEditorState,
            text: checkResults.text,
            errors: checkResults.errors,
        },
    };
};

/**
 * @param {State} state
 * @param {EditorChangePayload} editorChange
 * @returns {State}
 */
const editorChangedReducer = (state: State, editorChange: EditorChangePayload): State => ({
    ...state,
    editorState: editorChange.newEditorState,
});

const resetEditorReducer = (state: State, characterLimit: number): State => ({
    ...state,
    editorState: EditorState.forceSelection(
        EditorState.createEmpty(advancedDecoratorSpells(characterLimit)),
        SelectionState.createEmpty("")
    ),
    openResultEntityKey: "-1",
});

/**
 * @param {State} state
 * @param {HighlightEntityPayload} highlightedSpellError
 * @returns {State}
 */
export const highlightSpellErrorReducer = (state: State, highlightedSpellError: HighlightEntityPayload): State => ({
    ...state,
    highlightedSpellError: {
        entityKey: highlightedSpellError.entityKey,
        previewText: highlightedSpellError.previewText,
    },
});

export const updateDecoratorReducer = (state: State, characterLimit: number): State => ({
    ...state,
    editorState: EditorState.set(state.editorState, { decorator: advancedDecoratorSpells(characterLimit) }),
});

export const openInlineAdviceReducer = (state: State, payload: OpenAdviceItemPayload): State => ({
    ...state,
    openResultEntityKey: payload.entityKey,
});

export const setInlineAdviceTimerReducer = (state: State, payload: SetInlineAdviceTimerPayload | undefined): State => ({
    ...state,
    inlineAdviceTimer: payload,
});

export const padCrashedReducer = (state: State, payload: PadCrashedPayload): State => ({
    ...state,
    crashes: payload.data,
});

export const initialState: State = {
    editorState: hasStorage()
        ? EditorState.moveSelectionToEnd(EditorState.createWithContent(loadFromStorage()!, decoratorSpell))
        : EditorState.forceSelection(EditorState.createEmpty(decoratorSpell), SelectionState.createEmpty("")),
    oneClickEditorState: { text: window.localStorage.getItem("oneClickEditorText") || "", errors: [] },
    highlightedSpellError: {
        entityKey: "",
        previewText: [],
    },
    openResultEntityKey: "",
    inlineAdviceTimer: undefined,
    crashes: {},
};

export const reducer = reducerWithInitialState(initialState)
    .case(editorChangedAction, editorChangedReducer)
    .case(rerenderDecorationsAction, rerenderDecorationsReducer)
    .case(highlightEntityAction, highlightSpellErrorReducer)
    .case(openInlineAdviceItemAction, openInlineAdviceReducer)
    .case(resetPadAction, resetEditorReducer)
    .case(updateDecoratorAction, updateDecoratorReducer)
    .case(setInlineAdviceTimerAction, setInlineAdviceTimerReducer)
    .case(padCrashedAction, padCrashedReducer)
    .case(oneClickEditorChangedAction, oneClickEditorChangedReducer)
    .build();
