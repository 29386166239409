import React from 'react';

import * as SC from './Footer.styled';
import { Link } from '../MetaMenu/MetaMenu';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        _sp_?: { loadPrivacyManagerModal?: (value: number) => void };
    }
}

const linksConfig: Link[] = [
    {
        text: 'Privacy Manager',
        href: '#cmp',
        onClick: (event) => {
            try {
                window._sp_?.loadPrivacyManagerModal?.(883462);
            } catch (_err) {
                console.error('_sp_ internal error!');
            }

            event.preventDefault();

            return false;
        },
    },
    {
        text: 'Datenschutz',
        href: '/datenschutzerklaerung',
    },
    {
        text: 'AGB',
        href: '/agb',
    },
    {
        text: 'Impressum',
        href: '/impressum',
    },
];

const Footer: React.FC = () => <SC.Wrapper>
    <SC.Copyright>&copy; Cornelsen Verlag GmbH, {new Date().getFullYear()}</SC.Copyright>
    <SC.LinksWrapper>
        {linksConfig.map((link, index) => (
            <a key={index} href={link.href} onClick={link.onClick} className="footer-menu-link">{link.text}</a>
        ))}
    </SC.LinksWrapper>
</SC.Wrapper>;

export default Footer;
