import React, {
  forwardRef,
  useCallback,
  useState,
} from 'react';

import * as SC from './Input.styled';
import { ClosedEyeIcon, OpenEyeIcon } from '../Icons';

export enum InputAppearance {
    Default = 'default',
    Disabled = 'disabled',
    Error = 'error',
    Success = 'success',
    ReadOnly = 'read-only',
    Empty = 'empty',
}

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: React.ReactNode;
  message?: React.ReactNode;
  appearance?: InputAppearance;
  hideControls?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      message,
      appearance,
      hideControls,
      onChange,
      ...inputProps
    },
    forwardedRef,
  ) => {
    const isPassword = inputProps.type === 'password';

    const [inputType, setInputType] = useState<InputProps['type']>(
      inputProps.type,
    );

    const id = inputProps.id ?? inputProps.name;

    const handleIconClick: React.MouseEventHandler<HTMLDivElement> =
      useCallback(() => {
        if (isPassword) {
          setInputType((value) => (value === 'password' ? 'text' : 'password'));
        }
      }, [isPassword]);

    return (
      <div>
        {!!label && (
          <SC.Label htmlFor={id}>
            <span>
              {label}
              {inputProps.required && <SC.Sup>*</SC.Sup>}
            </span>
          </SC.Label>
        )}
        <SC.InputWrapper>
          <SC.Input
            {...inputProps}
            type={inputType}
            onChange={onChange}
            hideControls={hideControls}
            hasIcon={isPassword}
            appearance={appearance}
            ref={forwardedRef}
            id={id}
          />
          {isPassword && (
            <SC.InputIcon onClick={handleIconClick}>
              {inputType === 'password' ? <ClosedEyeIcon /> : <OpenEyeIcon />}
            </SC.InputIcon>
          )}
        </SC.InputWrapper>
        {!!message && <SC.Message appearance={appearance}>{message}</SC.Message>}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;