import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';
import {
    textCheckPendingAction,
    textCheckSuccessfulAction,
    textCheckErrorAction,
    CheckSuccessfulPayload, textCheckReceivedAction
} from '../PadState'
import {resetProfilerAction, setProfilerAction} from './Actions';
import {Profile} from './State';
import {textCheckDelay} from '../PadState/PadSaga';

let startDate = 0;

export const profilerMiddleware: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) => (
    (next: Dispatch) => (
        <A extends Action<{}>>(action: A): A => {
            if (document.cookie.indexOf('prfg') === -1) {
                return next(action);
            }
            
            switch (action.type) {
                case textCheckPendingAction.type:
                    handlePendingAction(api.dispatch);
                    break;
                case textCheckReceivedAction.type:
                    handleReceivedAction(api.dispatch);
                    break;
                case textCheckSuccessfulAction.type:
                    action = (handleSuccessfulAction(action, api.dispatch) as A);
                    break;
                case textCheckErrorAction.type:
                    handleErrorAction(api.dispatch);
                    break;
            }
            return next(action);
        }
    )
);

function getDuration(): number {
    return Date.now() - startDate;
}

function handlePendingAction(dispatch: Dispatch): void {
    startDate = Date.now() + textCheckDelay;
    dispatch(resetProfilerAction(void(0)));
}

function handleReceivedAction(dispatch: Dispatch): void {
    dispatch(setProfilerAction({key: 'textCheckReceived', value: {duration: getDuration()}}));
}

function handleSuccessfulAction(action: Action<{}>, dispatch: Dispatch): Action<{}> {
    const profiling = ((action.payload as CheckSuccessfulPayload & Profile).profiling as Profile) as Profile;
    if (!profiling) { return action; }

    dispatch(setProfilerAction({
        key: 'textCheckSuccessful',
        value: {
            ...profiling,
            duration: getDuration(),
            start: startDate,
        }
    }));

    delete (action.payload as CheckSuccessfulPayload & Partial<Profile>).profiling
    return action;
}

function handleErrorAction(dispatch: Dispatch) {
    dispatch(setProfilerAction({request: {duration: getDuration(), start: startDate}}));
}