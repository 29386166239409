import React, { useCallback, useState } from "react";

import { Modal, Checkbox, FormControlLabel } from "@material-ui/core";

import * as SC from "./ExpressConsentModal.styled";
import useOneClickStore from "../../../Hooks/useOneClickStore";
import { Colors } from "../../../Styles";
import { GTMDataLayerPush } from "../../../Util/GoogleTagManager";
import { SquareButton } from "../../Buttons";

type ExpressConsentModalProps = {
    closeOneClickModal: () => void;
    oneClickModal: boolean;
};

const ExpressConsentModal: React.FC<ExpressConsentModalProps> = ({
     closeOneClickModal,
     oneClickModal
 }) => {
    const [checked, setChecked] = useState(false);
    const setIsOneClickModeActive = useOneClickStore((store) => store.setIsOneClickModeActive);
    const setOneClickAgreed = useOneClickStore((store) => store.setOneClickAgreed);

    const handleClose = useCallback(() => {
        GTMDataLayerPush({
            event: "mentor_express_consent",
            option: "closed"
        });
        setIsOneClickModeActive(false);
        closeOneClickModal();
    }, [closeOneClickModal, setIsOneClickModeActive]);

    const handleReject = useCallback(() => {
        GTMDataLayerPush({
            event: "mentor_express_consent",
            option: "rejected"
        });
        setIsOneClickModeActive(false);
        closeOneClickModal();
    }, [closeOneClickModal, setIsOneClickModeActive]);

    const handleApprove = useCallback(() => {
        GTMDataLayerPush({
            event: "mentor_express_consent",
            option: "accepted"
        });
        setOneClickAgreed(checked);
        setIsOneClickModeActive(true);
        closeOneClickModal();
    }, [checked, closeOneClickModal, setIsOneClickModeActive, setOneClickAgreed]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <Modal open={oneClickModal} onClose={handleClose} aria-labelledby="1-click-correction-title">
            <SC.ModalWrapper>
                <SC.ModalHeader>
                    <h2 id="1-click-correction-title">
                        Mentor Express<sup>BETA</sup>
                    </h2>
                    <button onClick={handleClose}>&#10060;</button>
                </SC.ModalHeader>
                <SC.SubTitle>
                    Mit dieser komfortablen Funktion können Sie Ihre Texte KI-gestützt optimieren lassen.
                </SC.SubTitle>

                <SC.TextWrapper>
                    <p>
                        Diese Funktion basiert auf einem externen Service, für den wir auch den
                        Anbieter Microsoft nutzen. Eine gegebenenfalls erteilte Einwilligung zur
                        Nutzung dieses Services können Sie jederzeit mit Wirkung für die Zukunft
                        widerrufen. Weitere Informationen finden Sie in unserer <a href={`${process.env.REACT_APP_DUDENDE_URL}/service/datenschutz#textchecking`}>Datenschutzerklärung</a>.
                    </p>
                    <p>
                        <b>Hinweis:</b> Bitte beachten Sie, dass es bei Nutzung dieser Funktion zu leichten inhaltlichen
                        Änderungen in Ihren Texten kommen kann.
                    </p>
                </SC.TextWrapper>

                <SC.ButtonsWrapper>
                    <SquareButton
                        onClick={handleReject}
                        defaultBackground={Colors.mineShaft}
                        hoverBackground={Colors.mineShaft}
                        defaultColor={Colors.white}
                    >
                        Ablehnen
                    </SquareButton>
                    <SquareButton
                        onClick={handleApprove}
                    >
                        Zustimmen
                    </SquareButton>
                </SC.ButtonsWrapper>

                <SC.CheckboxWrapper>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                name="dontShowModal"
                                color="default"
                            />
                        }
                        label="Fenster nicht mehr anzeigen"
                    />
                </SC.CheckboxWrapper>
            </SC.ModalWrapper>
        </Modal>
    );
};

export default ExpressConsentModal;