import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { apiGetToken } from '../Util/RequestApi';

type AuthData = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
}

type AuthStore = {
    accessToken?: string;
    refreshToken?: string;
    expiresIn?: number;
    refreshTimer?: NodeJS.Timer;

    actions: {
        setAccessToken: (accessToken?: string) => void;
        setRefreshToken: (refreshToken?: string) => void;
        init: (data: AuthData) => void;
        clearTokens: () => void;
    }
};



const useAuthStore = create<AuthStore>()(
    persist(        (set, get) => ({
        accessToken: undefined,
        refreshToken: undefined,

        actions: {
            setAccessToken: (accessToken) => set({
                accessToken,
            }),
            setRefreshToken: (refreshToken) =>
                set({
                    refreshToken,
                }),
            init: ({ accessToken, refreshToken, expiresIn }) => {
                const { refreshTimer } = get();

                if (refreshTimer) clearTimeout(refreshTimer);

                const nextRefreshTimer = setTimeout(() => {
                    void apiGetToken();
                }, Math.max(0, expiresIn - 10000));

                set({
                    accessToken,
                    refreshToken,
                    expiresIn,
                    refreshTimer: nextRefreshTimer,
                })
            },
            clearTokens: () =>
                set({
                    accessToken: undefined,
                    refreshToken: undefined,
                }),
        }
    }), {
        name: 'AuthData',
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => ({
            accessToken: state.accessToken,
            refreshToken: state.refreshToken,
        }),
    })
);

export const getAccessToken = () => useAuthStore.getState().accessToken;

export const getRefreshToken = () => useAuthStore.getState().refreshToken;

export default useAuthStore;