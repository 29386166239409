import * as React from 'react';

export const InstagramIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <title>Instagram</title>
        <defs>
            <linearGradient id="236e2d4hna" x1="58.752%" x2="29.53%" y1="-5.299%" y2="91.245%">
                <stop offset="0%" stopColor="#515BD4"/>
                <stop offset="26%" stopColor="#9510B8"/>
                <stop offset="66%" stopColor="#E51804"/>
                <stop offset="100%" stopColor="#FEDA77"/>
            </linearGradient>
            <linearGradient id="i21b9hwkqb" x1="67.044%" x2="10.137%" y1="-57.687%" y2="130.319%">
                <stop offset="0%" stopColor="#515BD4"/>
                <stop offset="26%" stopColor="#9510B8"/>
                <stop offset="66%" stopColor="#E51804"/>
                <stop offset="100%" stopColor="#FFBF00"/>
            </linearGradient>
            <linearGradient id="ps0fqw2xvc" x1="-99.482%" x2="-343.001%" y1="-188.399%" y2="616.113%">
                <stop offset="0%" stopColor="#515BD4"/>
                <stop offset="26%" stopColor="#9510B8"/>
                <stop offset="66%" stopColor="#E51804"/>
                <stop offset="100%" stopColor="#FFBF00"/>
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M0 0H36V36H0z" transform="translate(-928 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48)"/>
            <g fillRule="nonzero">
                <path fill="url(#236e2d4hna)"
                      d="M15 0c-4.074 0-4.585.017-6.185.09-1.596.073-2.687.327-3.64.698-.987.383-1.824.896-2.657 1.73-.834.834-1.347 1.67-1.73 2.656C.416 6.128.162 7.22.09 8.815.017 10.415 0 10.926 0 15s.017 4.584.09 6.184c.073 1.597.327 2.687.697 3.641.384.987.897 1.823 1.73 2.657.834.834 1.671 1.347 2.657 1.73.954.371 2.045.625 3.641.698 1.6.073 2.111.09 6.185.09s4.584-.017 6.184-.09c1.597-.073 2.687-.327 3.641-.698.987-.383 1.823-.896 2.657-1.73.834-.834 1.347-1.67 1.73-2.657.371-.954.625-2.044.698-3.64.073-1.6.09-2.111.09-6.185s-.017-4.585-.09-6.185c-.073-1.596-.327-2.687-.698-3.64-.383-.987-.896-1.823-1.73-2.657-.834-.834-1.67-1.347-2.657-1.73-.954-.371-2.044-.625-3.64-.698C19.584.017 19.073 0 15 0zm0 2.703c4.005 0 4.48.015 6.061.087 1.463.067 2.257.311 2.785.517.7.272 1.2.597 1.725 1.122.525.525.85 1.024 1.122 1.724.206.529.45 1.323.517 2.786.072 1.581.087 2.056.087 6.061s-.015 4.48-.087 6.061c-.067 1.463-.311 2.257-.517 2.785-.272.7-.597 1.2-1.122 1.725-.525.525-1.024.85-1.725 1.122-.528.206-1.322.45-2.785.517-1.581.072-2.056.087-6.061.087-4.006 0-4.48-.015-6.061-.087-1.463-.067-2.257-.311-2.786-.517-.7-.272-1.2-.597-1.724-1.122-.525-.525-.85-1.024-1.122-1.725-.206-.528-.45-1.322-.517-2.785-.072-1.581-.087-2.056-.087-6.061s.015-4.48.087-6.061c.067-1.463.311-2.257.517-2.786.272-.7.597-1.2 1.122-1.724.525-.525 1.024-.85 1.724-1.122.529-.206 1.323-.45 2.786-.517 1.581-.072 2.056-.087 6.061-.087z"
                      transform="translate(-928 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(3 3)"/>
                <path fill="url(#i21b9hwkqb)"
                      d="M15 20c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5zm0-12.703c-4.254 0-7.703 3.449-7.703 7.703 0 4.254 3.449 7.703 7.703 7.703 4.254 0 7.703-3.449 7.703-7.703 0-4.254-3.449-7.703-7.703-7.703z"
                      transform="translate(-928 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(3 3)"/>
                <path fill="url(#ps0fqw2xvc)" d="M24.807 6.993c0 .994-.806 1.8-1.8 1.8s-1.8-.806-1.8-1.8.806-1.8 1.8-1.8 1.8.806 1.8 1.8z"
                      transform="translate(-928 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(3 3)"/>
            </g>
        </g>
    </svg>
);
