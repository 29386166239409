import * as React from "react";

export const ChevronUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M5.572 16.017c-.289-.289-.311-.743-.067-1.056l.067-.076 6.222-6.222 6.223 6.222c.312.313.312.82 0 1.132-.289.288-.743.31-1.056.066l-.076-.066-5.091-5.09-5.091 5.09c-.288.288-.742.31-1.056.066l-.075-.066z"
        />
    </svg>
);
