// tslint:disable:no-any
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';
// import {State} from 'FullVersion/Store'; TODO: move redux away from library
import {undoHistoryAction} from '../AdviceState';
import {addDictionaryEntrySuccessfulAction} from '../DictionaryState';
import {checkResultsExternallyChangedAction, correctErrorAction, ignoreErrorAction} from './Actions';

export const triggerTextCheckMiddleware: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            // tslint:disable-next-line:prefer-switch
            if (action.type === addDictionaryEntrySuccessfulAction.type
                || action.type === ignoreErrorAction.type
                || action.type === correctErrorAction.type
                || action.type === undoHistoryAction.type
            ) {
                api.dispatch(
                    checkResultsExternallyChangedAction({
                        text: api.getState().padState.editorState.getCurrentContent().getPlainText(),
                    }),
                );
            }

            return next(action);
        };
