import React from "react";

export const ArrowDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg {...props} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icons/new/dd_down">
            <path id="Path 13" fillRule="evenodd" clipRule="evenodd" d="M8.91602 12.2441L15.0572 18.3853L21.1609 12.2816L8.91602 12.2441Z" fill="#333333"/>
        </g>
    </svg>

);
