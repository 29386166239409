import * as React from 'react';

import {Editor, EditorState} from 'draft-js';
import {connect} from 'react-redux';

import {
    padCrashedAction,
    selectEditorState,
} from '../Store/PadState';

/**
 * Interface for Component State
 */
interface OwnState {
    hasErrors: boolean;
}

/**
 * * Interface for Component Props
 */
interface DispatchProps {
    editorState: EditorState;
}

interface DispatchActions {
    onCrash: typeof padCrashedAction;
}

export type OwnProps = DispatchProps & DispatchActions;

/**
 * This class wraps react errors, so that the whole app won't break
 */
export class PadError extends React.Component<React.PropsWithChildren<OwnProps>, OwnState> {

    public constructor(props: OwnProps) {
        super(props);

        this.state = {
            hasErrors: false,
        };
    }
    /**
     * When this method exists errors will be caught
     * @param error
     * @param info
     */
    public componentDidCatch(error: Error, info: React.ErrorInfo): void  {
        console.error('A rendering error was caught', error);
        this.setState({
            hasErrors: true,
        });
    }

    /**
     * Renders error message when a severe React Dom Error was caught. This should actually never happen.
     * @returns {JSX.Element}
     */
    public render(): JSX.Element {
        // App crashed.
        if (this.state.hasErrors) {
            this.props.onCrash({
                data: 'Pad Crashed Error',
            });

            return (
            <>
                <div
                    id="pad-panel"
                    className={'pad pad--length_overwhelmingly-long'}
                >
                    <div
                        className="pad__writable"
                    >
                        <Editor
                            editorState={this.props.editorState}
                            onChange={doNothing}
                            readOnly={true}
                        />
                    </div>
                </div>
            </>
            );
        }
        return (<>{this.props.children}</>);
    }
}

// We deliberately don't want anything to happen in the onChange call.
// Using lambdas in the return part of the render function is frowned upon by tslint because of its
// performance impact :(
const doNothing = () => {
    return;
};

// TODO: FullVersion State no longer exists is resolved, when converted to functional component
// tslint:disable-next-line:no-any
const mapStateToProps = (store: any): DispatchProps => ({
    editorState: selectEditorState(store),
});

const dispatchMap: DispatchActions = {
    onCrash: padCrashedAction,
};

export const PadErrorWrapper = connect(mapStateToProps, dispatchMap)(PadError);
