import * as React from 'react';
import {useEffect, useState} from "react";

import {useSelector} from 'react-redux';

import {selectUserFeatures} from '../../Store/UserState';

declare var dataLayer: any[];

export const Optimize: React.FC = () => {
    const userFeatures = useSelector(selectUserFeatures);
    const [dataLayerPushed, setDataLayerPushed] = useState(false)

    useEffect(
        () => {
            if (!dataLayerPushed) {
                if (typeof(dataLayer) !== 'undefined') {    
                    dataLayer.push({ event: "optimize.activate" });
                    setDataLayerPushed(true);
                }
            }
        },
        [userFeatures]
    );

    // tslint:disable-next-line:no-null-keyword
    return null;
};