import { convertFromRaw, convertToRaw, ContentState, EditorState } from "draft-js";

import { DocumentIdentifier } from "./DocumentIdentifier";

export function hasStorage(): boolean {
    // Depending on third-party cookie browser settings accessing local and session storage from an iFrame will
    // throw an exception, which we need to catch so everything still works in the browser extensions.
    try {
        return canWriteStorage() && !!window.sessionStorage.getItem("contentState");
    } catch (e) {
        return false;
    }
}

export function canWriteStorage(): boolean {
    return typeof Storage !== "undefined";
}

export function saveEditorContentToStorage(editorState: EditorState): void {
    saveToStorage(editorState.getCurrentContent(), DocumentIdentifier.get());
}

export function saveToStorage(contentState: ContentState, documentId: string): void {
    if (!canWriteStorage()) {
        return;
    }

    const rawContentState = convertToRaw(contentState);
    window.sessionStorage.setItem("contentState", JSON.stringify(rawContentState));
    window.sessionStorage.setItem("documentId", documentId);
}

export function loadFromStorage(): ContentState | undefined {
    if (!hasStorage()) {
        return;
    }
    const rawContentState = JSON.parse(window.sessionStorage.getItem("contentState")!);
    const documentId = window.sessionStorage.getItem("documentId");
    if (!rawContentState) {
        return;
    }
    if (documentId) {
        DocumentIdentifier.set(documentId);
    }

    return convertFromRaw(rawContentState);
}

export function loadClassicTextFromStorage(): string | undefined {
    if (!hasStorage()) {
        return;
    }
    const textStateToBeRestored = convertFromRaw(
        JSON.parse(window.sessionStorage.getItem("contentState")!)
    ).getPlainText();

    return textStateToBeRestored;
}