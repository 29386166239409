import styled from "styled-components";

export const ExpressCharsRemaining = styled.div`
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
`

export const CheckoutContent = styled.div`
    font-weight: bold;

    & p {
        margin: 0;
    }
`

export const CheckoutInfo = styled.p`
    padding-top: 5px;
    font-weight: normal;
`

export const CheckoutCta = styled.a`
    padding-top: 15px;
    display: flex;
    text-decoration: none;
`