import styled from "styled-components";

import { Colors } from "../../../Styles";

export const Advise = styled.mark<{ isStyle: boolean }>`
    background-color: initial;
    background-image: linear-gradient(0deg, ${({ isStyle }) => isStyle ? Colors.periwinkle : Colors.supernova} 48%, transparent 0);
    background-size: 100% 300%;
    background-position: 50%;
    transition: background-position .25s;
    cursor: pointer;
    
    & > span {
        pointer-events: none;
    }

    &:hover {
        background-position: center bottom
    }

    &[suggestion] {
        & > * {
            display: none;
        }
    }

    &[suggestion]:before {
        content: attr(suggestion);
        line-height: normal;
        display: inline;
    }
`;
