import {PlatformTypes} from './PlatformUtil';

export class DocumentIdentifier {

    public static get(): string {
        let docId = DocumentIdentifier.getInstance().getDocId();

        if (DocumentIdentifier.platformIdentifier && !docId.includes(DocumentIdentifier.platformIdentifier)) {
            docId = `${DocumentIdentifier.platformIdentifier}-${docId}`;
        }

        return docId;
    }

    public static reset(): void {
        DocumentIdentifier.instance = new DocumentIdentifier();
    }

    public static set(documentId: string): void {
        DocumentIdentifier.instance = new DocumentIdentifier(documentId);
    }

    public static getPlatformIdentifier(): PlatformTypes {
        return DocumentIdentifier.platformIdentifier;
    }

    public static setPlatformIdentifier(platformIdentifier: PlatformTypes): void {
        DocumentIdentifier.platformIdentifier = platformIdentifier;
    }

    private static instance: DocumentIdentifier;
    private static platformIdentifier: PlatformTypes;

    private static getInstance(): DocumentIdentifier {
        if (DocumentIdentifier.instance === undefined) {
            DocumentIdentifier.instance = new DocumentIdentifier();
        }
        return DocumentIdentifier.instance;
    }

    private static makeId(): string {
        const chars = [];
        const candidates = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < 10; i++) {
             chars.push(candidates.charAt(Math.floor(Math.random() * candidates.length)));
        }
        return `${new Date().valueOf().toString()}-${chars.join('')}`;
    }

    private docId: string;

    private constructor(documentId?: string) {
        this.docId = documentId ? documentId : DocumentIdentifier.makeId();
     }

    private getDocId(): string {
        return this.docId;
    }

}
