import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { initHideStyleAdviceAction, initializeUserAction, selectUserStateFromMainState, UserRole } from '../../Store/UserState';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';

export const User: React.FC<React.PropsWithChildren<{}>> = (props: React.PropsWithChildren<{}>) => {
    let componentMounted = useRef(false);
    let userStateReceived = useRef(false);
    const dispatch = useDispatch();
    const userState = useSelector(selectUserStateFromMainState);

    /** initialize User Store */
    useLayoutEffect(() => {
        dispatch(initializeUserAction(void(0)));
        dispatch(initHideStyleAdviceAction(void(0)));
    }, []);

    /** send datalayer push, only when user state was initial loaded */
    useEffect(() => {
        if (!componentMounted.current || userStateReceived.current) {
            componentMounted.current = true;
            return;
        }

        userStateReceived.current = true;

        if (!userState.roles) {
            return;
        }

        GTMDataLayerPush({
            event: "user_status",
            role: userState.roles ?? [UserRole.Gast],
            hasUsedTrial: userState.hasUsedTrial,
            hasHadPremium: userState.hasHadPremium,
            trialDaysRemaining: userState.trialDaysRemaining,
            characterLimit: userState.characterLimit,
        });
    }, [userState.roles, userState.hasUsedTrial, userState.hasHadPremium, userState.trialDaysRemaining, userState.characterLimit]);

    /** use memo here to prevent re-rendering when children weren't changed */
    return useMemo(() => <>{props.children}</>, [props.children]);
};