import actionCreatorFactory from "typescript-fsa";

import { AdviceType } from "./State";
import { AdviceData, HistoryProps } from "../../Components/Advices/Util/Props";

export interface ResetStatePayload {
    spellAdvices: AdviceData[];
    styleAdvices: AdviceData[];
    adviceHistory: HistoryProps[];
}

export interface OpenAdviceItemPayload {
    entityKey: string;
}

export interface HighlightSpellErrorPayload {
    entityKey: string;
}

export interface UserAdviceInteractionPayload {
    type: string;
    completeText: string;
    suggestedChangeOriginalText: string;
    suggestedChangeOffset: number;
    suggestedChangeErrorCode: string;
    suggestedChangeErrorType: string;
    suggestedChangeAcceptedText?: string;
    suggestedChangeReportCode?: string;
    suggestedChangeAcceptedPosition?: number;
}

export interface SetSelectedAdviceItemPayload {
    index: number;
    adviceType: AdviceType;
}

const adviceInteractionCreator = actionCreatorFactory("ADVICE");
export const undoHistoryAction = adviceInteractionCreator<HistoryProps>("UNDO_HISTORY");
export const resetStateAction = adviceInteractionCreator<ResetStatePayload>("RESET_STATE");
export const resetAdviceAction = adviceInteractionCreator<void>("RESET_ADVICE");
export const rejectSynonymsAction = adviceInteractionCreator<number>("SYNONYM_REJECT");
export const setSelectedAdviceItemAction = (
    adviceInteractionCreator<SetSelectedAdviceItemPayload | undefined>("SET_SELECTED_ADVICE_ITEM")
);
