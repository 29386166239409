import * as React from 'react';
import {useState} from "react";

import {Profile} from '../../Store/ProfilerState';

type ProfilerDataRowProps = {
    maxDuration: number;
    firstStart: number;
    title: string;
    duration: number;
    start: number;
    display: boolean;
    depth: number;
    children?: Profile;
};

export const ProfilerDataRow: React.FC<ProfilerDataRowProps> = (props) => {
    const [displayChildren, setDisplayChildren] = useState(props.depth < 2);
    const width = props.duration * 100 / props.maxDuration;
    const marginLeft = (props.start - props.firstStart) * 100 / props.maxDuration;

    const children = Object.keys(props.children || {}).filter((key) => key !== 'duration' && key !== 'start').map((key) => {
        const child = props.children![key];

        return (
            <ProfilerDataRow
                children={child}
                depth={props.depth + 1}
                display={props.display && displayChildren}
                duration={typeof child === 'number' ? child : child.duration}
                start={typeof child === 'number' || !child.start ? props.start : child.start}
                maxDuration={props.maxDuration}
                firstStart={props.firstStart}
                title={key}
            />
        );
    });

    return (
        <>
            <tr
                style={{display: props.display ? '' : 'none', cursor: children.length > 0 ? 'pointer' : 'unset'}}
                onClick={() => setDisplayChildren(!displayChildren)}
            >
                <th style={{paddingLeft: 15 + (props.depth * 15)}}>
                    {
                        children.length > 0 &&
                        <span
                            className="profiler__data-opener"
                            style={{transform: `rotate(${displayChildren ? 90 : 0}deg)`, left: (props.depth * 15)}}
                        >
                            ▶️
                        </span>
                    }
                    {props.title}
                </th>
                {typeof props.duration === 'undefined' ? (
                    <td colSpan={2} />
                ):(
                    <>
                        <td title={`${width}%`}>
                            <div
                                className="profiler__duration-indicator"
                                style={{minWidth: props.duration > 0 ? 1 : 0, width: `${width}%`, marginLeft: `${marginLeft}%`}}
                            />
                        </td>
                        <td style={{textAlign: 'right'}}>{props.duration}&nbsp;ms</td>
                    </>
                )}
            </tr>
            {children}
        </>
    );
}