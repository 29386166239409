import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { apiFetch } from '../../Util/RequestApi';
import { Extensions } from '../../Views/Extensions';
import { Skyscraper } from '../Ads/Skyscraper';
import { YieldLoveScript } from '../Ads/YieldLoveScript';
import { AlertSpace } from '../AlertSpace/AlertSpace';
import { Footer, MarketingFooter } from '../Footer';
import { Header, Menu, MenuItemProps } from '../Header';
import '../../Styles/layout/marketing-desk.sass';
import { Optimize } from '../Optimize/Optimize';


export type MarketingMenuEntry = {
    title: string;
    url: string;
    children: MarketingMenuEntry[];
}

export const MarketingDesk: React.FC = () => {
    const [menuEntries, setMenuEntries] = useState<MenuItemProps[]>([]);

    const mapMarketingMenuEntry = (entries: MarketingMenuEntry[]): MenuItemProps[] => (
        entries.map((entry) => ({
            children: mapMarketingMenuEntry(entry.children),
            href: entry.url,
            target: '_blank',
            text: entry.title,
        }))
    );

    const trackTrialLinkClick = () => GTMDataLayerPush({
        event: 'premium_touchpoint',
        option: 'trial_link_headline',
    });

    useEffect(() => {
        const headerDescription = document.querySelector('.marketing-headline');
        const trialLinkHeadline = headerDescription?.querySelector('a');

        if (trialLinkHeadline) {
            trialLinkHeadline.addEventListener('click', trackTrialLinkClick);

            return () => trialLinkHeadline.removeEventListener('click', trackTrialLinkClick);
        }

        return () => {
        };
    }, []);

    useLayoutEffect(() => {
        apiFetch('api/duden-marketing-menus/marketing-top')
            .then((response: Response) => {
                if (response.status !== 200) {
                    return [];
                }

                return response.json();
            })
            .then((json: MarketingMenuEntry[]) => setMenuEntries(mapMarketingMenuEntry(json)))
            .catch((e) => console.error(e));
    }, []);

    return (
        <>
            <Header>
                <Menu entries={menuEntries} className="main-navigation" />
                <AlertSpace />
            </Header>
            <Switch>
                <Route path="/" exact>
                    <main className="marketing-main">
                        <div className="marketing-main__content">
                            <div className="marketing-headline">
                                <h1>Mehr Erfolg durch<br />bessere Texte</h1>
                                <h2>Der Duden-Mentor ist die Textprüfung von Duden.<br />Er optimiert Ihre Rechtschreibung, Grammatik, Zeichensetzung und Ihren Stil.</h2>
                            </div>
                            <div className="marketing-desk">
                                <iframe title="Lite" src="/lite/?location=dm" frameBorder="0" width="100%" height="100%" />
                            </div>
                        </div>
                        <aside className="marketing-ads--skyscraper">
                            <Skyscraper />
                        </aside>
                    </main>
                </Route>
                <Route path="/extensions" component={Extensions} />
            </Switch>

            <MarketingFooter />
            <Footer />

            <YieldLoveScript />
            <Optimize />
        </>
    );
};
