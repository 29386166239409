import React from 'react';

import {DictionaryPanel} from "../../Components/Dictionary/DictionaryPanel";
import { MetaMenu } from '../../Components/MetaMenu/MetaMenu';
import {ErrorWrapper} from "../../Util/ErrorWrapper";

const Dictionary: React.FC = () => (
    <>
        <ErrorWrapper>
            <DictionaryPanel/>
        </ErrorWrapper>

        <MetaMenu />
    </>
);

export default Dictionary;
