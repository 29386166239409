import * as React from 'react';

export const YouTube: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <title>YouTube</title>
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M0 0H36V36H0z" transform="translate(-1081 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(153)"/>
            <g fillRule="nonzero">
                <path fill="red"
                      d="M32.31 3.65c-.38-1.428-1.497-2.554-2.917-2.936C26.819.02 16.5.02 16.5.02S6.18.02 3.607.714C2.187 1.096 1.07 2.222.69 3.651 0 6.24 0 11.645 0 11.645s0 5.405.69 7.995c.38 1.429 1.497 2.507 2.917 2.89 2.574.693 12.893.693 12.893.693s10.32 0 12.893-.694c1.42-.382 2.538-1.46 2.917-2.89.69-2.59.69-7.994.69-7.994s0-5.404-.69-7.994z"
                      transform="translate(-1081 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(153) translate(2 7)"/>
                <path fill="#FFF" d="M13.125 16.552L13.125 6.738 21.75 11.645z" transform="translate(-1081 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(153) translate(2 7)"/>
            </g>
        </g>
    </svg>
);
