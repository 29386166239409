import React from "react";

import * as SC from "./AdviceHeader.styled";
import { Colors } from "../../../Styles";
import { RoundButton } from "../../Buttons";
import { ArrowLeftIcon, ArrowRightIcon } from "../../Icons";
import { Tooltip } from "../../Tooltip/Tooltip";

type AdviceHeaderProps = {
    adviceType: "SPELL-ERROR" | "STYLE-ERROR";
    index: number;
    length: number;
    onClickNext: React.MouseEventHandler<HTMLButtonElement>;
    onClickPrevious: React.MouseEventHandler<HTMLButtonElement>;
}

const AdviceHeader: React.FC<AdviceHeaderProps> = (props) => (
    <>
        <SC.HiddenTitle>Gefundene Fehler</SC.HiddenTitle>
        <SC.AdviceHeader counterColor={props.adviceType === "SPELL-ERROR" ? Colors.supernova : Colors.melrose}>
            {props.adviceType === "SPELL-ERROR" ? "Rechtschreibung und Grammatik" : "Stil und Vokabular"}
        </SC.AdviceHeader>
        <SC.Navigation>
                <span>
                    <strong>{props.index + 1}</strong> / {props.length}
                </span>
            <div className="button-navigation">
                {props.index > 0 ? (
                    <Tooltip direction="west" text="vorheriger Vorschlag">
                        <RoundButton onClick={props.onClickPrevious} defaultBackground={Colors.white}>
                            <ArrowLeftIcon />
                        </RoundButton>
                    </Tooltip>) : (
                    <RoundButton disabled onClick={props.onClickPrevious} defaultBackground={Colors.white}>
                        <ArrowLeftIcon />
                    </RoundButton>
                )}
                {props.index < props.length - 1 ? (
                    <Tooltip direction="west" text="nächster Vorschlag">
                        <RoundButton onClick={props.onClickNext} defaultBackground={Colors.white}>
                            <ArrowRightIcon />
                        </RoundButton>
                    </Tooltip>) : (
                    <RoundButton disabled onClick={props.onClickNext} defaultBackground={Colors.white}>
                        <ArrowRightIcon />
                    </RoundButton>
                )}
            </div>
        </SC.Navigation>
    </>
);

export default AdviceHeader;