import styled, { css } from 'styled-components';

import { MediaBreakpoints } from '../../Styles';

export const Desk = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;

  @media screen and ${MediaBreakpoints.medium} {
    overflow: hidden;
  }

  @media screen and ${MediaBreakpoints.wide} {
    flex-direction: row;
    width: auto;
  }

  footer {
    flex: 0 0 auto;
  }
`;

export const DeskContent = styled.div<{viewMode: boolean}>`
    flex: 1 0 auto;
    position: relative;
    max-height: calc(100vh - 400px);
    padding-top: ${({ viewMode }) => viewMode ? 0 : '25px'};

    @media screen and ${MediaBreakpoints.medium} {
        max-height: 340px;
        padding-top: unset;
    }

    @media screen and ${MediaBreakpoints.wide} {
        padding-top: unset;
        max-height: 100%;
        height: calc(100% - 79px);
    }
    
    ${({viewMode}) => viewMode && css`
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 60px;
            bottom: 0;
            background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
            pointer-events: none;
        }
    `}}
`;

export const Editor = styled.div<{ expressMode?: boolean }>`
    display: flex;
    flex-direction: column;
    margin: ${({ expressMode }) => expressMode ? 0 : 25}px 20px 0;
    flex: 1 0 40vh;

    @media screen and ${MediaBreakpoints.medium} {
        margin: ${({ expressMode }) => expressMode ? 0 : 70}px 25px 0 100px;
        flex: 1 0 40%;
        max-height: 405px;
    }

    @media screen and ${MediaBreakpoints.wide} {
        margin: ${({ expressMode }) => expressMode ? 0 : 70}px 25px 0;
        max-height: 100%;
    }
`;

export const RightColumnWrapper = styled.div`
    position: relative;
    background: ${(p: { columnColor: string }) => p.columnColor};
    flex: 0 1 35%;

    [role="presentation"] {
        position: relative !important;
    }

    @media screen and ${MediaBreakpoints.medium} {
        min-height: calc(100vh - 490px);
        //max-height: calc(100vh - 505px);
        height: 100%;
        flex-grow: 1;
    }

    @media screen and ${MediaBreakpoints.wide} {
        max-height: 100%;
        padding-bottom: 25px;
        flex-grow: 0;
    }
`;

export const AdviceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 96%;
    overflow: visible;
    padding: 20px 10px 0;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    @media screen and ${MediaBreakpoints.medium} {
        overflow: auto;
        padding: 25px 80px 0;
    }

    @media screen and ${MediaBreakpoints.wide} {
        padding: 20px 25px 0;
    }
`;

export const StyledLoadingIcon = styled.div`
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;

    @media screen and ${MediaBreakpoints.medium} {
        top: 70px;
    }

    @media screen and ${MediaBreakpoints.wide} {
        top: 90px;
    }
`;