import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import * as SC from './CorrectionAdvice.styled';
import { useCorrectionAdvice } from '../../Hooks/useCorrectionAdvice';
import useCorrectionStore, { ADVICE_TYPE, NormalizedAdvice, selectAdvices } from '../../Hooks/useCorrectionStore';
import { selectUserStateFromMainState } from '../../Store/UserState';
import { Colors, Fonts } from '../../Styles';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { hasFeature } from '../../Util/UserUtils';
import { CardType } from '../Advice/Advice';
import { truncateText } from '../Advice/Advice/Advice';
import { AdviceCard } from '../Advice/AdviceCard';
import { AdviceCardTitle } from '../Advice/AdviceCardTitle';
import { AdviceHeader } from '../Advice/AdviceHeader';
import { AdviceInformation } from '../Advice/AdviceInformation';
import { SettingsInformation } from '../Advice/SettingsInformation';
import { RoundButton } from '../Buttons';
import { AcceptAllIcon, AcceptIcon, DictionaryIcon } from '../Icons';
import { Pill } from '../Pill';
import { Tooltip } from '../Tooltip/Tooltip';

type CorrectionAdviceProps = {
    data: NormalizedAdvice;
};

const CorrectionAdvice: React.FC<CorrectionAdviceProps> = ({ data }) => {
    const [openedCard, setOpenedCard] = useState<undefined | CardType>(undefined);
    const activeIndex = useCorrectionStore(store => store.activeIndex);
    const handleSetActiveIndex = useCorrectionStore(store => store.handleSetActiveIndex);
    const correctionMode = useCorrectionStore(store => store.correctionMode);
    const [spellAdvices, styleAdvices] = useCorrectionStore(selectAdvices);

    const advices = correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

    const {
        canAddToDictionary,
        isMultiple,
        isAcceptable,
        accept,
        reject,
        addToDictionary,
        showSuggestion,
        hideSuggestion,
        hasSettingsInformation,
    } = useCorrectionAdvice(data);

    // @see https://jira.cornelsen.de/browse/DUMEN-492
    const user = useSelector(selectUserStateFromMainState);
    const debugErrorCodes = hasFeature('debugErrorCodes', user.features);

    const onClickReject = useCallback(() => {
        GTMDataLayerPush({ event: 'text-advice', option: 'rejected', location: 'advice-panel', 'advice-type': data.adviceType });
        reject();
    }, [data.id, data.adviceType]);

    useEffect(() => {
        const keyPressHandler: (this: Window, ev: KeyboardEvent) => any = (event) => {
            if (!window._is_advise_highlighted && event.ctrlKey && event.keyCode === 73) {
                event.preventDefault();
                onClickReject();
            }
        };

        window.addEventListener('keydown', keyPressHandler);

        return () => {
            window.removeEventListener('keydown', keyPressHandler);
        };
    }, [data.id, onClickReject]);

    const toggleCard = (event: React.MouseEvent<HTMLDetailsElement>, value: CardType) => {
        event.preventDefault();
        if (value) {
            GTMDataLayerPush({ event: 'open-card', 'advice-type': data.adviceType, location: 'advice-panel', card: value });
        }
        setOpenedCard(value === openedCard ? undefined : value);
    };

    const onClickAccept = (alternative: string) => {
        GTMDataLayerPush({ event: 'text-advice', option: 'accepted', location: 'advice-panel', 'advice-type': data.adviceType });
        accept(alternative);
    };

    const onClickAlternatives = (alternative: string) => {
        GTMDataLayerPush({ event: 'select-alternative', 'advice-type': data.adviceType, location: 'advice-panel' });
        accept(alternative);
    };

    const onClickDictionary = () => {
        GTMDataLayerPush({ event: 'save-in-dictionary', location: 'advice-panel', 'advice-type': data.adviceType });
        addToDictionary();
    };

    const handleClickPreviousAdvice = useCallback(() => {
        handleSetActiveIndex({ shiftValue: -1 });
    }, [handleSetActiveIndex]);

    const handleClickNextAdvice = useCallback(() => {
        handleSetActiveIndex({ shiftValue: 1 });
    }, [handleSetActiveIndex]);

    return (
        <SC.Wrapper>
            <AdviceCard alwaysOpen={true}>
                <AdviceCardTitle onClick={(e) => e.preventDefault()}>
                    <AdviceHeader
                        adviceType={data.adviceType === ADVICE_TYPE.Spelling ? 'SPELL-ERROR' : 'STYLE-ERROR'}
                        index={activeIndex}
                        length={advices.length}
                        onClickNext={handleClickNextAdvice}
                        onClickPrevious={handleClickPreviousAdvice}
                    />
                </AdviceCardTitle>

                <>
                    <SC.OriginalError>
                        <SC.TextTruncate>
                            <span className={classNames('original-error', {
                                style: data.type === 'style',
                            })}>{truncateText(data.originalError)}</span>
                        </SC.TextTruncate>

                    </SC.OriginalError>
                </>
                {isAcceptable && (
                    <p style={{ fontFamily: Fonts.theinhardt, fontSize: 18, margin: '5px 0', paddingBottom: 10 }}>
                        {data.shortMessage}
                    </p>
                )}
                {(debugErrorCodes) && (
                    <p style={{ color: Colors.silver }}>
                        (Fehlercode: <a href={`/admin/error_message/list?name=${data.errorCode}`} target="_blank" rel="noreferrer">{data.errorCode}</a>)
                    </p>
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 13, paddingTop: 13, borderTop: `1px solid ${Colors.iron}` }}>
                    {isAcceptable ? (
                            data.proposals.length === 0 ?
                                <SC.StyledProposal
                                    className="advice__proposal"
                                    onClick={() => accept(' ')}
                                    onMouseEnter={() => showSuggestion(' ')}
                                    onMouseLeave={hideSuggestion}
                                >
                                    <del>{data.originalError}</del>
                                </SC.StyledProposal> :
                                <SC.StyledProposal
                                    className="advice__proposal"
                                    onClick={() => accept(data.proposals[0])}
                                    onMouseEnter={() => showSuggestion(data.proposals[0])}
                                    onMouseLeave={hideSuggestion}
                                >
                                    {data.proposals[0]}
                                </SC.StyledProposal>) :
                        <SC.StyledLabel className="advice__label">
                            {data.shortMessage.trim()}
                            {hasSettingsInformation && <SettingsInformation />}
                        </SC.StyledLabel>}

                    <div className="button-navigation">
                        {isAcceptable && (
                            <Tooltip direction="west" text={isMultiple ? 'Alle Vorschläge übernehmen' : 'Vorschlag übernehmen'}>
                                <RoundButton
                                    onClick={() => onClickAccept(data.proposals[0] || ' ')}
                                    onMouseEnter={() => showSuggestion(data.proposals[0])}
                                    onMouseLeave={hideSuggestion}
                                    defaultBackground={Colors.supernova}
                                    hoverBackground={Colors.pastelGreen}
                                    iconHoverColor={Colors.white}
                                >
                                    {isMultiple ? <AcceptAllIcon /> : <AcceptIcon />}
                                </RoundButton>
                            </Tooltip>
                        )}
                    </div>
                </div>

                {data.proposals.length > 1 && (
                    <SC.AdditionalReplacements>
                        <h3>Alternativen</h3>
                        {data.proposals.slice(1).slice(0, 10)
                            .map((alternative, index) => (
                                <Pill
                                    key={index}
                                    onClick={() => onClickAlternatives(alternative)}
                                    onMouseEnter={() => showSuggestion(alternative)}
                                    onMouseLeave={hideSuggestion}
                                >{alternative}</Pill>
                            ))
                        }
                    </SC.AdditionalReplacements>
                )}

                <SC.StyledAdvicePanelIgnoreButton onClick={onClickReject}>
                    Ignorieren&nbsp;<span>(Strg/Ctrl + i)</span>
                </SC.StyledAdvicePanelIgnoreButton>

                {canAddToDictionary && (
                    <SC.StyledAdvicePanelButton onClick={onClickDictionary}>
                        <DictionaryIcon /> In meinem Wörterbuch speichern
                    </SC.StyledAdvicePanelButton>
                )}
            </AdviceCard>

            {data.errorMessage && (
                <AdviceCard open={openedCard === 'examples'}>
                    <AdviceCardTitle isExpandable onClick={(e) => toggleCard(e, 'examples')}>Erklärung und Beispiele</AdviceCardTitle>
                    <AdviceInformation dangerouslySetInnerHTML={{ __html: data.errorMessage }} />
                </AdviceCard>
            )}

            {(data.additionalInformation) && (
                <AdviceCard open={openedCard === 'additionalInfo'}>
                    <AdviceCardTitle isExpandable onClick={(e) => toggleCard(e, 'additionalInfo')}>Mehr Informationen</AdviceCardTitle>
                    <AdviceInformation dangerouslySetInnerHTML={{ __html: data.additionalInformation }} />
                </AdviceCard>
            )}
        </SC.Wrapper>
    );
};

export default CorrectionAdvice;
