import styled from "styled-components";

import { Colors } from "../../../Styles";

export const Mark = styled.mark`
    background: transparent linear-gradient(to top, #515BD4 10%, transparent 10%, transparent 100%);
`;

export const Trace = styled.span`
    color: ${Colors.crimson};
    text-decoration: line-through;
`;