import styled, { css } from "styled-components";

import {Colors, Fonts} from "../../Styles";

export const Wrapper = styled.div<{ isHighlighted: boolean, isCorrected: boolean }>`
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    white-space: pre-wrap;
    font-family: ${Fonts.publico};
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 60px;
    box-sizing: border-box;
    
    &:focus-visible {
        outline: none;
    }

    &[placeholder]:empty:before {
        content: attr(placeholder);
        color: #ccc;
        font-size: 2em;
        line-height: normal;
        display: block;
        height: 25vh;
        margin-bottom: -25vh;
        transition: opacity 0.25s;
        pointer-events: none;
    }
    
    & .text-segment.loading {
        color: transparent;
        background-color: ${Colors.mystic};
        pointer-events: none;
        text-decoration: none;
        user-select: none;
        animation: blinking 1s infinite;

        * {
            opacity: 0;
        }
    }
    
    & .text-segment.blured {
        opacity: 0.4;
        cursor: pointer;
    }
    
    & .text-segment>mark {
        ${({ isHighlighted }) => !isHighlighted && css`
            background: unset;
        `}
    }
    
    ${({ isCorrected }) => isCorrected && css`
        & .text-segment {
            cursor: pointer;
        }
    `};

    @keyframes blinking {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    }
`;