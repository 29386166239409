// tslint:disable:no-any
import {EditorState, Modifier} from 'draft-js';
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';

// import {State as MainState} from 'FullVersion/Store'; TODO: move redux away from library
import {resetStateAction, undoHistoryAction} from './Actions';
import {AdviceData, HistoryProps} from '../../Components/Advices/Util/Props';
import {HistoryType} from '../../Components/Advices/Util/Types';
import {decoratorStrategyType, getDetailsForEntity, getDraftSelection, EntityDetails} from '../../Util/Draft';
import {editorChangedAction} from '../PadState';

export const undoHistoryMiddleWare: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            if (action.type === undoHistoryAction.type) {

                let newSpellAdvices: AdviceData[] = api.getState().checkState.spellAdvices;
                let newStyleAdvices: AdviceData[] = api.getState().checkState.styleAdvices;
                const actionPayload = action.payload as HistoryProps;
                let adviceHistoryItems = api.getState().checkState.resultHistory;

                const {editorState, advice, historyItems} = getUndoErrorEditor(
                        api.getState().padState.editorState,
                        actionPayload,
                        adviceHistoryItems,
                    );
                switch (advice.type) {
                    case 'style':
                        newStyleAdvices = [...newStyleAdvices, advice];
                        break;
                    default:
                        newSpellAdvices = [...newSpellAdvices, advice];
                        break;
                }
                adviceHistoryItems = historyItems;
                api.dispatch(editorChangedAction({
                    oldEditorState: api.getState().padState.editorState,
                    newEditorState: editorState}),
                );

                api.dispatch(resetStateAction({
                    spellAdvices: newSpellAdvices,
                    styleAdvices: newStyleAdvices,
                    adviceHistory: adviceHistoryItems,
                }));

            }

            // Call the next dispatch method in the middleware chain.
            return next(action);

        };

export const getUndoErrorEditor = (
    editorState: EditorState,
    adviceHistoryItem: HistoryProps,
    historyItems: HistoryProps[])   => {
    const advice = {
        errorCode: adviceHistoryItem.errorCode,
        offset: adviceHistoryItem.offset,
        length: adviceHistoryItem.length,
        type: adviceHistoryItem.errorType,
        proposals: adviceHistoryItem.proposals,
        originalError: adviceHistoryItem.originalError,
        errorMessage: adviceHistoryItem.errorMessage,
        synonyms: [],
        occurrences: [],
    } as unknown as AdviceData;

    const manipulateEditorCallback =  (entity: EntityDetails) => {
        const currentContentState = editorState.getCurrentContent();
        const start = entity.start!;
        const startKey = entity.blockKeyStart;
        const endKey = entity.blockKeyEnd;
        const replaceTextSelection = getDraftSelection(editorState, start, entity.end!, startKey, endKey);
        const originalSelection = editorState.getSelection();
        const originalError = adviceHistoryItem.originalError;
        let contentStateWithOriginalError;
        let replaceEntitySelection;
        if (HistoryType.Corrected === adviceHistoryItem.historyType) {
            contentStateWithOriginalError = Modifier.replaceText(
                currentContentState,
                replaceTextSelection,
                originalError,
            );
            const newFocusOffset = contentStateWithOriginalError.getSelectionAfter().getFocusOffset();
            const newFocusKey = contentStateWithOriginalError.getSelectionAfter().getFocusKey();
            replaceEntitySelection = getDraftSelection(editorState, start, newFocusOffset, startKey, newFocusKey);
        } else {
            contentStateWithOriginalError = currentContentState;
            replaceEntitySelection = replaceTextSelection;
        }

        const decoratorType = advice.type === 'style' ?
            decoratorStrategyType.styleError : decoratorStrategyType.spellError;
        const contentStateWithEntity = contentStateWithOriginalError.createEntity(
            decoratorType,
            'MUTABLE',
            {originalError, customTypes: [decoratorType]},
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const contentState = Modifier.applyEntity(
            contentStateWithEntity,
            replaceEntitySelection,
            entityKey,
        );
        advice.entityKey = entityKey;
        advice.offset = start;
        advice.length = originalError.length;
        editorState = EditorState.push(editorState, contentState, 'insert-characters');
        editorState = EditorState.set(editorState, {selection: originalSelection});
        historyItems =  historyItems.filter(

            (
                historyItem: HistoryProps) =>
                !(historyItem.originalError === originalError &&
                    historyItem.offset === adviceHistoryItem.offset &&
                    historyItem.length === adviceHistoryItem.length &&
                    historyItem.correctedText === adviceHistoryItem.correctedText
                ),
            adviceHistoryItem,
        );
    };
    const entities: EntityDetails[] = getDetailsForEntity(editorState, adviceHistoryItem.entityKey);

    entities.forEach((entity: EntityDetails) => {
        manipulateEditorCallback(entity);
    });
    return {editorState, advice, historyItems};
};
