import {reducerWithInitialState} from 'typescript-fsa-reducers';

import {resetProfilerAction, setProfilerAction} from './Actions';
import {Profile, State} from './State';

const resetProfilerReducer = (): State => ({});

const setProfilerReducer = (state: State, payload: {key: string, value: Profile}): State => {
    Object.keys(state.request || {}).forEach((key: string) => payload.value[key] = state.request![key]);
    const newState = {request: {}};
    newState.request[payload.key] = {...payload.value};

    return newState as State;
};

export const initialState: State = {}
export const reducer = reducerWithInitialState(initialState)
    .case(resetProfilerAction, resetProfilerReducer)
    .case(setProfilerAction, setProfilerReducer);