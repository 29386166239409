import React from 'react';

export const OpenEyeIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <path
      d="M18 10.25c5.026 0 9.562 2.443 13.577 7.27a.75.75 0 0 1 0 .96c-4.015 4.827-8.55 7.27-13.577 7.27-5.026 0-9.562-2.443-13.577-7.27a.75.75 0 0 1 0-.96c4.015-4.827 8.55-7.27 13.577-7.27zm5.324 2.6.097.128a6.75 6.75 0 1 1-10.746-.128c-2.278.979-4.44 2.616-6.487 4.919L5.985 18l.203.231c3.48 3.914 7.287 5.904 11.444 6.014l.368.005c4.301 0 8.23-1.99 11.812-6.019l.202-.231-.202-.231c-2.048-2.303-4.21-3.94-6.488-4.92zM18 11.75l-.216.004A5.25 5.25 0 1 0 18 11.75z"
      fill="inherit"
      fillRule="evenodd"
    />
  </svg>
);
