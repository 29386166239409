// tslint:disable:no-any
import {EditorState, Modifier} from 'draft-js';
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {Action} from 'typescript-fsa';
// import {State as MainState} from 'FullVersion/Store'; TODO: move redux away from library
import {decoratorStrategyType, getDetailsForEntity, getDraftSelection, EntityDetails} from '../../Util/Draft';
import {editorChangedAction, ignoreErrorAction, IgnoreAdviceItemPayload} from './Actions';

export const ignoreErrorMiddleWare: Middleware<{}, any> = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch) =>
        <A extends Action<{}>>(action: A): A => {
            if (action.type === ignoreErrorAction.type) {
                const actionPayload = action.payload as IgnoreAdviceItemPayload;
                const {editorState, ignoreError} = getEditorStateWithErrorIgnore(
                    api.getState().padState.editorState,
                    actionPayload,
                );
                action.payload = ignoreError;
                api.dispatch(editorChangedAction({
                    oldEditorState: api.getState().padState.editorState,
                    newEditorState: editorState}),
                );
            }
            // Call the next dispatch method in the middleware chain.
            return next(action);

        };

export const getEditorStateWithErrorIgnore = (editorState: EditorState, ignoreError: IgnoreAdviceItemPayload):
    { editorState: EditorState; ignoreError: IgnoreAdviceItemPayload } => {
    const advice = ignoreError.adviceHistory;
    const manipulateEditorCallback = (entity: EntityDetails) => {

        const currentContentState = editorState.getCurrentContent();
        const originalSelection = editorState.getSelection();
        const start = entity.start!;
        const startKey = entity.blockKeyStart;
        const endKey = entity.blockKeyEnd;
        const replaceEntitySelection = getDraftSelection(editorState, start, entity.end!, startKey, endKey);

        const contentStateWithEntity = currentContentState.createEntity(
            decoratorStrategyType.spellErrorIgnored,
            'MUTABLE',
            {
                originalError: ignoreError.adviceHistory.originalError,
                customTypes: [decoratorStrategyType.spellErrorIgnored],
            },
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentState = Modifier.applyEntity(
            contentStateWithEntity,
            replaceEntitySelection,
            entityKey,
        );
        ignoreError.adviceHistory.entityKey = entityKey;
        editorState = EditorState.push(editorState, contentState, 'insert-characters');
        editorState = EditorState.set(editorState, {selection: originalSelection});
    };
    const entities: EntityDetails[] = getDetailsForEntity(editorState, advice.entityKey);

    entities.forEach((entity: EntityDetails) => {
        manipulateEditorCallback(entity);
    });
    return {editorState, ignoreError};
};
