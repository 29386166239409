import styled from "styled-components";

import {Colors, Fonts, MediaBreakpoints, Paddings} from "../../Styles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    

    @media ${MediaBreakpoints.medium} {
        padding-left: 95px;
    }
`;

export const Header = styled.div`
    background: ${Colors.mystic};
    padding: ${Paddings.horizontalPaddingMobile} ${Paddings.verticalPaddingMobile};
    position: relative;
    
    @media ${MediaBreakpoints.medium} {
        padding: 50px 5em 1.5em;
    }
`;

export const Select = styled.div`
  width: 205px;
  position: relative;
  background-color: ${Colors.white};
  padding: 8px;
  border: 2px solid ${Colors.black};

  @media ${MediaBreakpoints.medium} {
    padding: 16px;
  }

  .placeholder {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    p {
      color: ${Colors.mineShaft};
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 25px;
      letter-spacing: 1px;
      margin: 0;
    }
  }

  .options {
    left: -2px;
    width: 189px;
    background-color: ${Colors.white};
    padding: 16px;
    border: 2px solid ${Colors.black};
    border-top: transparent;
    position: absolute;
    z-index: 10;

    @media ${MediaBreakpoints.medium} {
      width: 205px;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-radius: 17px;
      transition-duration: 0.3s;
      padding: 4px 10px;

      &:hover {
        background-color: ${Colors.mystic};
      }
    }
  }
`;

export const Divider = styled.div`
    margin-bottom: 8px;
    margin-top: 8px;
    width: 100%;
    height: 1px;
    background-color: ${Colors.iron};
`;

export const DeleteButton = styled.button`
    align-self: center;
    padding: 0;
    margin: 0 20px;
    height: 34px;
    width: 34px;
    display: inline-block;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background: 0 0;
    border: none;
    outline: 0;
    user-select: none;
    cursor: pointer;
    font-weight: 600;
`;

export const SubmitButton = styled.button`
  align-self: center;
  width: 44px;
  height: 44px;
  padding: 0;
  background: ${Colors.supernova};
  border: none;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: Theinhardt, 'cv', 'icons', sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 2px;
  text-align: center;
  cursor: pointer;
  color: ${Colors.mineShaft};
  fill: currentColor;

  @media ${MediaBreakpoints.wide} {
    padding: 0 16px;
    width: 188px;
    height: 60px;

    svg {
      margin-left: 8px;
    }
  }

  svg {
    horiz-align: center;
    vertical-align: middle;
  }

  &:disabled {
    opacity: .3;
  }
`;

export const Title = styled.label`
    display: block;
    font-family: Theinhardt, 'cv', 'icons', sans-serif;
    font-size: 36px;
    margin-bottom: 1em;
    font-weight: 700;
    color: ${Colors.mineShaft};
`;

export const Form = styled.div`
    font-family: ${Fonts.publico};
    font-size: 23px;
    line-height: 30px;

    p{
        margin: 0
    }
`;

export const InputWrapper = styled.div`
    background: ${Colors.white};
    padding: 10px;
    display: flex;
    margin-top: 30px;

    @media ${MediaBreakpoints.medium} {
        padding: 20px;
        margin-top: ${Paddings.verticalPadding} * 2;
    }
`;

export const Input = styled.input`
  min-width: 185px;
  font-size: 23px;
  line-height: 1.15;
  outline: 0;
  height: 60px;
  background: none;
  border: none;
  border-left: 4px solid ${Colors.supernova};
  flex: 1;
  padding-left: 12px;

  @media ${MediaBreakpoints.medium} {
    font-size: 36px;
  }

  &::placeholder {
    font-size: 23px;
    color: ${Colors.osloGray};
    opacity: 1;

    @media ${MediaBreakpoints.medium} {
      font-size: 48px;
    }
  }
`;

export const Loader = styled.div`
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translateX(-50);
`;

export const SortingWrapper = styled.div`
    display: flex;
    justify-content: right;
    padding: ${Paddings.verticalPaddingMobile} ${Paddings.horizontalPaddingMobile};
    position: relative;
    
    @media ${MediaBreakpoints.medium} {
        padding: ${Paddings.verticalPadding} ${Paddings.horizontalPadding};
    }
`;

export const Message = styled.span`
    line-height: 24px;
    letter-spacing: 0.2px;
    font-family: Theinhardt, 'cv', 'icons', sans-serif;
    font-size: 17px;
    padding: 15px 0;
    display: inline-block;
    color: ${Colors.crimson}
`;

export const ToastContainer = styled.div`
    padding: 0.5em 5em;
    
    .toast--type_ink{
        padding: 0.5em 3em 0.5em 1em;
        display: inline-block;
        text-align: left;
    }
`;

export const Main = styled.div`
    padding: 0 ${Paddings.horizontalPaddingMobile};
    flex: 1 1 auto;
    overflow: auto;

    @media ${MediaBreakpoints.medium} {
        padding: 0 ${Paddings.horizontalPadding};
        overflow: auto;
    }
`;

export const NoEntry = styled.span`
  font-size: 28px;
  font-weight: 400;
  letter-spacing: .2px;
  max-width: 684px;
  display: inline-block;
  margin-top: ${Paddings.verticalPaddingMobile};

  @media ${MediaBreakpoints.medium} {
    margin-top: ${Paddings.verticalPadding};
    overflow: visible
  }
`;