import React from "react";

import * as SC from "./BlockContent.styled";
import { ADVICE_TYPE, Segment } from "../../../Hooks/useCorrectionStore";

type BlockContentProps = {
    blocks: Segment["blocks"];
};

export const BlockContent: React.FC<BlockContentProps> = ({blocks}) => {
    return <>
        {blocks?.map((block) => {
            if (block.type === ADVICE_TYPE.Spelling || block.type === ADVICE_TYPE.Style) {

                return <><SC.Advise key={block.id} id={block.id} data-llm-id={block.id} data-llm-type={block.type} isStyle={block.type === ADVICE_TYPE.Style}>
                    <span>{block.copy}</span>
                </SC.Advise></>
            }

            if (block.type === 'common') {
                return <span key={block.id}>{block.copy}</span>
            }

            return null;
        })}
    </>;
};