import styled from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.div`
    max-width: 520px;
    padding: 40px 18px;
    font-family: ${Fonts.theinhardt};

    @media screen and (min-width: 601px) {
        margin-left: 10%;
    }
`;
export const Headline = styled.h1`
  font-size: 38px;
  color: ${Colors.mineShaft};
  font-weight: bold;
  line-height: 45px;
  padding: 30px 0 10px;
  margin: 0;
`;

export const Subheadline = styled.h2`
  font-size: 18px;
  font-weight: normal;
  font-family: ${Fonts.publico};
  color: ${Colors.mineShaft};
  line-height: 31px;
  margin: 0 0 50px;

  @media screen and (min-width: 601px) {
    font-size: 23px;
  }
`;
