import styled from "styled-components";

import { Colors, Fonts, MediaBreakpoints } from "../../../Styles";

export const ModalWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    max-width: 90%;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 15px;
    border-radius: 10px;
    background: ${Colors.white};
    box-sizing: border-box;

    @media ${MediaBreakpoints.wide} {
        padding: 40px 70px;
    }

    @media ${MediaBreakpoints.medium} {
        padding: 30px;
    }
`;

export const ModalHeader = styled.div`
    h2 {
        margin-bottom: 15px;
        font-size: 25px;
        font-weight: bold;
        line-height: 34px;

        @media ${MediaBreakpoints.medium} {
            margin-bottom: 30px;
            font-size: 30px;
        }
        &:before {
            display: none;
            content: "";
            height: 100px;
            width: 140px;
            position: absolute;
            top: 9px;
            left: 15px;
            background-image: url("/assets/icons/illu.svg");
            background-repeat: no-repeat;
            background-size: contain;

            @media ${MediaBreakpoints.medium} {
                display: block;
            }

            @media ${MediaBreakpoints.wide} {
                height: 125px;
                width: 180px;
                top: 9px;
                left: 50px;
            }
        }
    }

    sup {
        margin-left: 5px;
        top: -10px;
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
    }

    button {
        position: absolute;
        top: 15px;
        right: 15px;
        background: ${Colors.white};
        border: none;
        font-size: 20px;
        cursor: pointer;
    }
`;

export const SubTitle = styled.div`
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;

    @media ${MediaBreakpoints.medium} {
        max-width: 580px;
        margin-bottom: 15px;
        font-size: 22px;
    }
`;

export const TextWrapper = styled.div`
    text-align: center;
    line-height: 20px;

    @media ${MediaBreakpoints.medium} {
        max-width: 580px;
        line-height: 25px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media ${MediaBreakpoints.medium} {
        flex-direction: row;
    }

    button {
        margin: 10px;

        @media ${MediaBreakpoints.medium} {
            margin: 20px;
        }
    }
`;

export const CheckboxWrapper = styled.div`
    span {
        font-family: ${Fonts.theinhardt};
        font-weight: bold;
    }
`;