import React from "react";

import * as SC from './SettingsInformation.styled';
import { InfoIcon } from "../../Icons";
import { Tooltip } from "../../Tooltip/Tooltip";


const SettingsInformation: React.FC = () => (
    <SC.SettingsIcon>
        <Tooltip direction="north" text="Die Stilprüfung können Sie in den Einstellungen anpassen.">
            <InfoIcon />
        </Tooltip>
    </SC.SettingsIcon>
);

export default SettingsInformation;

