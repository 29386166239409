import styled from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 48px;
    color: ${Colors.mineShaft};
    background-color: ${Colors.supernova};
    gap: 20px 0;

    font-family: ${Fonts.theinhardt};
    font-size: 14px;
    letter-spacing: 1.29px;
`;

export const Copyright = styled.span`
    margin-right: 30px;
    white-space: nowrap;
`;

export const LinksWrapper = styled.nav`
    display: flex;
    flex-wrap: wrap;
    gap: 5px 30px;
    
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;