import {reducerWithInitialState} from 'typescript-fsa-reducers';

import {settingsDataLoadedAction} from './Actions';
import {SettingsDataPayload, State} from './State';

/**
 * @param {State} state
 * @param {SettingsDataPayload} settingsData
 * @returns {State}
 */
const loadSettingsDataReducer = (state: State, settingsData: SettingsDataPayload): State => (
    {
        ...state,
        featureFlags: settingsData.featureFlags,
    }
);

export const initialState: State = {
    featureFlags: {},
};

export const reducer = reducerWithInitialState(initialState)
    .case(settingsDataLoadedAction, loadSettingsDataReducer)
    .build();
