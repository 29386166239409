import React from "react";

export const CrossOutlinedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icons/new/x_outline ">
            <g id="Group 3">
                <path id="cross-border" fillRule="evenodd" clipRule="evenodd"
                      d="M3.33528 20.0005C3.33528 10.8108 10.8117 3.33378 20.0016 3.33378C20.2888 3.33378 20.5685 3.36233 20.8524 3.37745C29.6459 3.82429 36.6679 11.0981 36.6679 20.0005C36.6679 28.9029 29.6459 36.1759 20.8524 36.6235C20.5685 36.6378 20.2888 36.6664 20.0016 36.6664C10.8117 36.6664 3.33528 29.1893 3.33528 20.0005ZM29.5248 2.41737C26.6903 0.87664 23.4457 0 19.9992 0C18.9656 0 17.9496 0.0793738 16.9575 0.231066C16.6268 0.282218 16.2987 0.340426 15.9741 0.407452C6.86836 2.27538 0 10.3503 0 20.0004C0 29.6497 6.86836 37.7246 15.9741 39.5925C16.2987 39.6596 16.6268 39.7187 16.9575 39.7689C17.9496 39.9206 18.9656 40 19.9992 40C23.4457 40 26.6903 39.1234 29.5248 37.5826C29.8079 37.4283 30.0874 37.2678 30.3617 37.1002C36.1347 33.5884 39.9993 27.2376 39.9993 20.0004C39.9993 12.7633 36.1347 6.41164 30.3617 2.89979C30.0874 2.73222 29.8079 2.57171 29.5248 2.41737Z"
                      fill="#333333"/>
                <path id="cross-main" fillRule="evenodd" clipRule="evenodd"
                      d="M26.2765 13.7235C25.7558 13.2028 24.9116 13.2028 24.3909 13.7235L20.0002 18.1142L15.6101 13.7241L15.4605 13.595C14.9386 13.2077 14.1979 13.2507 13.7245 13.7241C13.2038 14.2448 13.2038 15.089 13.7245 15.6097L18.1146 19.9998L13.7245 24.39L13.5954 24.5395C13.2081 25.0615 13.2511 25.8022 13.7245 26.2755C14.2452 26.7962 15.0894 26.7962 15.6101 26.2755L20.0002 21.8854L24.3909 26.2761L24.5405 26.4052C25.0624 26.7925 25.8032 26.7494 26.2765 26.2761C26.7972 25.7554 26.7972 24.9112 26.2765 24.3905L21.8858 19.9998L26.2765 15.6091L26.4056 15.4596C26.7929 14.9376 26.7499 14.1969 26.2765 13.7235Z"
                      fill="#333333"/>
            </g>
        </g>
    </svg>
);
