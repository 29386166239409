import React, { useEffect, useRef, useState } from "react";

import * as SC from "./PadBanner.styled";
import { ChevronDownIcon, ChevronUpIcon } from "../Icons";

type PadBannerProps = {
    title: string;
    label?: React.ReactNode;
    href?: string;
    children?: React.ReactNode;
    isExpress?: boolean;
}

const PadBanner: React.FC<PadBannerProps> = ({ title, isExpress, children }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const sourceRef = useRef< Element | null>(null);
    const bannerRef = useRef< HTMLDivElement | null>(null);

    const handleResize = () => {
        if (!bannerRef.current) return;

        if (window.innerWidth < 1024) {
            bannerRef.current.style.marginLeft = '0';
            bannerRef.current.style.width = '100%';
        } else {
            const sourceNode = sourceRef.current ?? (sourceRef.current = document.querySelector(".duden-editor"));

            if (sourceNode) {
                const { left, width } = sourceNode.getBoundingClientRect();

                bannerRef.current.style.marginLeft = `${Math.max(left - 105, 0)}px`;
                bannerRef.current.style.width = `${width + 130}px`;
            }
        }
    }

    useEffect(() => {
        handleResize();

        setTimeout(() => {
            handleResize()
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleContentExpand = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsExpanded(value => !value);
    };

    return <SC.Wrapper>
        <div ref={bannerRef} data-testid="pad-banner">
            <SC.ContentWrapper data-testid={`pad-banner-content-${isExpress ? 'express' : 'classic'}`}>
                <SC.ExpandIcon href="#" onClick={handleContentExpand}>{isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}</SC.ExpandIcon>
                <SC.Content>
                    <SC.Title>{title}</SC.Title>
                    {isExpanded && children}
                </SC.Content>
                {(isExpanded && !isExpress) && <SC.ImageWrapper hasPlaceholder={!isExpress}>
                    <img src={`/assets/icons/checkout-${isExpress ? 'express' : 'standart'}.svg`} alt="Banner" />
                </SC.ImageWrapper>}
            </SC.ContentWrapper>
        </div>
    </SC.Wrapper>;
};

export default PadBanner;