import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useCorrectionStore, { ADVICE_TYPE, NormalizedAdvice } from './useCorrectionStore';
import { addDictionaryEntryAction } from '../Store/DictionaryState';
import { selectUserFeatures } from '../Store/UserState';
import { hasFeature } from '../Util/UserUtils';


const NON_ACCEPTABLE_ERROR_CODES = ['21', '22', '87', '139', '140', '141', '142', '143', '147', '149', '229', '230', '254', 'c005', 'c006'];
const ADDITIONAL_MESSAGE_ERROR_CODES = ['160', '161', '902', '145', '150', '151', '153', '155', '157', '158', '159', '163', '225', '255', '901', '100', '170', '171', '166', '231', '240', '241', '242', '135', 'c006'];
const SETTINGS_AVAILABLE_ERROR_CODES = ['c005', 'c006', '240', '241', '242'];

export const useCorrectionAdvice = (advice: NormalizedAdvice) => {
    const dispatch = useDispatch();
    const userFeatures = useSelector(selectUserFeatures);
    const handleSetAdviceSuggestion = useCorrectionStore((store) => store.handleSetAdviceSuggestion);
    const handleAcceptAdvice = useCorrectionStore((store) => store.handleAcceptAdvice);
    const rejectAdvice = useCorrectionStore((store) => store.handleRejectAdvice);

    const canAddToDictionary = hasFeature('accessDictionary', userFeatures) && advice.adviceType === ADVICE_TYPE.Spelling && (advice.errorCode === '21' || advice.errorCode === '22');

    const isAcceptable = advice.proposals.length > 0 || !NON_ACCEPTABLE_ERROR_CODES.includes(advice.errorCode);
    const isMultipleSpellError = ADVICE_TYPE.Spelling && advice.occurrences.length > 1;
    const showAdditionalShortMessage = isAcceptable && ADDITIONAL_MESSAGE_ERROR_CODES.includes(advice.errorCode);
    const hasSettingsInformation = SETTINGS_AVAILABLE_ERROR_CODES.includes(advice.errorCode);

    /**
     * accept specified proposal and dispatch to redux
     * @param acceptedText
     */
    const accept = (acceptedText: string): void => {
        if (!isAcceptable && advice.errorCode !== 'c006') {
            return;
        }

        // if (isMultipleSpellError) {
        //     spellAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(correctErrorAction(getCorrectedErrorPayload(a, acceptedText))));
        //     return;
        // }
        // dispatch(correctErrorAction(getCorrectedErrorPayload(advice as unknown as AdviceData, acceptedText)));
        if (advice.id && acceptedText) {
            handleAcceptAdvice(advice, acceptedText);
        }
    };

    const reject = (): void => {
        // when spell error and multiple, ignore all advices with same first occurrence entity key
        // if (isMultipleSpellError) {
        //     spellAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(ignoreErrorAction(getIgnoreErrorPayload(a))));
        //     return;
        // }

        // when duplicated words, ignore all identical words
        // if (advice.errorCode === 'c006') {
        //     styleAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(ignoreErrorAction(getIgnoreErrorPayload(a))));
        //     return;
        // }

        rejectAdvice(advice);
    };

    const showSuggestion = useCallback((suggestion?: string) => {
        if (advice.id && suggestion) {
            handleSetAdviceSuggestion({
                id: advice.id,
                segmentId: advice.segmentId,
                suggestion,
            });
        }
    }, [advice.id, advice.segmentId, handleSetAdviceSuggestion]);

    const hideSuggestion = useCallback(() => {
        handleSetAdviceSuggestion(null);
    }, [handleSetAdviceSuggestion]);

    /**
     * add original error to dictionary
     */
    const addToDictionary = (): void => {
        if (!canAddToDictionary) {
            return;
        }
        dispatch(addDictionaryEntryAction({ word: advice.originalError }));
    };

    return {
        isAcceptable,
        isMultiple: isMultipleSpellError,
        showAdditionalShortMessage,
        hasSettingsInformation,
        canAddToDictionary,
        accept,
        reject,
        showSuggestion,
        hideSuggestion,
        addToDictionary,
    };
};
