import {useDispatch, useSelector} from 'react-redux';

import { hasFeature } from './UserUtils';
import {
    openInlineAdviceItemAction,
    selectInlineAdviceEntityKey,
    selectInlineAdviceTimer,
    setInlineAdviceTimerAction,
} from '../Store/PadState';
import { selectUserFeatures } from '../Store/UserState';

export const useInlineAdviceItem = () => {
    const inlineAdviceItemKey = useSelector(selectInlineAdviceEntityKey);
    const inlineAdviceItemTimer = useSelector(selectInlineAdviceTimer);
    const userFeatures = useSelector(selectUserFeatures);

    const dispatch = useDispatch();
    const canUseInlineAdvices = hasFeature('inlineAdvices', userFeatures);

    const startInlineAdviceItemClosing = () => {
        if (!canUseInlineAdvices) {
            return;
        }

        const timer = window.setTimeout(
            () => {
                dispatch(openInlineAdviceItemAction({entityKey: '-1'}));
            },
            1000,
        );
        dispatch(setInlineAdviceTimerAction(timer));
    };

    const stopInlineAdviceItemClosing = () => {
        if (!canUseInlineAdvices) {
            return;
        }

        if (inlineAdviceItemTimer !== undefined) {
            window.clearTimeout(inlineAdviceItemTimer as number);
        }
        dispatch(setInlineAdviceTimerAction(undefined));
    };

    const openInlineAdviceItem = (entityKey: string) => {
        if (!canUseInlineAdvices) {
            return;
        }

        stopInlineAdviceItemClosing();
        dispatch(openInlineAdviceItemAction({entityKey}));
    };

    const closeInlineAdviceItem = () => {
        if (!canUseInlineAdvices) {
            return;
        }
        stopInlineAdviceItemClosing();
        dispatch(openInlineAdviceItemAction({entityKey: '-1'}));
    };

    return {
        inlineAdviceItemKey,
        closeInlineAdviceItem,
        openInlineAdviceItem,
        startInlineAdviceItemClosing,
        stopInlineAdviceItemClosing,
    };
};
