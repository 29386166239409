import * as React from "react";

export const ArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M10.298 17.746l-.075-.067L4 11.457l6.223-6.223c.312-.312.818-.312 1.13 0 .29.289.311.742.067 1.056l-.066.076-4.29 4.292 13.724-.001c.442 0 .8.358.8.8 0 .408-.305.744-.7.794l-.1.006H7.064l4.29 4.291c.288.288.31.742.066 1.056l-.066.075c-.288.289-.742.311-1.056.067z"
        />
    </svg>
);
