export enum AdviceType {
    synonym,
    spellCheck,
    writingStyle,
    history,
}

export enum ButtonType {
    accept = 'accept',
    acceptAll = 'acceptall',
    reject = 'reject',
    rejectAll = 'rejectall',
    elaborate = 'elaborate',
    undo = 'undo',
    placeholder = 'placeholder',
    dictionary = 'dictionary',
}

export enum HistoryType {
    Corrected,
    Ignored,
}

export enum PhaseTypes {
    revolver = '',
    checked = '✓',
    caution = '⚠',
}
