import React from 'react';
import {useState} from "react";

import {useSelector} from 'react-redux';

import {ProfilerDataRow} from './ProfilerDataRow';
import {selectProfilerStateFromMainState} from '../../Store/ProfilerState/Selectors';

import 'library/src/Styles/component/profiler/profiler.sass'

export const Profiler: React.FC = () => {
    const [showProfiler, setShowProfiler] = useState(false);
    const profilerData = useSelector(selectProfilerStateFromMainState);

    if (!profilerData || !profilerData.request) return null;

    return (
        <>
            <span className="profiler__button" onClick={() => setShowProfiler(!showProfiler)}>P</span>
            {showProfiler &&
                <div className="profiler">
                    <div className="profiler__overlay">
                        <table className="profiler__data">
                            <colgroup>
                                <col width="20%"/>
                                <col width="*"/>
                                <col width="10%"/>
                            </colgroup>
                            <caption>Profiling Results</caption>
                            <tbody>
                            {Object.keys(profilerData.request).map((key: string) => (
                                <ProfilerDataRow
                                    children={profilerData.request[key]}
                                    depth={0}
                                    display={true}
                                    duration={profilerData.request[key].duration}
                                    start={profilerData.request[key].start}
                                    maxDuration={profilerData.request[key].duration}
                                    firstStart={profilerData.request[key].start}
                                    title={key}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    );
}
