import React, { forwardRef } from 'react';

import type { KnobAppearance , KnobSize } from '../Knob';
import * as S from '../Knob.styled';

export type IconKnobProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  appearance?: KnobAppearance | boolean;
  size?: KnobSize;
  icon: React.ReactNode;
};

export const IconKnob = forwardRef<HTMLButtonElement, IconKnobProps>(
  ({ appearance, size, icon, children, ...knobProps }, forwardedRef) => {
    return (
      <S.IconButton {...knobProps} ref={forwardedRef}>
        <S.Icon
          appearance={appearance}
          size={size}
        >
          {icon}
        </S.Icon>
        {!!children && <S.IconLabel>{children}</S.IconLabel>}
      </S.IconButton>
    );
  },
);
