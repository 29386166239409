import { CardType } from "../Components/Advice/Advice";
import { AdviceType } from '../Hooks/useCorrectionStore';
import { UserRole } from "../Store/UserState";

// tslint:disable-next-line:no-any
declare var dataLayer: any[];

/** old tracking event function */
export enum gtmEventTypes {
    newsletter = 'Newsletterformular',
    padFocus = 'KlickWeissesFeld',
    copyText = 'KlickKopieren',
    adviceExpanded = 'VorschlaegeAusklappen',
    adviceAccepted = 'KlickPrüfungsergebnisseAngenommen',
    adviceRejected = 'KlickPrüfungsergebnisseAbgelehnt',
    historyExpanded = 'KlickHistorieÖffnen',
    historyItemUndone = 'KlickHistorienEintragRückgängigMachen',
    clickDelete = 'click_delete',
    confirmDelete = 'confirm_delete',
    textCopied = 'text_copied',
}

export function sendGTMEvent(event: gtmEventTypes): void {
    // only push events, if dataLayer from googleTagManager is available
    if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push({event});
    }
}

/** new tracking event handling  */
type Location = "advice-panel" | "inline-advice";

interface IEventAdviceTypeSelection {
    event: "advice-type-selection";
    "advice-type": AdviceType | "synonyms";
    location: Extract<Location, "advice-panel">;
}
interface IEventTextAdvice {
    event: "text-advice";
    option: "accepted" | "rejected";
    "advice-type": AdviceType;
    location: Location;
}
interface IEventNavigateAdvices {
    event: "navigate-advices";
    "advice-type": AdviceType;
    location: Extract<Location, "advice-panel">;
}

interface IEventSelectAlternative {
    event: "select-alternative";
    "advice-type": AdviceType;
    location: Location;
}

interface IEventAddToDictionary {
    event: "save-in-dictionary";
    "advice-type": AdviceType;
    location: Location;
}

interface IEventOpenCard {
    event: "open-card";
    "advice-type": AdviceType;
    card: CardType;
    location: Extract<Location, "advice-panel">;
}

interface IEventUserStatus {
    event: "user_status";
    role: UserRole[];
    hasUsedTrial: boolean;
    hasHadPremium: boolean;
    trialDaysRemaining: number;
    characterLimit?: number;
}

interface IEventAppMenu {
    event: "app-menu";
    option: "spellcheck" | "dictionary" | "settings" | "extensions" | "express_spellcheck" | "express_rephrase";
}

interface IEventSelectExtensionStore {
    event: "select-extension-store";
    option: "edge" | "chrome" | "firefox" | "word";
}

interface IEventPremiumLinkClick {
    event: "premium_touchpoint";
    option?: string;
}

interface IEventMenuLinkClick {
    event: "dm_navigation_click";
    option: string;
    navigation_location: "header" | "footer";
}

interface IEventSpellcheckSelectOption {
    event: "spellcheck_select_option";
    option: "mentor_standard" | "mentor_express";
}

interface IEventExpressConsent {
    event: "mentor_express_consent";
    option: "accepted" | "rejected" | "closed";
}

interface IEventGtmEvent {
    event: "gtmEvent";
    event_name: "premium_touchpoint";
    option: "mentor_express_limit";
}

interface IEventExpressStart {
    event: "mentor_express_start";
}

interface IEventPremiumTouchpoint {
    event: "premium_touchpoint";
    option: "mentor_express_limit"
}

interface IEventTryMentorExpressClick {
    event: "try_mentor_express_click";
}

type Events = IEventTextAdvice
    | IEventNavigateAdvices
    | IEventSelectAlternative
    | IEventAddToDictionary
    | IEventOpenCard
    | IEventAdviceTypeSelection
    | IEventUserStatus
    | IEventAppMenu
    | IEventSelectExtensionStore
    | IEventPremiumLinkClick
    | IEventMenuLinkClick
    | IEventSpellcheckSelectOption
    | IEventExpressConsent
    | IEventGtmEvent
    | IEventExpressStart
    | IEventPremiumTouchpoint
    | IEventTryMentorExpressClick;

export function GTMDataLayerPush(event: Events) {
    if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push(event);
    }
}