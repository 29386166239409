import styled, { createGlobalStyle } from "styled-components";

interface Props {
    fontSize?: number;
    fontWeight?: number | string;
    fontStyle?: string;
    lineHeight?: number | string;
    letterSpacing?: number | string;
}

export const FontFacesTheinhardt = createGlobalStyle`
    @font-face {
        font-family: Theinhardt;
        src: url(${"/assets/Theinhardt/theinhardt-400-normal.woff2"}) format("woff2");
        src: url(${"/assets/Theinhardt/theinhardt-400-normal.woff"}) format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: Theinhardt;
        src: url(${"/assets/Theinhardt/theinhardt-500-normal.woff2"}) format("woff2");
        src: url(${"/assets/Theinhardt/theinhardt-500-normal.woff"}) format("woff");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: Theinhardt;
        src: url(${"/assets/Theinhardt/theinhardt-700-normal.woff2"}) format("woff2");
        src: url(${"/assets/Theinhardt/theinhardt-700-normal.woff"}) format("woff");
        font-weight: 700;
        font-style: normal;
    }
`;

export const FontTheinhardt = styled.span`
    font-family: Theinhardt, sans-serif;
    font-size: ${(props: Props) => props.fontSize}px;
    line-height: ${(props: Props) => props.lineHeight};
    letter-spacing: ${(props: Props) => props.letterSpacing};
    font-weight: ${(props: Props) => props.fontWeight};
    font-style: ${(props: Props) => props.fontStyle};
    font-stretch: normal;
`;

FontTheinhardt.defaultProps = {
    fontSize: 15,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.4,
    letterSpacing: "normal",
};
