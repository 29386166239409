import { reducer as checkStateReducer, AdviceState } from 'library/src/Store/AdviceState';
import { reducer as dictionaryReducer, State as DictionaryState } from 'library/src/Store/DictionaryState';
import { padStyleMiddleWare, reducer as padStateReducer, State as PadState } from 'library/src/Store/PadState';
import { profilerMiddleware, reducer as profilerReducer, State as ProfilerState } from 'library/src/Store/ProfilerState'
import { reducer as settingsStateReducer, State as SettingsState } from 'library/src/Store/SettingsState';
import { reducer as userStateReducer, State as UserState } from 'library/src/Store/UserState';

import { undoHistoryMiddleWare } from 'library/src/Store/AdviceState/UndoHistoryMiddleware';
import { correctErrorMiddleWare } from 'library/src/Store/PadState/CorrectErrorMiddleware';
import { ignoreErrorMiddleWare } from 'library/src/Store/PadState/IgnoreErrorMiddleware';
import { logoutNoticeMiddleware } from 'library/src/Store/UserState/LogoutNoticeMiddleware';
import { showSynonymsMiddleWare } from 'library/src/Store/PadState/ShowSynonymsMiddleware';
import { triggerRegionMiddleWare } from 'library/src/Store/PadState/TriggerRegionMiddleware';
import { triggerTextCheckMiddleware } from 'library/src/Store/PadState/TriggerTextCheckMiddleware';
import { hideStyleAdviceMiddleware } from 'library/src/Store/UserState/HideStyleAdviceMiddleware';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(
    sagaMiddleware,
    logoutNoticeMiddleware,
    ignoreErrorMiddleWare,
    correctErrorMiddleWare,
    undoHistoryMiddleWare,
    showSynonymsMiddleWare,
    padStyleMiddleWare,
    triggerRegionMiddleWare,
    triggerTextCheckMiddleware,
    hideStyleAdviceMiddleware,
    profilerMiddleware,
);

export interface State {
    checkState: AdviceState;
    padState: PadState;
    userState: UserState;
    settingsState: SettingsState;
    dictionaryState: DictionaryState;
    profilerState: ProfilerState;
}

export const getState = () => store.getState() as State;

export const store = createStore(
    combineReducers({
        checkState: checkStateReducer,
        padState: padStateReducer,
        userState: userStateReducer,
        settingsState: settingsStateReducer,
        dictionaryState: dictionaryReducer,
        profilerState: profilerReducer,
    }),
    composeWithDevTools(middleware),
);

sagaMiddleware.run(rootSaga);
