import { Popover } from '@headlessui/react';
import styled, { css } from 'styled-components';

export const PopoverPanel = styled(Popover.Panel)<{ isHeaderView?: boolean }>`
    position: absolute;
    z-index: 10;

    ${({ isHeaderView }) => isHeaderView
        ? css`
            top: 100%;
            right: 0;
        `
        : css`
            left: 100%;
            top: -20px;
            margin-left: 10px;
        `
    }
`;