import styled from "styled-components";

import {Fonts} from "../../../Styles";

export const Wrapper = styled.div`
    text-align: center;

    span {
        display: inline-block;
        border: 1px solid currentColor;
        border-radius: 50%;
    }

    svg {
        display: block;
        margin: 0 auto;
    }

    p {
        font-family: ${Fonts.theinhardt};
        font-size: 26px;
        line-height: 31px;
        margin-top: 20px;
        padding: 0 20px;
    }
`;