import React from 'react';

import classNames from 'classnames';

import '../../../Styles/component/authoring/phase/phase.sass';
import {PhaseTypes} from './Types';

type PhaseProps = {
    text: string | JSX.Element;
    type: PhaseTypes;
}

export const Phase: React.FC<PhaseProps> = ({text, type}) => {
    return (
        <div className="phase" key={getKey()}>
            <span
                className={classNames('', {
                    phase__status: type !== PhaseTypes.revolver,
                    phase__revolver: type === PhaseTypes.revolver,
                })}
            >
                {type}
            </span>
            <span className="phase__text">{text}</span>
        </div>
    );

    function getKey(): string {
        switch (type) {
            case PhaseTypes.revolver:
                return 'revolver';
            case PhaseTypes.checked:
                return 'checked';
            case PhaseTypes.caution:
                return 'caution';
        }
    }
}
