import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";

import { SettingsInformation } from "./SettingsInformation";
import { AdviceType, selectSpellAdvices, selectStyleAdvices } from "../../Store/AdviceState";
import {addDictionaryEntryAction} from "../../Store/DictionaryState";
import {correctErrorAction, highlightEntityAction, ignoreErrorAction} from "../../Store/PadState";
import {selectUserFeatures} from "../../Store/UserState";
import {Fonts} from "../../Styles";
import {hasFeature} from "../../Util/UserUtils";
import {getCorrectedErrorPayload, getIgnoreErrorPayload, getPreviewForProposal} from "../Advices/Util/helpers";
import {AdviceData} from "../Advices/Util/Props";

interface UseAdvice {
    suggestion: JSX.Element;
    isAcceptable: boolean;
    isMultiple: boolean;
    showAdditionalShortMessage: boolean;
    hasSettingsInformation: boolean;
    canAddToDictionary: boolean;
    accept: (acceptedText: string) => void;
    mouseEnter: (previewText: string) => void;
    mouseLeave: () => void;
    reject: () => void;
    addToDictionary: () => void;
}

const StyledLabel = styled.span`
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
`;
const StyledProposal = styled.span`
    font-family: ${Fonts.publico};
    font-weight: bold;
    font-size: 23px;
    overflow: hidden;
    word-wrap: break-word;
    &:hover {
        cursor: pointer;
    }
`;

export const useAdvice = (advice: AdviceData, adviceType: AdviceType): UseAdvice => {
    const dispatch = useDispatch();
    const userFeatures = useSelector(selectUserFeatures);
    const spellAdvices = useSelector(selectSpellAdvices);
    const styleAdvices = useSelector(selectStyleAdvices);

    const nonAcceptableErrorCodes = [
        "21",
        "22",
        "87",
        "139",
        "140",
        "141",
        "142",
        "143",
        "147",
        "149",
        "229",
        "230",
        "254",
        "c005",
        "c006",
    ];

    const canAddToDictionary =
        hasFeature("accessDictionary", userFeatures) &&
        adviceType === "SPELL-ERROR" &&
        (advice.errorCode === "21" || advice.errorCode === "22");
    const isAcceptable = advice.proposals.length > 0 || !nonAcceptableErrorCodes.includes(advice.errorCode);
    const isMultipleSpellError = adviceType === "SPELL-ERROR" && advice.occurrences.length > 1;
    const showAdditionalShortMessage =
        isAcceptable &&
        [
            "160",
            "161",
            "902",
            "145",
            "150",
            "151",
            "153",
            "155",
            "157",
            "158",
            "159",
            "163",
            "225",
            "255",
            "901",
            "100",
            "170",
            "171",
            "166",
            "231",
            "240",
            "241",
            "242",
            "135",
            "c006",
        ].includes(advice.errorCode);
    const hasSettingsInformation = ["c005", "c006", "240", "241", "242"].includes(advice.errorCode);
    /**
     * show preview text for currently displayed advice
     * @param previewText
     */
    const mouseEnter = (previewText: string): void => {
        if (advice.entityKey === undefined) {
            return;
        }

        const preview = getPreviewForProposal(advice, previewText);
        dispatch(highlightEntityAction({entityKey: advice.entityKey, previewText: preview}))
    };

    /**
     * reset preview text for currently displayed advice
     */
    const mouseLeave = (): void => {
        if (advice.entityKey === undefined) {
            return;
        }
        dispatch(highlightEntityAction({entityKey: advice.entityKey, previewText: []}));
    };

    /**
     * accept specified proposal and dispatch to redux
     * @param acceptedText
     */
    const accept = (acceptedText: string): void => {
        if (!isAcceptable && advice.errorCode !== "c006") {
            return;
        }

        // when spell error and multiple, accept all advices with same first occurrence entity key
        if (isMultipleSpellError) {
            spellAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(correctErrorAction(getCorrectedErrorPayload(a, acceptedText))));
            return
        }
        dispatch(correctErrorAction(getCorrectedErrorPayload(advice, acceptedText)));
    };

    /**
     * reject advice and dispatch to redux
     */
    const reject = (): void => {
        // when spell error and multiple, ignore all advices with same first occurrence entity key
        if (isMultipleSpellError) {
            spellAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(ignoreErrorAction(getIgnoreErrorPayload(a))));
            return;
        }

        // when duplicated words, ignore all identical words
        if (advice.errorCode === "c006") {
            styleAdvices.filter(a => a.firstOccurrenceEntityKey === advice.firstOccurrenceEntityKey).forEach(a => dispatch(ignoreErrorAction(getIgnoreErrorPayload(a))));
            return;
        }
        dispatch(ignoreErrorAction(getIgnoreErrorPayload(advice)));
    };

    /**
     * add original error to dictionary
     */
    const addToDictionary = (): void => {
        if (!canAddToDictionary) {
            return;
        }
        dispatch(addDictionaryEntryAction({word: advice.originalError}));
    };

    /**
     * return either label, first proposal or unknown
     */
    const getSuggestion = (): JSX.Element => {
        if (!isAcceptable) {
            return (
                <StyledLabel className="advice__label">
                    {advice.shortMessage.trim()}
                    {hasSettingsInformation && <SettingsInformation />}
                </StyledLabel>
            );
        }

        if (advice.proposals.length === 0 && isAcceptable) {
            return (
                <StyledProposal className="advice__proposal" onClick={() => accept(" ")} onMouseEnter={() => mouseEnter(" ")} onMouseLeave={mouseLeave}>
                    <del>{advice.originalError}</del>
                </StyledProposal>
            );
        }
        return <StyledProposal className="advice__proposal" onClick={() => accept(advice.proposals[0])} onMouseEnter={() => mouseEnter(advice.proposals[0])} onMouseLeave={mouseLeave}>{advice.proposals[0]}</StyledProposal>;
    };

    return {
        suggestion: getSuggestion(),
        isAcceptable,
        isMultiple: isMultipleSpellError,
        showAdditionalShortMessage,
        hasSettingsInformation,
        canAddToDictionary,
        accept,
        reject,
        mouseEnter,
        mouseLeave,
        addToDictionary,
    };
}
