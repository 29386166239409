import styled from "styled-components";

import { Colors, Fonts, MediaBreakpoints } from "../../Styles";

export const Wrapper = styled.header`
    background-color: ${Colors.supernova};
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    font-family: ${Fonts.theinhardt};
    font-size: 14px;
    color: ${Colors.mineShaft};

    @media ${MediaBreakpoints.medium} {
        padding: 0 48px;
    }

    .logo {
        height: 32px;
        padding: 14px 0;
    }
`;

export const Button = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 34px;
    font-weight: 400;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &--close {
        position: static;
        transform: translateY(0);
        font-size: 26px;
        font-weight: 700;
    }

    &:focus {
        background: transparent;
        outline: none;
    }

    @media ${MediaBreakpoints.medium} {
        right: 48px;
    }

    @media ${MediaBreakpoints.wide} {
        display: none;
    }
`;

Button.displayName = "menuButton"