import React from "react";

import { css, default as styled } from "styled-components";

import {Colors, Fonts, MediaBreakpoints} from "../../Styles";

type RoundedCornersButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    style?: React.CSSProperties;
    defaultColor?: string;
    margin?: string;
    lineHeight?: number;
    className?: string;
    active?: boolean;
}

const buttonActiveStyle = css`
        background: ${Colors.mineShaft};
        color: ${Colors.white};

        &:before {
            content: '';
            display: inline-block;
            height: 6px;
            width: 6px;
            margin-right: 6px;
            background: ${Colors.white};
            border-radius: 50%;
        }
    `

const Button = styled.button<Pick<RoundedCornersButtonProps, 'margin' | 'lineHeight' | 'defaultColor' | 'active'>>`
    display: flex;
    align-items: center;
    margin: ${({ margin }) => margin}px;
    padding: 1px 8px;
    border: 2px solid ${Colors.mineShaft};
    border-radius: 28px;
    line-height: ${({ lineHeight }) => lineHeight}px;
    letter-spacing: 0.6px;
    font-size: 15px;
    font-weight: bold;
    font-family: ${Fonts.theinhardt};
    color: ${({ defaultColor }) => defaultColor};
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;

    @media screen and ${MediaBreakpoints.skyscraper} {
        flex-direction: row;
    }

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: none;
    }
    
    ${({ active }) => active && buttonActiveStyle}

    &.active {
        ${buttonActiveStyle}
    }

    sup {
        font-size: 8px;
        margin-left: 3px;
    }
`;

const defaultProps: Partial<RoundedCornersButtonProps> = {
    defaultColor: Colors.mineShaft,
    margin: '0',
    lineHeight: 22,
}

export const RoundedCornersButton = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<RoundedCornersButtonProps>>(
    (props, ref) => {
        return (
            <Button {...defaultProps} {...props} ref={ref}>
                {props.children}
            </Button>
        );
    }
);
