import React from "react";

import * as SC from "./Extensions.styled";
import { ArrowRightIcon } from "../../Components/Icons";
import { MetaMenu } from "../../Components/MetaMenu/MetaMenu";
import { Pill } from "../../Components/Pill";
import { Tile } from "../../Components/Tile";
import { Colors } from "../../Styles";
import { GTMDataLayerPush } from "../../Util/GoogleTagManager";

type ExtensionsProps = {
    hasHeight?: boolean;
}

type Browser = {
    id: "edge" | "chrome" | "firefox" | "word";
    iconPath: string;
    alt: string;
    name: string;
    text: string;
    storeUrl: string;
}

const browsers: Browser[] = [
    {
        id: "edge",
        iconPath: "/assets/icons/logo-edge.svg",
        alt: "Edge Logo",
        name: "Microsoft Edge",
        text: "Mit unserer Browser-Erweiterung prüft der Mentor Ihre Texteingaben auf zahlreichen Internetseiten.",
        storeUrl: "https://microsoftedge.microsoft.com/addons/detail/dudenmentortextpr%C3%BCfung/bmggehagjjhlpcmpllhldmcopgiabnpe"
    },
    {
        id: "firefox",
        iconPath: "/assets/icons/logo-firefox.svg",
        alt: "Firefox Logo",
        name: "Mozilla Firefox",
        text: "Gewinnen Sie Sicherheit und korrigieren Sie Ihre Texte an Ort und Stelle im Browser von Mozilla.",
        storeUrl: "https://addons.mozilla.org/de/firefox/addon/duden-mentor-textpr%C3%BCfung/"
    },
    {
        id: "chrome",
        iconPath: "/assets/icons/logo-chrome.svg",
        alt: "Chrome Logo",
        name: "Google Chrome",
        text: "Auch in Chrome steht Ihnen der Duden-Mentor als Erweiterung stets zur Seite.",
        storeUrl: "https://chrome.google.com/webstore/detail/duden-mentor-textpr%C3%BCfung/jkennnbhkennnbeebfonckakbodfaldk?hl=de"
    }
];

const word: Browser = {
    id: "word",
    iconPath: "/assets/icons/logo-word.svg",
    alt: "Word Logo",
    name: "Microsoft Word",
    text: "Korrekturen und Stiltipps von Duden direkt in Word - das Mentor-Add-in macht es möglich.",
    storeUrl: "https://appsource.microsoft.com/de-de/product/office/WA200002017"
};

const getCurrentBrowserId = (): string => {
    let sUsrAg = navigator.userAgent;

    if (/Firefox\/[0-9]*.[0-9]/g.test(sUsrAg) && !(/Seamonkey\/[0-9]*.[0-9]/g.test(sUsrAg))) {
        return "firefox";
    } else if (sUsrAg.toLowerCase().indexOf("edg") > -1) {
        return "edge";
    } else if (/Chrome\/[0-9]*.[0-9]*/g.test(sUsrAg) && !(/OPR\/[0-9]*.[0-9]*/g.test(sUsrAg))) {
        return "chrome";
    }
    return "";
};

const isCurrentBrowser = (browserId: string): boolean => browserId === getCurrentBrowserId();

const getSortedBrowser = (): Browser[] => {
    const currentBrowserId = getCurrentBrowserId();
    const currentBrowserPosition = browsers.findIndex(b => b.id === currentBrowserId);
    const sortedBrowsers = [...browsers.slice(currentBrowserPosition), ...browsers.slice(0, currentBrowserPosition)];
    sortedBrowsers.push(word);

    return sortedBrowsers;
};

const Extensions: React.FC<ExtensionsProps> = ({ hasHeight }) => {
    return (
        <>
            <SC.StyledExtensionsListView hasHeight={hasHeight}>
                <h1>Erweiterungen</h1>
                <SC.StyledExtensionsList>
                    {getSortedBrowser().map(browser => (
                        <SC.StyledExtensionListItem key={browser.id}>
                            <Tile
                                heading={
                                    <>
                                        <img width="62" height="62" src={browser.iconPath} alt={browser.alt} />
                                        <h2>
                                            {`Duden-Mentor für ${browser.name}`}
                                            {isCurrentBrowser(browser.id) && <Pill color={Colors.grannySmithApple} fontSize={13}>Ihr aktueller Browser</Pill>}
                                        </h2>
                                    </>
                                }
                                backgroundColor={isCurrentBrowser(browser.id) ? `${Colors.mystic}` : `${Colors.bajaWhite}`}
                                button={<a className="dark" target="_blank" onClick={() => GTMDataLayerPush({ event: "select-extension-store", option: browser.id })} href={browser.storeUrl}
                                           rel="noreferrer">Installieren<ArrowRightIcon /></a>}
                            >
                                <p>{browser.text}</p>
                            </Tile>
                        </SC.StyledExtensionListItem>
                    ))}
                </SC.StyledExtensionsList>
            </SC.StyledExtensionsListView>
            <MetaMenu />
        </>
    );
};

export default Extensions;