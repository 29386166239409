import React from 'react';

import classNames from 'classnames';

import {AdviceType} from './Types';

type PremiumTeaserProps = {
    type: AdviceType;
    title: string;
    teaserText: JSX.Element;
}

export const PremiumTeaserElement: React.FC<PremiumTeaserProps> = (props) => {
    const cssClasses = classNames('advice__number', {
        'advice__number--synonym': props.type === AdviceType.spellCheck,
        'advice__number--readability': props.type === AdviceType.writingStyle,
    });

    return (
        <div className="advice advice--teaser">
            <span className={cssClasses}>{props.title.slice(0, 1).toUpperCase()}</span>
            <span className="advice__title">{props.title}</span>
            <div className="advice__teaser advice__teaser--inked">
                <span className="advice__teaser__text">{props.teaserText}</span>
                <div className="advice__teaser__pointer">◀</div>
            </div>
        </div>
    );
}
