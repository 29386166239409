import { EditorState } from "draft-js";
import actionCreatorFactory from "typescript-fsa";

import { OneClickEditorState } from "./State";
import { AdviceData, HistoryProps, SynonymListProps } from "../../Components/Advices/Util/Props";
import { HighlightSpellErrorPayload, OpenAdviceItemPayload, UserAdviceInteractionPayload } from "../AdviceState";

export interface HighlightPreviewTextPayload {
    original: string;
    replacement: string;
}

export interface HighlightEntityPayload {
    entityKey: string;
    previewText: HighlightPreviewTextPayload[];
}

export interface IgnoreAdviceItemPayload {
    adviceHistory: HistoryProps;
}

export interface EditorChangePayload {
    oldEditorState: EditorState;
    newEditorState: EditorState;
}

const padInteractionCreator = actionCreatorFactory("PAD");
export const editorChangedAction = padInteractionCreator<EditorChangePayload>("EDITOR_CHANGED");
export const rerenderDecorationsAction = padInteractionCreator<EditorState>("RERENDER_DECORATIONS");
export const correctErrorAction = padInteractionCreator<CorrectErrorPayload>("CORRECT_ERROR");
export const correctSynonymAction = padInteractionCreator<{ adviceHistory: Partial<CorrectErrorPayload['adviceHistory']> }>("CORRECT_SYNONYM");
export const highlightEntityAction = padInteractionCreator<HighlightEntityPayload>("HIGHLIGHT_SPELL_ERROR");
export const ignoreErrorAction = padInteractionCreator<IgnoreAdviceItemPayload>("IGNORE_DPF_ERROR");
export const openInlineAdviceItemAction = padInteractionCreator<OpenAdviceItemPayload>("OPEN_INLINE_ADVICE");
export const triggerOneClickAction = padInteractionCreator<any>("TRIGGER_ONE_CLICK");
export const correctOneClickAction = padInteractionCreator<any>("CORRECT_ONE_CLICK");
export const oneClickEditorChangedAction = padInteractionCreator<any>("ONE_CLICK_EDITOR_CHANGED");

export interface CheckSuccessfulPayload {
    data: {
        spellAdvices: AdviceData[];
        styleAdvices: AdviceData[];
    };
}

export interface CheckErrorPayload {
    data: string;
}

export interface CheckPendingPayload {
    textLength: number;
}

export interface CorrectErrorPayload {
    adviceHistory: HistoryProps;
}

export interface CorrectOneClickErrorPayload {
    checkResults: OneClickEditorState;
}

export interface CopyOneClickTextPayload {
    editorState: EditorState;
}

const textCheckCreator = actionCreatorFactory("TEXT_CHECK");
export const textCheckAction = textCheckCreator<{
    text: string;
    userInteraction?: UserAdviceInteractionPayload;
}>("INVOKE");
export const textCheckPendingAction = textCheckCreator<CheckPendingPayload>("PENDING");
export const textCheckReceivedAction = textCheckCreator<void>("RECEIVED");
export const textCheckSuccessfulAction = textCheckCreator<CheckSuccessfulPayload>("SUCCESSFUL");
export const textCheckErrorAction = textCheckCreator<CheckErrorPayload>("ERROR");

export interface UserActionPayload {
    text: string;
    key?: string;
    userInteraction?: UserAdviceInteractionPayload;
}

export interface SynonymHighlightPayload {
    entityKey?: string;
    replacement?: string;
}

export type SetInlineAdviceTimerPayload = NodeJS.Timer | number | undefined;

const padActionCreator = actionCreatorFactory("PAD_INTERACTION");
export const keyDownAction = padActionCreator<UserActionPayload>("KEY_DOWN");
export const textPastedAction = padActionCreator<UserActionPayload>("TEXT_PASTED");
export const userInteractionAction = padActionCreator<UserActionPayload>("USER_INTERACTION_WITH_ADVICE");
export const checkResultsExternallyChangedAction = padInteractionCreator<UserActionPayload>("RESULTS_CHANGED");
export const highlightAdviceSpellErrorAction = padActionCreator<HighlightSpellErrorPayload>("HIGHLIGHT_SPELL_ERROR");
export const wordHighlightedAction = padActionCreator<string>("WORD_HIGHLIGHTED");
export const resetPadAction = padActionCreator<number>("RESET_PAD");
export const updateDecoratorAction = padActionCreator<number>("UPDATE_DECORATOR");
export const setInlineAdviceTimerAction = padActionCreator<SetInlineAdviceTimerPayload>("INLINE_ADVICE_TIMER");
export const copyOneClickTextAction = padActionCreator<CopyOneClickTextPayload>("COPY_ONE_CLICK");

const synonymActionCreator = actionCreatorFactory("SYNONYM");
export const getSynonymsPendingAction = synonymActionCreator<void>("PENDING");
export const getSynonymsSuccessfulAction = synonymActionCreator<{ query?: string, list?: SynonymListProps[] }>("SUCCESSFUL");
export const getSynonymsCleanAction = synonymActionCreator("CLEAN");
export const getSynonymsFailedAction = synonymActionCreator<void>("FAILED");
// export const getSynonymOriginalWordAction = synonymActionCreator<string>("ORIGINAL_WORD");
export const highlightSynonymCorrectionAction = synonymActionCreator<SynonymHighlightPayload>("HIGHLIGHT_CORRECTION");

export interface PadCrashedPayload {
    data: string;
}

const appCrashedCreator = actionCreatorFactory("APP_CRASHED");
export const padCrashedAction = appCrashedCreator<PadCrashedPayload>("PAD_ERROR");
