import React from 'react';

import classNames from 'classnames';
import {ContentState} from 'draft-js';
import {useDispatch, useSelector} from 'react-redux';

import {
    selectSelectedAdviceItem,
    selectSpellAdvices,
    setSelectedAdviceItemAction,
} from '../../../Store/AdviceState';
import {
    highlightAdviceSpellErrorAction,
    highlightEntityAction,
    selectHighlightedSpellError,
    HighlightPreviewTextPayload,
} from '../../../Store/PadState';
import {selectUserFeatures} from '../../../Store/UserState';
import {gtmEventTypes, sendGTMEvent} from '../../../Util/GoogleTagManager';
import {useInlineAdviceItem} from '../../../Util/useInlineAdviceItem';
import {hasFeature} from '../../../Util/UserUtils';
import {AdviceData} from '../../Advices/Util/Props';

interface ComponentProps {
    offsetKey: string;
    entityKey: string;
    contentState: ContentState;
}

type Props = React.PropsWithChildren<ComponentProps>;

export const SpellError: React.FC<Props> = (props: Props) => {
    const highlightedEntity = useSelector(selectHighlightedSpellError);
    const userFeatures = useSelector(selectUserFeatures);
    const {openInlineAdviceItem, startInlineAdviceItemClosing} = useInlineAdviceItem();
    const selectedAdviceItem = useSelector(selectSelectedAdviceItem);
    const advices = useSelector(selectSpellAdvices);
    const dispatch = useDispatch();

    /**
     * getEntityKeyForHighlighting
     * @returns string
     */
    const getEntityKey = (): string => {
        const data = props.contentState.getEntity(props.entityKey).getData();
        if (data.firstOccurrenceEntityKey) {
            return data.firstOccurrenceEntityKey;
        }

        return props.entityKey;
    };

    /**
     * @returns {String}
     */
    const getPreviewText = (): String => {
        const previewText = highlightedEntity.previewText.filter((obj: HighlightPreviewTextPayload) => (
            props.offsetKey === obj.original.replace(/\r?\n|\r/g, '\n')
        ));
        if (previewText !== undefined && previewText[0] !== undefined) {
            return previewText[0].replacement;
        }
        return '';
    };

    /**
     * open item in advice panel with specified entity key
     */
    const onMouseClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        if (selectedAdviceItem !== undefined
            && selectedAdviceItem.adviceType === 'SPELL-ERROR'
            && advices[selectedAdviceItem.spellingIndex].entityKey === getEntityKey()) {
            return;
        }

        sendGTMEvent(gtmEventTypes.adviceExpanded);
        const index = advices.reduce(
            (indexInProgress: number | undefined, advice: AdviceData, arrayIndex: number) => (
                advice.entityKey === getEntityKey() ? arrayIndex : indexInProgress
            ),
            undefined,
        );
        dispatch(setSelectedAdviceItemAction(index === undefined ? undefined : {
            adviceType: 'SPELL-ERROR',
            index,
        }));
    };

    /**
     * adds css class to Advice item component with a specific entity key as id
     * and highlight all sibling SpellError Components
     */
    const onMouseEnter = () => {
        dispatch(highlightAdviceSpellErrorAction({entityKey: getEntityKey()}));
        dispatch(highlightEntityAction({entityKey: getEntityKey(), previewText: []}));

        openInlineAdviceItem(props.entityKey);
    };

    /**
     * remove css class from Advice item component with a specific entity key as id
     * and remove highlight from all sibling SpellError Components
     */
    const onMouseLeave = () => {
        dispatch(highlightAdviceSpellErrorAction({entityKey: ''}));
        dispatch(highlightEntityAction({entityKey: '', previewText: []}));

        startInlineAdviceItemClosing();
    };

    return (
        <mark
            className={classNames('pad__mark', {
                'pad__mark--inline-advice': hasFeature('inlineAdvices', userFeatures),
                'pad__mark--in-focus': (highlightedEntity.entityKey === getEntityKey() || (
                    selectedAdviceItem !== undefined
                    && selectedAdviceItem.adviceType === 'SPELL-ERROR'
                    && advices[selectedAdviceItem.spellingIndex] !== undefined
                    && advices[selectedAdviceItem.spellingIndex].entityKey === getEntityKey()
                )),
                'pad__mark--correct': (
                    highlightedEntity.entityKey === getEntityKey()
                    && highlightedEntity.previewText!.length > 0
                ),
            })}
            data-entity-key={props.entityKey}
            data-offset-key={props.offsetKey}
            data-highlight-entity-key={getEntityKey()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onMouseClick}
        >
            {highlightedEntity.previewText!.length > 0
            && highlightedEntity.entityKey === getEntityKey()
                ? getPreviewText()
                : props.children
            }
        </mark>
    );
};
