import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useCorrectionStore, { selectHighlightedAdvice } from './useCorrectionStore';
import {
    openInlineAdviceItemAction,
    // selectInlineAdviceEntityKey,
    selectInlineAdviceTimer,
    setInlineAdviceTimerAction,
} from '../Store/PadState';
import { selectUserFeatures } from '../Store/UserState';
import { hasFeature } from '../Util/UserUtils';
// import useCorrectionStore from './useCorrectionStore';

export const useCorrectionInlineAdviceItem = () => {
    // const selectedIndex = useCorrectionStore(store => store.selectedIndex);
    const highlightedAdvice = useCorrectionStore(selectHighlightedAdvice);
    // const inlineAdviceItemKey = useSelector(selectInlineAdviceEntityKey);
    const inlineAdviceItemTimer = useSelector(selectInlineAdviceTimer);
    const userFeatures = useSelector(selectUserFeatures);

    // const handleSetActiveIndex = useCorrectionStore(store => store.handleSetActiveIndex);

    const dispatch = useDispatch();
    const canUseInlineAdvices = hasFeature('inlineAdvices', userFeatures);

    const startInlineAdviceItemClosing = useCallback(() => {
        if (!canUseInlineAdvices) {
            return;
        }

        const timer = window.setTimeout(
            () => {
                dispatch(openInlineAdviceItemAction({ entityKey: '-1' }));
            },
            1000,
        );
        dispatch(setInlineAdviceTimerAction(timer));
    }, [canUseInlineAdvices, dispatch]);

    const stopInlineAdviceItemClosing = useCallback(() => {
        if (!canUseInlineAdvices) {
            return;
        }

        if (inlineAdviceItemTimer !== undefined) {
            window.clearTimeout(inlineAdviceItemTimer as number);
        }
        dispatch(setInlineAdviceTimerAction(undefined));
    }, [canUseInlineAdvices, dispatch, inlineAdviceItemTimer]);

    const openInlineAdviceItem = useCallback((entityKey: string) => {
        if (!canUseInlineAdvices) {
            return;
        }

        stopInlineAdviceItemClosing();
        dispatch(openInlineAdviceItemAction({ entityKey }));
    }, [canUseInlineAdvices, dispatch, stopInlineAdviceItemClosing]);

    const closeInlineAdviceItem = useCallback(() => {
        if (!canUseInlineAdvices) {
            return;
        }
        stopInlineAdviceItemClosing();
        dispatch(openInlineAdviceItemAction({ entityKey: '-1' }));
        // handleSetActiveIndex({ value: -1 });
    }, [canUseInlineAdvices, dispatch, stopInlineAdviceItemClosing]);

    return {
        inlineAdvice: highlightedAdvice,
        closeInlineAdviceItem,
        openInlineAdviceItem,
        startInlineAdviceItemClosing,
        stopInlineAdviceItemClosing,
    };
};
