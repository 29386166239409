import * as React from 'react';

import styled from 'styled-components';

import { Colors } from '../../Styles';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { PlatformTypes } from '../../Util/PlatformUtil';

const metaMenuLinks: Link[] = [
    {
        text: 'Impressum',
        href: `${process.env.REACT_APP_PUBLIC_URL}/impressum`,
    },
    {
        text: 'Datenschutz',
        href: `${process.env.REACT_APP_PUBLIC_URL}/datenschutzerklaerung`,
    },
    {
        text: 'AGB',
        href: `${process.env.REACT_APP_PUBLIC_URL}/agb`,
    },
    {
        text: 'Privacy Manager',
        href: `${process.env.REACT_APP_PUBLIC_URL}/#cmp`,
        onClick: (event) => {
            try {
                window._sp_?.loadPrivacyManagerModal?.(883462);
            } catch (_err) {
                console.error('_sp_ internal error!');
            }

            event.preventDefault();

            return false;
        },
    },
];

export interface Link {
    href: string;
    text: string;
    target?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const StyledMetaMenu = styled.nav`
    color: ${Colors.mineShaft};
    font-size: 11px;
    text-align: center;
    margin: auto 0 35px;
    padding: 10px;
`;

const MetaMenuItem = styled.a`
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const MetaMenu = () => {
    const isLiteVersion = DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.full;
    if (isLiteVersion) {
        return null;
    }
    const linkElements = metaMenuLinks.reduce<JSX.Element[]>(
        (links: JSX.Element[], link: Link, index: number) => {
            links.push(<MetaMenuItem href={link.href} key={index} target={link.target} onClick={link.onClick}>{link.text}</MetaMenuItem>);
            if (index < metaMenuLinks.length - 1) {
                links.push(<React.Fragment key={`divider-${index}`}> | </React.Fragment>);
            }
            return links;
        },
        [],
    );

    return (
        <StyledMetaMenu>{linkElements}</StyledMetaMenu>
    );
};
