import React from "react";
import { useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";

import * as SC from './AdvicePanel.styled';
import { ADVICE_TYPE, selectCheckStateFromMainState, selectSynonymList, selectTextLength } from "../../../Store/AdviceState";
import { selectUserStateFromMainState } from '../../../Store/UserState';
import { Colors } from "../../../Styles";
import { hasFeature } from '../../../Util/UserUtils';
import { PremiumTeaser } from "../../Advices/Util/PremiumTeaser";
import { LoadingDotsIcon } from "../../Icons";
import { Advice } from "../Advice";
import { AdviceHeader, StyledAdviceHeader } from "../AdviceHeader";
import { NoAdvice } from "../NoAdvice";
import { SynonymsCard } from "../SynonymsCard";
import { useAdviceLists } from "../useAdviceLists";

import "../../../Styles/component/authoring/advice/advice.sass";
import "../../../Styles/component/authoring/pad/pad.sass";
import "../../../Styles/component/authoring/tip/tip.sass";

const AdvicePanel: React.FC = () => {
    const [adviceCardType, setAdviceCardType] = useState<"SPELL-ERROR" | "STYLE-ERROR" | "SYNONYMS" | undefined>();
    const synonymList = useSelector(selectSynonymList);
    const isLoading = useSelector(selectCheckStateFromMainState).isLoading;
    const user = useSelector(selectUserStateFromMainState);
    const textLength = useSelector(selectTextLength);
    const {
        currentAdvice,
        spellAdvices,
        styleAdvices,
        nextAdvice,
        previousAdvice,
        setCurrentList
    } = useAdviceLists();

    const currentAdviceType = currentAdvice?.type;
    const isSynonymsActive = hasFeature('synonyms', user.features);

    const showSynonyms = isSynonymsActive && textLength > 0 && !isLoading;

    /**
     * show synonym card, when synonym list changed
     */
    useLayoutEffect(() => {
        setAdviceCardType(synonymList.length > 0 ? "SYNONYMS" : currentAdvice?.type);
    }, [synonymList]);

    /**
     * hide synonyms when current advice is changed (i.e. mouse click on advice in text)
     */
    useLayoutEffect(() => {
        setAdviceCardType(currentAdvice?.type);
    }, [currentAdvice, currentAdviceType]);

    const onClickSpellErrorCard = () => {
        if (currentAdvice?.type !== ADVICE_TYPE.SpellError) {
            setCurrentList(ADVICE_TYPE.SpellError);
        }
        setAdviceCardType(ADVICE_TYPE.SpellError);
    };
    const onClickStyleErrorCard = () => {
        if (currentAdvice?.type !== ADVICE_TYPE.StyleError) {
            setCurrentList(ADVICE_TYPE.StyleError);
        }
        setAdviceCardType(ADVICE_TYPE.StyleError);
    };

    const Loader = isLoading ? (
        <SC.LoadingIcon>
            <LoadingDotsIcon fill={Colors.mineShaft} />
        </SC.LoadingIcon>
    ) : null;


    const CurrentCard = isLoading ? null : !currentAdvice ? (<NoAdvice />) : adviceCardType !== "SYNONYMS" && (
        <Advice
            data={currentAdvice.advice}
            adviceType={currentAdvice.type}
            adviceHeader={
                <AdviceHeader
                    adviceType={currentAdvice.type}
                    index={currentAdvice.index}
                    length={currentAdvice.list.length}
                    onClickNext={nextAdvice}
                    onClickPrevious={previousAdvice}
                />
            }
        />
    );

    const AdditionalCards: JSX.Element[] = [];
    if (adviceCardType !== ADVICE_TYPE.SpellError && spellAdvices.length > 0) {
        AdditionalCards.push((
            <SC.AdditionalCards key={1}>
                <StyledAdviceHeader counterColor={Colors.supernova} onClick={onClickSpellErrorCard}>
                    Rechtschreibung und Grammatik
                    <span>{spellAdvices.length}</span>
                </StyledAdviceHeader>
            </SC.AdditionalCards>
        ));
    }
    if (adviceCardType !== ADVICE_TYPE.StyleError && styleAdvices.length > 0) {
        AdditionalCards.push((
            <SC.AdditionalCards key={2}>
                <StyledAdviceHeader counterColor={Colors.periwinkle} onClick={onClickStyleErrorCard}>
                    Stil und Vokabular
                    <span>{styleAdvices.length}</span>
                </StyledAdviceHeader>
            </SC.AdditionalCards>
        ));
    }

    return (
        <SC.Wrapper id="advice-panel" >
            {Loader}
            {CurrentCard}
            {AdditionalCards}
            {textLength > 0 && <PremiumTeaser />}
            {showSynonyms && <SynonymsCard />}
        </SC.Wrapper>
    );
};

export default AdvicePanel;