import React, { useCallback } from "react";

import * as SC from './CorrectionModeButtons.styled';
import useOneClickStore from '../../Hooks/useOneClickStore';
import { RoundedCornersButton } from '../Buttons';
import { ExpressOnboardingPopover } from "../Popover";

type CorrectionModeButtonsProps = {
    handleOneClickButton: React.MouseEventHandler<HTMLButtonElement>;
    handleClassicButton: React.MouseEventHandler<HTMLButtonElement>;
    isLiteMode?: boolean;
}

export const CorrectionModeButtons: React.FC<CorrectionModeButtonsProps> = ({
    handleClassicButton,
    handleOneClickButton,
    isLiteMode,
}) => {
    const isOneClickModeActive = useOneClickStore((store) => store.isOneClickModeActive);
    const [isExpressOnboarded, setIsExpressOnboarded] = useOneClickStore((store) =>[store.isExpressOnboarded, store.setIsExpressOnboarded]);

    const handleClosePopover = useCallback(() => {
        !isExpressOnboarded && setIsExpressOnboarded();
    }, [isExpressOnboarded, setIsExpressOnboarded]);

    const handleExpressClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
        handleClosePopover();
        handleOneClickButton(event)
    }, [handleClosePopover, handleOneClickButton]);

    return (
        <SC.ChangeEditorWrapper isLiteMode={isLiteMode}>
            <RoundedCornersButton
                active={!isOneClickModeActive}
                onClick={handleClassicButton}
            >
                Standard-Prüfung
            </RoundedCornersButton>
            <SC.PopoverWrapper>
                <RoundedCornersButton
                    active={isOneClickModeActive}
                    onClick={handleExpressClick}
                >
                    KI-Prüfung <sup>BETA</sup>
                </RoundedCornersButton>
                {!isExpressOnboarded && <ExpressOnboardingPopover handleClose={handleClosePopover} isLiteMode={isLiteMode} />}
            </SC.PopoverWrapper>
        </SC.ChangeEditorWrapper>
    );
};
