import { useEffect, useState } from 'react';

export enum RESOLUTIONS {
    tablet = 768,
    laptop = 1024,
    desktop = 1200,
    widescreen = 1440
}

export const useResize = () => {
    const [resolution, setResolution] = useState<number>(768);

    useEffect(() => {
        handleResize();

        typeof window !== 'undefined' &&
        window.addEventListener('resize', handleResize);
        return () => {
            typeof window !== 'undefined' &&
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = (): void => {
        if (typeof window === 'undefined') {
            return;
        }

        setResolution(window.innerWidth);
    };

    return {
        resolution,
        isMobile: resolution < RESOLUTIONS.tablet,
        isTablet:
            resolution >= RESOLUTIONS.tablet && resolution < RESOLUTIONS.laptop
    };
};

