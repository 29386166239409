import * as React from 'react';
import '../../Styles/component/ads/skyscraper/skyscraper.sass';
import {memo} from "react";

export const Skyscraper: React.FC = memo(() => {
    return (
        <aside className="skyscraper">
            <header className="skyscraper__label">Anzeige</header>
            <div id="div-gpt-ad-1407836145397-0"/>
        </aside>
    );
});
