import styled, { css } from 'styled-components';

import { Colors, Fonts } from "../../Styles";

export const Wrapper = styled.div`
    color: ${Colors.mineShaft};
    
    p {
        margin: 0;
        text-align: center;
    }
`

export const StatusTitle = styled.div<{ isCardView?: boolean }>`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin: 0 -10px;
    white-space: nowrap;
    
    ${({ isCardView }) => isCardView && css`
        padding-bottom: 5px;
        min-height: 31px;
    `}
`

export const Title = styled.p`
    padding-bottom: 10px;
    font-size: 26px;
    line-height: 31px;

    svg {
        margin-bottom: -5px
    }
`

export const Description = styled.p`
    padding-bottom: 10px;
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
    line-height: 25px;
`

export const InitTitle = styled.p`
    padding-bottom: 10px;
    font-family: ${Fonts.publico};
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
`

export const InitDescription = styled.p`
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;

    svg {
        margin: 0 4px -7px
    }
`
export const Info = styled.p`
    font-family: ${Fonts.publico};
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
`

export const Image = styled.img`
    display: block;
    margin: 10px auto 0;
`