import styled from "styled-components";

import { MediaBreakpoints } from "../../../Styles";

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  margin-top: 15px;
  right: 48px;
  gap: 8px;
    
  @media ${MediaBreakpoints.medium} {
    display: none;
  }
    
  .launcher__link {
    width: 40px;
  }
`;