import { AdviceData, HistoryProps, SynonymListProps } from "../../Components/Advices/Util/Props";

type ObjectValues<T> = T[keyof T];

export const ADVICE_TYPE = {
    SpellError: "SPELL-ERROR",
    StyleError: "STYLE-ERROR"
} as const;
export type AdviceType = ObjectValues<typeof ADVICE_TYPE>

export type SelectedAdviceItem = {
    spellingIndex: number;
    styleIndex: number;
    adviceType: AdviceType;
}

export type AdviceState = {
    hasErrors: boolean;
    errors: {};
    isLoading: boolean;
    spellAdvices: AdviceData[];
    styleAdvices: AdviceData[];
    resultHistory: HistoryProps[];
    textLength: number;
    highlightedSpellError: string;
    getSynonymsPending: boolean;
    synonymList: SynonymListProps[];
    synonymQuery: string;
    highlightedSynonym: {
        entityKey?: string;
        replacement?: string;
    };
    correctionCounter: number;
    selectedAdviceItem?: SelectedAdviceItem;
    openResultEntityKey?: string;
}
