import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    font-family: Theinhardt, sans-serif;
`;

export const Title = styled.div`
    margin: 20px 0 10px;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
`;

export const Message = styled.div`
    font-size: 20px;
    line-height: 26px;
    text-align: center;
`;
