import { CorrectionBlock, ERROR_CODE, type LLMError } from '../Hooks/useOneClickStore';

export const normalizeBlocks = (text: string, errors?: LLMError[], options?: { showTraces: boolean }) => {
    const { showTraces } = options ?? {};
    const blocks: CorrectionBlock[] = [];
    let sinitizedText = text;

    const sortedErrors = errors?.sort((a, b) => b.offset - a.offset || a.errorcode.localeCompare(b.errorcode)) ?? [];

    if (sortedErrors?.length) {
        sortedErrors.forEach((error) => {
            if (showTraces && error.errorcode === ERROR_CODE.Remove) {
                return;
            }

            if (!showTraces && error.errorcode === ERROR_CODE.Trace) {
                return;
            }

            const commonCopy = sinitizedText?.substring(error.offset + error.length);
            const markCopy = error.errorcode === ERROR_CODE.Trace ? error.proposals[0] : sinitizedText?.substring(error.offset, error.offset + error.length);

            if (!markCopy) return;

            commonCopy && blocks.unshift({
                type: 'common',
                copy: commonCopy,
            });

            blocks.unshift({
                type: error.errorcode,
                copy: markCopy,
            });

            sinitizedText = sinitizedText?.substring(0, error.offset);
        });

        sinitizedText && blocks.unshift({
            type: 'common',
            copy: sinitizedText,
        });
    } else {
        blocks.push({
            type: 'common',
            copy: sinitizedText,
        });
    }

    return blocks;
};