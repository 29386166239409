import styled, { css } from 'styled-components';

import { Colors } from '../../../Styles';

export const Wrapper = styled.div<{ showArrow?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 20px;
    background-color: ${Colors.supernova};
    min-width: 220px;
    gap: 15px;
    
    ${({ showArrow }) => showArrow && css`
        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-right: 10px solid ${Colors.supernova};
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            top: 30px;
            left: 0;
            transform: translate(-100%);
        }
    `}
`;

export const Content = styled.p`
    color: ${Colors.mineShaft};
    font-size: 15px;
    font-weight: 600;
    margin: 0;
`

export const CtaContainer = styled.div`
    display: flex;
    gap: 20px;
`

export const CloseButton = styled.button`
    position: absolute;
    border: none;
    background: transparent;
    padding: 0;
    top: 6px;
    right: 6px;
    cursor: pointer;
`;