import actionCreatorFactory from 'typescript-fsa';
import {DictionaryEntry} from './State';

const dictionaryFactory = actionCreatorFactory('DICTIONARY');
export const resetDictionaryCounterAction = dictionaryFactory<void>('RESET_COUNTER');

export interface GetDictionaryEntriesSuccessfulPayload {
    dictionaryEntries: DictionaryEntry[];
}

export interface DictionaryApiFailedPayload {
    errorMessage: string;
}

const getDictionaryEntriesFactory = actionCreatorFactory('GET_DICTIONARY_ENTRIES');
export const getDictionaryEntriesAction = getDictionaryEntriesFactory<void>('SUBMIT');
export const getDictionaryEntriesPendingAction = getDictionaryEntriesFactory<void>('PENDING');
export const getDictionaryEntriesFailedAction = getDictionaryEntriesFactory<DictionaryApiFailedPayload>('FAILED');
export const getDictionaryEntriesSuccessfulAction = getDictionaryEntriesFactory<GetDictionaryEntriesSuccessfulPayload>(
    'SUCCESSFUL',
);

export interface AddDictionaryEntryPayload {
    word: string;
    doNotCount?: boolean;
}

export interface AddDictionaryEntrySuccessfulPayload {
    success: boolean;
}

const addDictionaryEntryFactory = actionCreatorFactory('ADD_DICTIONARY_ENTRY');
export const addDictionaryEntryAction = addDictionaryEntryFactory<AddDictionaryEntryPayload>('SUBMIT');
export const addDictionaryEntryPendingAction = addDictionaryEntryFactory<void>('PENDING');
export const addDictionaryEntryFailedAction = addDictionaryEntryFactory<DictionaryApiFailedPayload>('FAILED');
export const addDictionaryEntrySuccessfulAction = addDictionaryEntryFactory<AddDictionaryEntrySuccessfulPayload>(
    'SUCCESSFUL',
);

export interface DeleteDictionaryEntryPayload {
    id: number;
}

const deleteDictionaryEntryFactory = actionCreatorFactory('DELETE_DICTIONARY_ENTRY');
export const deleteDictionaryEntryAction = deleteDictionaryEntryFactory<DeleteDictionaryEntryPayload>('SUBMIT');
export const deleteDictionaryEntryPendingAction = deleteDictionaryEntryFactory<void>('PENDING');
export const deleteDictionaryEntryFailedAction = deleteDictionaryEntryFactory<DictionaryApiFailedPayload>('FAILED');
export const deleteDictionaryEntrySuccessfulAction = deleteDictionaryEntryFactory<DeleteDictionaryEntryPayload>(
    'SUCCESSFUL',
);