import * as React from 'react';

export const FacebookIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <title>Facebook</title>
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M0 0H36V36H0z" transform="translate(-979 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(51)"/>
            <g transform="translate(-979 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(51) translate(3 3)">
                <mask id="l9qa1g1mxb" fill="#fff">
                    <path id="tqmrmzh0ca" d="M0.002 0.001L30 0.001 30 29.818 0.002 29.818z"/>
                </mask>
                <path fill="#1877F2" d="M30 15c0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 7.487 5.485 13.692 12.656 14.818V19.336H8.848V15h3.808v-3.305c0-3.76 2.24-5.836 5.666-5.836 1.641 0 3.358.293 3.358.293v3.692h-1.892c-1.863 0-2.444 1.156-2.444 2.342V15h4.16l-.665 4.336h-3.495v10.482C24.514 28.692 30 22.487 30 15" mask="url(#l9qa1g1mxb)"/>
            </g>
            <path fill="#FFF" d="M20.839 19.336L21.504 15h-4.16v-2.814c0-1.186.58-2.342 2.444-2.342h1.892V6.152s-1.717-.293-3.358-.293c-3.426 0-5.666 2.077-5.666 5.836V15H8.848v4.336h3.808v10.482c.764.12 1.547.182 2.344.182.797 0 1.58-.062 2.344-.182V19.336h3.495" transform="translate(-979 -3191) translate(0 869) translate(0 2202) translate(928 72) translate(0 48) translate(51) translate(3 3)"/>
        </g>
    </svg>
);
