import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {
    addDictionaryEntryFailedAction,
    addDictionaryEntryPendingAction,
    addDictionaryEntrySuccessfulAction,
    deleteDictionaryEntryFailedAction,
    deleteDictionaryEntryPendingAction,
    deleteDictionaryEntrySuccessfulAction,
    getDictionaryEntriesFailedAction,
    getDictionaryEntriesPendingAction,
    getDictionaryEntriesSuccessfulAction,
    resetDictionaryCounterAction,
    AddDictionaryEntrySuccessfulPayload,
    DeleteDictionaryEntryPayload,
    DictionaryApiFailedPayload,
    GetDictionaryEntriesSuccessfulPayload,
} from './Actions';
import {DictionaryEntry, State} from './State';

function getDictionaryEntriesPendingReducer(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
    };
}

function getDictionaryEntriesSuccessfulReducer(state: State, payload: GetDictionaryEntriesSuccessfulPayload): State {
    return {
        ...state,
        isLoading: false,
        dictionaryEntries: [...payload.dictionaryEntries],
    };
}

function getDictionaryEntriesFailedReducer(state: State, payload: DictionaryApiFailedPayload): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: payload.errorMessage,
    };
}

function addDictionaryEntryPendingReducer(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
    };
}

function addDictionaryEntrySuccessfulReducer(state: State, payload: AddDictionaryEntrySuccessfulPayload): State {
    return {
        ...state,
        dictionaryEntries: [
            ...state.dictionaryEntries,
            payload.entry,
        ],
        isLoading: false,
        addedEntries: true,
    };
}

function addDictionaryEntryFailedReducer(state: State, payload: DictionaryApiFailedPayload): State {
    return {
        ...state,
        errorMessage: payload.errorMessage,
        hasError: true,
        isLoading: false,
    };
}

function deleteDictionaryEntryPendingReducer(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
    };
}

function deleteDictionaryEntrySuccessfulReducer(state: State, payload: DeleteDictionaryEntryPayload): State {
    return {
        ...state,
        isLoading: false,
        dictionaryEntries: state.dictionaryEntries.filter((entry: DictionaryEntry) => entry.id !== payload.id),
    };
}

function deleteDictionaryEntryFailedReducer(state: State, payload: DictionaryApiFailedPayload): State {
    return {
        ...state,
        isLoading: false,
        errorMessage: payload.errorMessage,
        hasError: true,
    };
}

function resetDictionaryCounterReducer(state: State): State {
    return {
        ...state,
        addedEntries: false,
    };
}

export const initialState: State = {
    dictionaryEntries: [],
    isLoading: false,
    hasError: false,
    errorMessage: '',
    addedEntries: false,
};

export const reducer = reducerWithInitialState(initialState)
    .case(getDictionaryEntriesPendingAction, getDictionaryEntriesPendingReducer)
    .case(getDictionaryEntriesSuccessfulAction, getDictionaryEntriesSuccessfulReducer)
    .case(getDictionaryEntriesFailedAction, getDictionaryEntriesFailedReducer)
    .case(addDictionaryEntryPendingAction, addDictionaryEntryPendingReducer)
    .case(addDictionaryEntrySuccessfulAction, addDictionaryEntrySuccessfulReducer)
    .case(addDictionaryEntryFailedAction, addDictionaryEntryFailedReducer)
    .case(deleteDictionaryEntryPendingAction, deleteDictionaryEntryPendingReducer)
    .case(deleteDictionaryEntrySuccessfulAction, deleteDictionaryEntrySuccessfulReducer)
    .case(deleteDictionaryEntryFailedAction, deleteDictionaryEntryFailedReducer)
    .case(resetDictionaryCounterAction, resetDictionaryCounterReducer);
