import 'es6-shim';
import 'react-app-polyfill/ie11';
import 'nodelist-foreach-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'library/src/Styles/BaseStyles';
import { App } from './App';
import { store } from './Store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FontFacesTheinhardt } from 'library/src/Styles/Font/Theinhardt/Theinhardt';

window.addEventListener(
    'touchstart',
    function onFirstTouch(): void {
        document.body.classList.add('user__can-touch');
        window.removeEventListener('touchstart', onFirstTouch, false);
    },
    false
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            initialDataUpdatedAt: 0,
            retry: 0,
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider contextSharing client={queryClient}>
            <FontFacesTheinhardt />
            <App />
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
