import React, { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import * as SC from './LoginForm.styled';
import { apiGetToken } from '../../Util/RequestApi';
import validation from '../../Util/validation';
import { LoadingDotsIcon } from '../Icons';
import { Input, InputAppearance } from '../Input';
import { IconKnob, Knob, KnobAppearance, KnobSize } from '../Knob';
import { Link } from '../Link';

export type UserLogin = {
    EmailAddress: string;
    Password: string;
}

const RESET_PASSWORD_HREF = '/user/password';
const REGISTRATION_HREF = '/user/register?source=duden-mentor';

const handleRegisterClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    window.location.assign(`${REGISTRATION_HREF}`);
};

const LoginForm: React.FC = () => {
    const history = useHistory();
    const { register, handleSubmit, formState } = useForm<UserLogin>({
        mode: 'onChange',
    });

    const emailErrorMessage = formState.errors.EmailAddress?.message;
    const passwordErrorMessage = formState.errors.Password?.message;

    const [isLoading, setIsLoading] = useState(false);
    // const [,setNotification] = useAtom(notificationAtom);

    const handleUserError = useCallback(
        () => {
            // setNotification({
            //   message: intl.formatMessage({ id: 'login.invalid.error' })
            // });
            //
            setIsLoading(false);
        },
        [],
    );

    const onSubmit: SubmitHandler<UserLogin> = (data) => {
        void loginUser(data);
    };

    const loginUser = async (userData: UserLogin) => {
        setIsLoading(true);

        try {
            const response = await apiGetToken({
                email: userData.EmailAddress,
                password: userData.Password,
            });

            if (response?.accessToken && response?.refreshToken) {
                history.push('/');
            }
        } catch {
            handleUserError();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <SC.FormWrapper>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <SC.FieldWrapper>
                        <Input
                            type="email"
                            label="E-Mail-Adresse"
                            {...register('EmailAddress', {
                                required: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
                                pattern: {
                                    value: validation.EMAIL,
                                    message: 'Dies ist keine gültige E-Mail-Adresse.',
                                },
                            })}
                            appearance={
                                emailErrorMessage ? InputAppearance.Error : InputAppearance.Default
                            }
                            message={emailErrorMessage}
                        />
                    </SC.FieldWrapper>
                    <SC.FieldWrapper>
                        <Input
                            label="Passwort"
                            {...register('Password', {
                                required: 'Bitte geben Sie ein Passwort ein.',
                            })}
                            type="password"
                            placeholder="********"
                            appearance={
                                passwordErrorMessage ? InputAppearance.Error : InputAppearance.Default
                            }
                            message={passwordErrorMessage}
                        />
                    </SC.FieldWrapper>

                    <SC.CtaWrapper>
                        <Knob
                            appearance={KnobAppearance.Contrasted}
                            type="submit"
                        >
                            Anmelden →
                        </Knob>
                        <SC.ResetPassword>
                            <Link href={RESET_PASSWORD_HREF}>
                                Passwort vergessen?
                            </Link>
                        </SC.ResetPassword>
                    </SC.CtaWrapper>
                </form>
            </SC.FormWrapper>

            <SC.Hr />

            <SC.FormWrapper>
                <SC.RegisterLinkLabel>Sie haben noch kein Benutzerkonto?</SC.RegisterLinkLabel>
                <SC.RegisterLink>
                    <IconKnob
                        size={KnobSize.Small}
                        appearance={KnobAppearance.Primary}
                        icon="→"
                        onClick={handleRegisterClick}
                    >
                        Jetzt registrieren
                    </IconKnob>
                </SC.RegisterLink>

                {isLoading && <LoadingDotsIcon />}
            </SC.FormWrapper>
        </>
    );
};

export default LoginForm;
