import * as React from 'react';

import {EditorState} from 'draft-js';
import {connect} from 'react-redux';

import {Phase} from '../Components/Advices/Util/Phase';
import {PhaseTypes} from '../Components/Advices/Util/Types';
import {selectCheckStateFromMainState, selectErrors} from '../Store/AdviceState';
import {selectCrashes, selectEditorState, textCheckAction, textCheckPendingAction} from '../Store/PadState';

/**
 * Interface for Component State
 */
interface OwnState {
    hasErrors: boolean;
    reloadInterval: number;
}

/**
 * * Interface for Component Props
 */
interface DispatchProps {
    editorState: EditorState;
    errors: Record<string, any>;
    crashes: Record<string, any>;
    isLoading: boolean;
}

interface DispatchActions {
    textCheckAction: typeof textCheckAction;
    textCheckPendingAction: typeof textCheckPendingAction;
}

export type OwnProps = DispatchProps & DispatchActions;

/**
 * This class wraps react errors, so that the whole app won't break
 */
export class AdviceError extends React.Component<React.PropsWithChildren<OwnProps>, OwnState> {

    public constructor(props: OwnProps) {
        super(props);

        this.state = {
            hasErrors: false,
            reloadInterval: 1000 * 5, // 5 seconds
        };
    }
    /**
     * When this method exists errors will be caught
     * @param error
     * @param info
     */
    public componentDidCatch(error: Error, info: React.ErrorInfo): void  {
        console.error('A rendering error was caught', error);
        this.setState({
            hasErrors: true,
        });
    }

    /**
     * handles recoverable errors on button click
     * send textCheckAction() again
     */
    private handleTextCheckClick = (): void => {
        const text = this.props.editorState.getCurrentContent().getPlainText();
        this.props.textCheckPendingAction({textLength: text.length});
        this.props.textCheckAction({text});
    }

    /**
     * handles app crash
     * reload browser window
     */
    private handleReloadClick = (): void => {
        window.location.reload();
    }

    /**
     * Renders error message when a severe React Dom Error was caught. This should actually never happen.
     * @returns {JSX.Element}
     */
    public render(): JSX.Element {
        // App crashed (Advices or Pad).
        if (this.state.hasErrors || Object.keys(this.props.crashes).length > 0) {
            return (
                <div className="phase">
                    <span className="phase__status">⚠️</span>
                    {' '}
                    <span className="phase__text">
                        Unser Service ist aktuell leider nicht erreichbar.<br />
                        <button className="phase__action knob knob--small" onClick={this.handleReloadClick}>Neu laden</button>
                    </span>
                </div>
            );
        }

        // Recoverable errors found.
        if (Object.keys(this.props.errors).length > 0) {
            const json = this.props.errors;

            if (json.errorCode === 'maximum_characters_exceeded') {
                return (
                    <div className="phase">
                        <span className="phase__status">⚠️</span>
                        {' '}
                        <span className="phase__text">
                            Sie wurden automatisch abgemeldet.<br />
                            Bitte melden sie sich erneut an.
                        </span>
                    </div>
                );
            }

            return (
                <>
                {this.props.isLoading &&
                    <Phase type={PhaseTypes.revolver} text="Text wird geprüft…"/>
                }
                {!this.props.isLoading &&
                    <div className="phase">
                        <span className="phase__status">⚠️</span>
                        {' '}
                        <span className="phase__text">
                            Unser Prüfservice ist aktuell leider nicht erreichbar.<br/>
                            <button className="phase__action knob knob--small" onClick={this.handleTextCheckClick}>Text erneut prüfen</button>
                        </span>
                    </div>
                }
                </>
            );
        }

        return (<>{this.props.children}</>);
    }
}

// TODO: FullVersion State no longer exists is resolved, when converted to functional component
// tslint:disable-next-line:no-any
const mapStateToProps = (store: any): DispatchProps => ({
    editorState: selectEditorState(store),
    errors: selectErrors(store),
    crashes: selectCrashes(store),
    isLoading: selectCheckStateFromMainState(store).isLoading,
});

const dispatchMap: DispatchActions = {
    textCheckAction,
    textCheckPendingAction,
};

export const AdviceErrorWrapper = connect(mapStateToProps, dispatchMap)(AdviceError);
