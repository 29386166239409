import { useLayoutEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AdviceType, selectSelectedAdviceItem, selectSpellAdvices, selectStyleAdvices, setSelectedAdviceItemAction } from "../../Store/AdviceState";
import { GTMDataLayerPush } from "../../Util/GoogleTagManager";
import { AdviceData } from "../Advices/Util/Props";

interface UseAdviceLists {
    spellAdvices: AdviceData[];
    styleAdvices: AdviceData[];
    nextAdvice: () => void;
    previousAdvice: () => void;
    setCurrentList: (adviceList: "SPELL-ERROR" | "STYLE-ERROR") => void;
    currentAdvice?: {
        advice: AdviceData;
        index: number;
        type: AdviceType;
        list: AdviceData[];
    };
}

export const useAdviceLists = (): UseAdviceLists => {
    const dispatch = useDispatch();
    const spellAdviceList = useSelector(selectSpellAdvices);
    const styleAdviceList = useSelector(selectStyleAdvices);
    const selectedAdvice = useSelector(selectSelectedAdviceItem);

    /**
     * switch advice list, when current advice list is empty
     * switch advice list, when no advice was selected, but one list is not empty
     */
    useLayoutEffect(() => {
        // both lists are empty => no advice selected
        if (selectedAdvice !== undefined && spellAdviceList.length === 0 && styleAdviceList.length === 0) {
            dispatch(setSelectedAdviceItemAction(undefined));
            return;
        }

        // none selected and at least one list is not empty
        if (!selectedAdvice && (spellAdviceList.length > 0 || styleAdviceList.length > 0)) {
            dispatch(setSelectedAdviceItemAction({ adviceType: spellAdviceList.length > 0 ? "SPELL-ERROR" : "STYLE-ERROR", index: 0 }));
            return;
        }

        // change list, when one list is empty
        const currentAdviceList = selectedAdvice?.adviceType === "SPELL-ERROR" ? spellAdviceList : styleAdviceList;
        if (selectedAdvice !== undefined && currentAdviceList.length === 0) {
            dispatch(setSelectedAdviceItemAction({
                adviceType: selectedAdvice.adviceType === "SPELL-ERROR" ? "STYLE-ERROR" : "SPELL-ERROR",
                index: selectedAdvice.adviceType === "SPELL-ERROR" ? selectedAdvice.styleIndex : selectedAdvice.spellingIndex
            }));
            return;
        }

        // checks if selected advice is in current advice list range
        if (selectedAdvice !== undefined && getSelectedAdviceIndex()! >= currentAdviceList.length) {
            dispatch(setSelectedAdviceItemAction({ adviceType: selectedAdvice.adviceType, index: currentAdviceList.length - 1 }));
            return;
        }
    }, [spellAdviceList, styleAdviceList]);

    /**
     * set next advice as selected
     */
    const nextAdvice = () => {
        if (!selectedAdvice || getSelectedAdviceIndex()! + 1 >= currentAdviceList.length) {
            return;
        }
        GTMDataLayerPush({ event: "navigate-advices", "advice-type": selectedAdvice.adviceType === "SPELL-ERROR" ? "spelling" : "style", location: "advice-panel" });
        dispatch(setSelectedAdviceItemAction({ adviceType: selectedAdvice.adviceType, index: getSelectedAdviceIndex()! + 1 }));
    };

    /**
     * set previous advice as selected
     */
    const previousAdvice = () => {
        if (!selectedAdvice || getSelectedAdviceIndex()! - 1 < 0) {
            return;
        }
        GTMDataLayerPush({ event: "navigate-advices", "advice-type": selectedAdvice.adviceType === "SPELL-ERROR" ? "spelling" : "style", location: "advice-panel" });
        dispatch(setSelectedAdviceItemAction({ adviceType: selectedAdvice.adviceType, index: getSelectedAdviceIndex()! - 1 }));
    };

    const setCurrentList = (adviceList: "SPELL-ERROR" | "STYLE-ERROR") => {
        GTMDataLayerPush({ event: "advice-type-selection", "advice-type": adviceList === "SPELL-ERROR" ? "spelling" : "style", location: "advice-panel" });
        dispatch(setSelectedAdviceItemAction({
            adviceType: adviceList,
            index: adviceList === "SPELL-ERROR" ? (selectedAdvice?.spellingIndex || 0) : (selectedAdvice?.styleIndex || 0)
        }));
    };

    /**
     * returns selected Advice index by advice type
     */
    const getSelectedAdviceIndex = () => (
        selectedAdvice && (selectedAdvice.adviceType === "SPELL-ERROR" ? selectedAdvice.spellingIndex : selectedAdvice.styleIndex)
    );

    /**
     * check if an advice is not selected
     */
    if (!selectedAdvice) {
        return { spellAdvices: spellAdviceList, styleAdvices: styleAdviceList, nextAdvice, previousAdvice, setCurrentList };
    }

    /**
     * check if selected advice is in the list of advices
     */
    const currentAdviceList = selectedAdvice.adviceType === "SPELL-ERROR" ? spellAdviceList : styleAdviceList;
    if (currentAdviceList.length === 0 || getSelectedAdviceIndex()! >= currentAdviceList.length) {
        return { spellAdvices: spellAdviceList, styleAdvices: styleAdviceList, nextAdvice, previousAdvice, setCurrentList };
    }

    return {
        currentAdvice: {
            list: currentAdviceList,
            advice: currentAdviceList[getSelectedAdviceIndex()!],
            index: getSelectedAdviceIndex()!,
            type: selectedAdvice.adviceType
        },
        spellAdvices: spellAdviceList,
        styleAdvices: styleAdviceList,
        nextAdvice,
        previousAdvice,
        setCurrentList
    };
};
