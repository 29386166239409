import React from 'react';

export const ClosedEyeIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <path
      d="M18 10.25c5.026 0 9.562 2.443 13.577 7.27a.75.75 0 0 1 0 .96 26.606 26.606 0 0 1-2.505 2.64l2.045 2.953a.75.75 0 0 1-1.164.94l-.07-.086-1.957-2.828a18.338 18.338 0 0 1-2.96 1.932l.004.007 1.25 4.25a.75.75 0 0 1-1.404.52l-.036-.096-1.19-4.047c-1.402.566-2.85.911-4.343 1.034l.003.051v3.75a.75.75 0 0 1-1.493.102l-.007-.102v-3.752a14.78 14.78 0 0 1-3.9-.584l-.611 3.466a.75.75 0 0 1-1.489-.159l.011-.101.654-3.703a17.018 17.018 0 0 1-3.552-1.973L7.16 25.856a.75.75 0 0 1-1.363-.619l.043-.093 1.75-3.25a.753.753 0 0 1 .083-.124 25.42 25.42 0 0 1-3.25-3.29.75.75 0 0 1 0-.96c4.015-4.827 8.55-7.27 13.577-7.27zm0 1.5c-4.301 0-8.23 1.99-11.812 6.019L5.985 18l.203.231c3.48 3.914 7.287 5.904 11.444 6.014l.368.005c4.301 0 8.23-1.99 11.812-6.019l.202-.231-.202-.231c-3.48-3.914-7.287-5.904-11.444-6.014z"
      fill="inherit"
      fillRule="evenodd"
    />
  </svg>
);
