import React from "react";

import classNames from "classnames";
import { ContentState } from "draft-js";
import { useDispatch, useSelector } from "react-redux";

import { ADVICE_TYPE, selectSelectedAdviceItem, selectStyleAdvices, setSelectedAdviceItemAction } from "../../../Store/AdviceState";
import {
    highlightAdviceSpellErrorAction,
    highlightEntityAction,
    selectHighlightedSpellError,
    HighlightPreviewTextPayload
} from "../../../Store/PadState";
import { selectUserFeatures } from "../../../Store/UserState";
import { gtmEventTypes, sendGTMEvent } from "../../../Util/GoogleTagManager";
import { useInlineAdviceItem } from "../../../Util/useInlineAdviceItem";
import { hasFeature } from "../../../Util/UserUtils";
import { AdviceData } from "../../Advices/Util/Props";

type StyleErrorProps = {
    offsetKey: string;
    entityKey: string;
    contentState: ContentState;
    children?: React.ReactNode;
}

export const StyleError: React.FC<StyleErrorProps> = (props) => {
    const userFeatures = useSelector(selectUserFeatures);
    const highlightedEntity = useSelector(selectHighlightedSpellError);
    const selectedAdviceItem = useSelector(selectSelectedAdviceItem);
    const { startInlineAdviceItemClosing, openInlineAdviceItem } = useInlineAdviceItem();
    const advices = useSelector(selectStyleAdvices);
    const dispatch = useDispatch();

    /**
     * getEntityKeyForHighlighting
     */
    const getEntityKey = (): string => {
        const data = props.contentState.getEntity(props.entityKey).getData();
        if (data.firstOccurrenceEntityKey) {
            return data.firstOccurrenceEntityKey;
        }

        return props.entityKey;
    };

    const getEntityKeyForPreview = (): string => {
        const data = props.contentState.getEntity(props.entityKey).getData();
        if (data.firstOccurrenceEntityKey && advices.filter(advice => advice.entityKey === props.entityKey && advice.errorCode === "c006").length === 0) {
            return data.firstOccurrenceEntityKey;
        }

        return props.entityKey;
    };

    /**
     * @returns {String}
     */
    const getPreviewText = (): String => {
        const previewText = highlightedEntity.previewText.filter((obj: HighlightPreviewTextPayload) => (
            props.offsetKey === obj.original.replace(/\r?\n|\r/g, "\n")
        ));
        if (previewText !== undefined && previewText[0] !== undefined) {
            return previewText[0].replacement;
        }
        return "";
    };

    /**
     * open item in advice panel with specified entity key
     */
    const onMouseClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        if (selectedAdviceItem
            && selectedAdviceItem.adviceType === ADVICE_TYPE.StyleError
            && advices[selectedAdviceItem.styleIndex].entityKey === getEntityKey()) {
            return;
        }

        sendGTMEvent(gtmEventTypes.adviceExpanded);
        const index = advices.reduce(
            (indexInProgress: number | undefined, advice: AdviceData, arrayIndex: number) => (
                advice.entityKey === getEntityKey() ? arrayIndex : indexInProgress
            ),
            undefined
        );
        dispatch(setSelectedAdviceItemAction(index === undefined ? undefined : {
            adviceType: ADVICE_TYPE.StyleError,
            index
        }));
    };

    /**
     * adds css class to Advice item component with a specific entity key as id
     * and highlight all sibling SpellError Components
     */
    const onMouseEnter = () => {
        dispatch(highlightAdviceSpellErrorAction({ entityKey: getEntityKey() }));
        dispatch(highlightEntityAction({ entityKey: getEntityKey(), previewText: [] }));

        openInlineAdviceItem(props.entityKey);
    };

    /**
     * remove css class from Advice item component with a specific entity key as id
     * and remove highlight from all sibling SpellError Components
     */
    const onMouseLeave = () => {
        dispatch(highlightAdviceSpellErrorAction({ entityKey: "" }));
        dispatch(highlightEntityAction({ entityKey: "", previewText: [] }));

        startInlineAdviceItemClosing();
    };

    return (
        <mark
            className={classNames("pad__mark", "pad__mark--readability", {
                "pad__mark--inline-advice": hasFeature("inlineAdvices", userFeatures),
                "pad__mark--in-focus": (highlightedEntity.entityKey === getEntityKey() || (
                    selectedAdviceItem !== undefined
                    && selectedAdviceItem.adviceType === ADVICE_TYPE.StyleError
                    && advices[selectedAdviceItem.styleIndex] !== undefined
                    && advices[selectedAdviceItem.styleIndex].entityKey === getEntityKey()
                )),
                "pad__mark--correct": (
                    highlightedEntity.entityKey === getEntityKeyForPreview()
                    && highlightedEntity.previewText!.length > 0
                )
            })}
            data-entity-key={props.entityKey}
            data-highlight-entity-key={getEntityKey()}
            data-offset-key={props.offsetKey}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onMouseClick}
        >
            {highlightedEntity.previewText!.length > 0
            && highlightedEntity.entityKey === getEntityKeyForPreview()
                ? getPreviewText()
                : props.children
            }
        </mark>
    );
};
