import React from 'react';

import classNames from "classnames";
import { useSelector } from "react-redux";
import styled from 'styled-components';

import useOneClickStore from "../../Hooks/useOneClickStore";
import { selectUserFeatures } from "../../Store/UserState";
import { Colors, MediaBreakpoints } from "../../Styles";
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { hasFeature } from "../../Util/UserUtils";
import { Skyscraper } from "../Ads/Skyscraper";
import { AppMenu } from "../AppMenu/AppMenu";

const Sidebar = styled.aside`
    display: none;
    width: 80px;
    margin-top: 70px;
    max-height: 100%;

    @media ${MediaBreakpoints.medium} {
        display: block;
        max-height: 44%;
        z-index: 5;
    }    
    
    @media ${MediaBreakpoints.wide} {
        display: block;
        max-height: 100%;
    }
`;

const Advertisement = styled.aside`
    display: none;
    background-color: ${Colors.mystic};
    max-height: 100%;
    padding: 50px 10px;
    order: 2;

    @media ${MediaBreakpoints.skyscraper} {
        display: block;
    }
`;

const MainContent = styled.main`
    background: ${Colors.white};    
    flex: 0 1 1265px;
    order: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; // adjusting profiler button and overlay

    @media ${MediaBreakpoints.medium} {
        margin-left: -80px;
        z-index: 2;
    }

    @media ${MediaBreakpoints.wide} {
        margin-left: 0;
        overflow: hidden;
    }
`;

const StyledDeskWrapper = styled.div`
    background: linear-gradient(to right, ${Colors.white} 0%, ${Colors.white} 60%, ${(p: {columnColor: string}) => p.columnColor} 60%);
    display: flex;
    height: calc(100vh - 60px); 
    align-content: stretch;
    justify-content: center;
    width: 100vw;

    &.lite {
        height: 100vh
    }
`;

type DeskWrapperProps = {
    children?: React.ReactNode;
}

export const DeskWrapper: React.FC<DeskWrapperProps> = (props) => {
    const features = useSelector(selectUserFeatures);
    const noAdvertisements = hasFeature("noAdvertisements", features);
    const isLiteVersion = DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.full;
    const isOneClickModeActive = useOneClickStore((store) => store.isOneClickModeActive);

    return (
        <>
            <StyledDeskWrapper
                className={classNames("", {"lite": isLiteVersion})}
                columnColor={isOneClickModeActive ? `${Colors.cottonBoll}` : `${Colors.mystic}`}
            >
                {!isLiteVersion && (
                    <>
                        <Sidebar>
                            <AppMenu/>
                        </Sidebar>

                        {!noAdvertisements && (
                            <Advertisement>
                                <Skyscraper/>
                            </Advertisement>
                        )}
                    </>
                )}

                <MainContent>
                    {props.children}
                </MainContent>
            </StyledDeskWrapper>
        </>
    )
}
