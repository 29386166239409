import {AdviceData} from './Props';
import {HistoryType} from './Types';
import {
    CorrectErrorPayload,
    HighlightPreviewTextPayload,
    IgnoreAdviceItemPayload,
} from '../../../Store/PadState';

export function getPreviewForProposal(
    advice: AdviceData,
    proposal: string,
): HighlightPreviewTextPayload[] {
    let text = proposal;
    const firstOccurrenceEntityKey = advice.firstOccurrenceEntityKey as string;
    const previewText: HighlightPreviewTextPayload[] = [];

    getPadMarks(firstOccurrenceEntityKey).reverse().map(
        (obj: HTMLElement, index: number, original: HTMLElement[]) => {
            if (index === original.length - 1 || advice.occurrences.length > 1) {
                previewText.push({
                    original: obj.dataset.offsetKey!,
                    replacement: text,
                });
            } else {
                const replace = text.slice(
                    text.indexOf(obj.innerText),
                    text.length,
                );
                text = text.replace(replace, '');
                previewText.push({
                    original: obj.dataset.offsetKey!,
                    replacement: replace,
                });
            }
        });

    return previewText;

}

export function getPreviewForSynonym(
    entityKey: string,
    proposal: string,
): HighlightPreviewTextPayload[] {
    let text = proposal;
    const previewText: HighlightPreviewTextPayload[] = [];

    getPadMarks(entityKey).reverse().map(
        (obj: HTMLElement, index: number, original: HTMLElement[]) => {
            if (index === original.length - 1) {
                previewText.push({
                    original: obj.dataset.offsetKey!,
                    replacement: text,
                });
            } else {
                const replace = text.slice(
                    text.indexOf(obj.innerText),
                    text.length,
                );
                text = text.replace(replace, '');
                previewText.push({
                    original: obj.dataset.offsetKey!,
                    replacement: replace,
                });
            }
        });

    return previewText;

}

/**
 * Collects an array of Entity marks and returns it filtered by entityKey
 * @returns {HTMLElement[]}
 */
export function getPadMarks(entityKey: string): HTMLElement[] {
    const list: HTMLElement[] = Array.from(document.getElementsByTagName('mark'));

    return list.filter((obj: HTMLElement) =>
        obj.attributes.getNamedItem('data-highlight-entity-key')!.value === entityKey,
    );
}

export function getCorrectedErrorPayload(advice: AdviceData, proposal: string): CorrectErrorPayload {
    return {
        adviceHistory: {
            errorCode: advice.errorCode,
            offset: advice.offset,
            length: advice.length,
            entityKey: advice.entityKey!,
            originalError: advice.originalError,
            proposals: advice.proposals,
            correctedText: proposal,
            errorType: advice.type,
            historyType: HistoryType.Corrected,
            errorMessage: advice.errorMessage,
            occurrences: advice.occurrences,
            firstOccurrenceEntityKey: advice.firstOccurrenceEntityKey,
        },
    };
}

export function getIgnoreErrorPayload(advice: AdviceData): IgnoreAdviceItemPayload {
    return {
        adviceHistory: {
            errorCode: advice.errorCode,
            offset: advice.offset,
            length: advice.length,
            entityKey: advice.entityKey!,
            originalError: advice.originalError,
            proposals: advice.proposals,
            correctedText: advice.originalError,
            errorType: advice.type,
            historyType: HistoryType.Ignored,
            errorMessage: advice.errorMessage,
            occurrences: advice.occurrences,
            firstOccurrenceEntityKey: advice.firstOccurrenceEntityKey,
        },
    };
}

export function showDictionaryIcon(result: AdviceData, hasDictionary: boolean): boolean {
    const allowedErrorCodes = [
        '1',
        '2',
        '5',
        '13',
        '16',
        '21',
        '22',
        '27',
        '28',
        '30',
        '32',
        '43',
        '130',
        '201',
        '202',
        '229',
        '230',
        '233',
        '234',
        '235',
        '1981',
    ];

    return result.type === 'orth' && allowedErrorCodes.includes(result.errorCode) && hasDictionary;
}
