import styled, { css } from 'styled-components';

import { KnobAppearance, KnobSize } from './Knob';
import { Colors, Fonts } from '../../Styles';

type ButtonStyledProps = {
  appearance?: KnobAppearance | boolean;
  size?: KnobSize;
};

const frameStyles = css`
  background-color: inherit;
  color: ${Colors.mineShaft};
  fill: ${Colors.mineShaft};
  border: 2px solid ${Colors.mineShaft};
`;

const primaryStyles = css`
  background-color: ${Colors.supernova};
  color: ${Colors.mineShaft};
  fill: ${Colors.mineShaft};
  border: 0;
`;

const secondaryStyles = css`
  background-color: ${Colors.white};
  color: ${Colors.mineShaft};
  fill: ${Colors.mineShaft};
  border: 0;
`;

const contrastedStyles = css`
  background-color: ${Colors.mineShaft};
  color: ${Colors.white};
  fill: ${Colors.white};
  border: 0;
`;

const inactiveStyles = css`
  background-color: ${Colors.mystic};
  color: ${Colors.mineShaft};
  fill: ${Colors.mineShaft};
  border: 0;
`;

const getButtonStyles = (appearance?: KnobAppearance | boolean) => {
  switch (appearance) {
    case KnobAppearance.Secondary: {
      return secondaryStyles;
    }

    case KnobAppearance.Inactive: {
      return inactiveStyles;
    }

    case KnobAppearance.Contrasted: {
      return contrastedStyles;
    }

    case KnobAppearance.Frame: {
      return frameStyles;
    }

    default: {
      return primaryStyles;
    }
  }
};

export const Button = styled.button<ButtonStyledProps>`
  ${({ appearance }) => getButtonStyles(appearance)};
  height: ${({ size }) => (size === KnobSize.Small ? '32px' : '50px')};
  box-sizing: border-box;
  padding: 0 ${({ size }) => (size === KnobSize.Small ? '15px' : '24px')};
  font-size: ${({ size }) => (size === KnobSize.Small ? '12px' : '16px')};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.6;
    cursor: unset;
  }
`;

export const Icon = styled.div<ButtonStyledProps>`
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  ${({ appearance }) =>
    appearance === KnobAppearance.Inactive
      ? inactiveStyles
      : appearance === KnobAppearance.Contrasted
      ? contrastedStyles
      : appearance === KnobAppearance.Secondary
      ? secondaryStyles
      : primaryStyles};
  ${({ appearance }) =>
    appearance === KnobAppearance.Primary
      ? css`box-shadow: 1px 1px 0 1px ${Colors.sun};`
      : appearance === KnobAppearance.Contrasted 
        ? css`box-shadow: 1px 1px 0 1px ${Colors.tundora};`
        : css`box-shadow: none;`}
  width: ${({ size }) => (size === KnobSize.Small ? '30px' : '38px')};
  height: ${({ size }) => (size === KnobSize.Small ? '30px' : '38px')};
  box-sizing: border-box;
  padding: 0;
  border: 0;
  border-radius: 50%;
  font-family: ${Fonts.theinhardt};
`;

export const IconButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  font-size: inherit;
  border: 0;
  cursor: pointer;
  font-family: ${Fonts.theinhardt};

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.6;
    cursor: unset;
  }
`;

export const IconLabel = styled.span`
  padding-left: 10px;
`;
