import styled from 'styled-components';

import { ERROR_CODE } from "../../Hooks/useOneClickStore";
import {Colors, Fonts} from '../../Styles';

export const Wrapper = styled.div`
    background-color: white;
    font-family: ${Fonts.publico};
    font-size: 18px;
    line-height: 23px;
    box-shadow: 0 4px 8px 0 #00000026;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

export const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -5px;
`;

export const BlockSection = styled.div`
    word-break: break-word;
    
    mark {
        background: transparent;
        color: ${Colors.darkBlue};
        
        &[data-llm-type="${ERROR_CODE.Replace}"] {
            margin-left: 3px;
        }
    }
`;

export const BottomSection = styled.div`
    display: flex;
    padding-top: 20px;
    margin-left: -10px;
    border-top: 1px solid #D8D8D8;
    
    & > span {
        font-size: 15px;
        line-height: 20px;
        font-style: italic;
        padding: 6px 10px 5px;
    }
`;

export const CloseButton = styled.button`
    border: none;
    background: transparent;
    height: 24px;
    line-height: 21px;
    font-family: ${Fonts.theinhardt};
    font-size: 21px;
    color: ${Colors.mineShaft};
    width: 24px;
    display: block;
    cursor: pointer;
`;

export const SkipButton = styled.button`
    width: 100%;
    border: none;
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    border-radius: 22px;

    &:hover {
        background: ${Colors.mystic};
    }

    svg {
        margin-right: 7px;
        vertical-align: middle;
        width: 29px;
        height: 29px;
    }
`;

export const ParaphrasingSection = styled.div`
    color: ${Colors.mineShaft};
    font-family: ${Fonts.publico};
    font-weight: 400;
    word-break: break-word;
    
    & > * {
        margin: 0;
    }
    
    h4 {
        font-family: ${Fonts.theinhardt};
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        padding: 30px 0 10px;
    }
`

export const ParaphrasingResult = styled.p`
    font-size: 18px;
    line-height: 28px;
`

export const ParaphrasingOrigin = styled.p`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
`
