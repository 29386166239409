import React, { useCallback } from "react";

import * as SC from "./ExpressSinglePad.styled";
import { IssueSection } from "./IssueSection/IssueSection"
import useOneClickStore, { getDataSelector } from "../../Hooks/useOneClickStore";
import { Colors } from "../../Styles";
import { RoundButton } from "../Buttons";
import { BlockContent } from "../ExpressTextSegment";
import { ArrowLeftIcon, ArrowRightIcon, UndoLeft, UndoRight } from "../Icons";

const ExpressSinglePad: React.FC = () => {
    const handleSetSingleCorrectionActiveIndex = useOneClickStore((store) => store.handleSetSingleCorrectionActiveIndex);
    const setSegment = useOneClickStore((store) => store.setSegment);
    const isParaphrasingModeActive = useOneClickStore((store) => store.isParaphrasingModeActive);
    const [activeSegment, isFirstSegment, isLastSegment] = useOneClickStore((store) => {
        const dataSelector = getDataSelector(store);
        const segments = store[dataSelector].segments;
        const activeIndex = store.singleCorrectionActiveIndex;
        const activeSegment = segments[activeIndex];

        const isFirst = activeIndex === 0;
        const isLast = !!activeSegment?.error || (activeIndex === segments.length - 1);

        return [activeSegment, isFirst, isLast];
    });

    const hasIssue = !!activeSegment?.issue;

    const handleCloseClick = useCallback(() => {
        handleSetSingleCorrectionActiveIndex({ value: -1 });
    }, [handleSetSingleCorrectionActiveIndex]);

    const handleNextClick = useCallback(() => {
        handleSetSingleCorrectionActiveIndex({ shiftValue: 1 });
    }, [handleSetSingleCorrectionActiveIndex]);

    const handlePreviousClick = useCallback(() => {
        handleSetSingleCorrectionActiveIndex({ shiftValue: -1 });
    }, [handleSetSingleCorrectionActiveIndex]);

    const handleSkipClick = useCallback(() => {
        if (activeSegment) {
            setSegment(activeSegment.id, { isSkipped: !activeSegment.isSkipped });
        }
    }, [activeSegment, setSegment]);

    if (!activeSegment) {
        return null;
    }

    return <SC.Wrapper>
        <SC.TopSection>
            <div>
                <RoundButton onClick={handlePreviousClick} defaultBackground={Colors.white} disabled={isFirstSegment}>
                    <ArrowLeftIcon />
                </RoundButton>
                <RoundButton onClick={handleNextClick} defaultBackground={Colors.white} disabled={isLastSegment}>
                    <ArrowRightIcon />
                </RoundButton>
            </div>
            <SC.CloseButton onClick={handleCloseClick}>❌</SC.CloseButton>
        </SC.TopSection>
        {hasIssue ? (
            <IssueSection
                code={activeSegment?.issue?.code}
                title={activeSegment?.issue?.title}
                message={activeSegment?.issue?.message}
            />
        ) : (
            <>
                {isParaphrasingModeActive ? <SC.ParaphrasingSection>
                    <SC.ParaphrasingResult>{activeSegment.text}</SC.ParaphrasingResult>
                    <h4>Original</h4>
                    <SC.ParaphrasingOrigin>{activeSegment.originText}</SC.ParaphrasingOrigin>
                </SC.ParaphrasingSection> : <SC.BlockSection>
                    <BlockContent blocks={activeSegment.traceBlocks} />
                </SC.BlockSection>}

                <SC.BottomSection>
                    {activeSegment?.isSkipped ? (<SC.SkipButton onClick={handleSkipClick}>
                        <UndoRight />
                        Vorschlag erneut übernehmen
                    </SC.SkipButton>) : (<SC.SkipButton onClick={handleSkipClick}>
                        <UndoLeft />
                        Originalsatz wiederherstellen
                    </SC.SkipButton>)}
                </SC.BottomSection>
            </>
        )}
    </SC.Wrapper>;
};

export default ExpressSinglePad;