import React from "react";

export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Warning</title>
        <g id="Group 3">
            <mask
                id="mask0_650_127"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path id="Clip 2" fillRule="evenodd" clipRule="evenodd" d="M0 0H24V24H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_650_127)">
                <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 6.07187V16L11.0004 13.5539C11.0004 14.145 11.4923 14.625 12.0998 14.625C12.7073 14.625 13.2 14.145 13.2 13.5539L13.1996 6.07187C13.1996 5.48 12.7069 5 12.0994 5C11.4919 5 11 5.48 11 6.07187ZM10.8 17.8C10.8 18.5179 11.3821 19.1 12.1 19.1C12.8179 19.1 13.4 18.5179 13.4 17.8C13.4 17.0821 12.8179 16.5 12.1 16.5C11.3821 16.5 10.8 17.0821 10.8 17.8ZM2 12.0003C2 6.48633 6.48594 2 12 2C12.1724 2 12.3402 2.01713 12.5105 2.02621C17.7867 2.29432 22 6.65869 22 12.0003C22 17.3418 17.7867 21.7057 12.5105 21.9743C12.3402 21.9829 12.1724 22 12 22C6.48594 22 2 17.5137 2 12.0003ZM17.7153 1.45046C16.0145 0.525995 14.0677 0 11.9998 0C11.3796 0 10.77 0.0476254 10.1747 0.138643C9.97628 0.169335 9.77943 0.20426 9.5847 0.244477C4.12111 1.36526 0 6.21035 0 12.0005C0 17.7902 4.12111 22.6353 9.5847 23.7561C9.77943 23.7963 9.97628 23.8317 10.1747 23.8619C10.77 23.9529 11.3796 24.0005 11.9998 24.0005C14.0677 24.0005 16.0145 23.4745 17.7153 22.5501C17.8851 22.4575 18.0529 22.3612 18.2174 22.2606C21.6813 20.1535 24.0001 16.3429 24.0001 12.0005C24.0001 7.65816 21.6813 3.84707 18.2174 1.73991C18.0529 1.63937 17.8851 1.54306 17.7153 1.45046Z"
                    fill="#333333"
                />
            </g>
        </g>
    </svg>
);
