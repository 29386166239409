import React from 'react';

import classnames from 'classnames';
import { useSelector } from "react-redux";

import { selectHighlightedSynonym } from "../../../Store/AdviceState";

type ShowSynonymProps = {
    offsetKey: string;
    entityKey: string;
    children?: React.ReactNode;
}

export const ShowSynonym: React.FC<ShowSynonymProps> = (props) => {
    const { entityKey, replacement } = useSelector(selectHighlightedSynonym);

    const isCorrected = !!entityKey && props.entityKey === entityKey;

    return (
        <span
            className={classnames('synonym__mark', {
                'synonym__mark--highlighted': isCorrected
            })}
            data-entity-key={props.entityKey}
            data-offset-key={props.offsetKey}
            id={`decorator-synonym-entity-id-${props.entityKey}`}
        >
                {isCorrected ? replacement : props.children}
            </span>
    )
}
